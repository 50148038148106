<template>
  <div class="container-fluid">
    <div class="div-table w-100 ">
      <div class="static-width-3100px">

        <div class="sticky-products-header shadow mb-2">
          <BButtonToolbar class="mt-3 mb-3 " size="sm">
            <BButtonGroup>
              <BButton variant="primary" size="sm"
                       @click="createProduct"
              >Створити товар
              </BButton>

              <BButton variant="outline-primary"
                       :title="selectedProducts.length !== 1 ? 'Спочатку оберіть товар, який треба скопіювати' : ''"
                       size="sm" @click="copySelectedProduct"

                       :disabled="selectedProducts.length !== 1">
                Копіювати
              </BButton>


              <BDropdown
                  text="Імпортувати товари"
                  variant="outline-primary"
                  boundary="viewport"
                  container="body"
              >
                <BDropdownItem @click="importProduct('prom')">пром</BDropdownItem>
                <BDropdownItem @click="importProduct('rozetka')">розетка</BDropdownItem>
                <BDropdownItem @click="importProduct('kasta')">каста</BDropdownItem>
                <BDropdownItem @click="importProduct('horoshop')">хорошоп</BDropdownItem>
                <BDropdownItem @click="importProduct('opencart')">opencart</BDropdownItem>
              </BDropdown>


              <!--   <BButton
                       :title="selectedProducts.length === 0 ? 'Оберіть товари для видалення' : ''"
                       size="sm"
                       @click="editPriceTypes"
                       >

              </BButton>-->
              <BButton variant="outline-danger"
                       :title="selectedProducts.length === 0 ? 'Оберіть товари для видалення' : ''"
                       size="sm"
                       @click="deleteSelectedProducts"

                       :disabled="!isAnyRowSelected">
                Видалити
              </BButton>


              <template>

                <priceTypes
                    @update:priceTypesResponse="priceTypesResponseHandler"
                />

<!--                <BButton variant="outline-secondary" @click="editPriceTypes"> Редагувати типи цін</BButton>

                <BModal v-model="preventableModal"
                        title="Список типів цін"
                        @hide.prevent
                        scrollable

                        @ok="savePriceTypes"
                        @cancel="preventableModal = false"
                        cancel-title="Закрити"
                        ok-title="Зберегти"

                >

                  <BButton @click="addPriceType">Додати тип ціни</BButton>

                  <div v-for="(priceType) in priceTypes" :key="priceType.id" class="mt-2">
                    <div class="d-flex ">
                      <div v-if="priceType.user_id !== null "> {{ priceType.name }}</div>
                      <div v-else>
                        <BFormInput v-model="priceType.name" placeholder="Назва типу ціни"></BFormInput>
                      </div>
                    </div>
                  </div>
                </BModal>-->
              </template>




              <template>
                <BButton variant="outline-secondary" @click="editCagerories"> Редагувати категорії</BButton>

                <BModal v-model="categoriesModal"
                        scrollable
                        title="Список категорій"
                        @hide.prevent
                        @ok="saveCategories"
                        @cancel="categoriesModal = false"
                        cancel-title="Закрити"
                        ok-title="Зберегти"
                >


                  <div>
                    <BInputGroup size="sm" prepend="Додати категорію" class="mt-1">
                      <BFormInput size="sm" placeholder="Самокати" v-model="categoryInputValue"/>
                      <BButton size="sm" title="очистити" variant="outline-danger" @click="categoryInputValue = ''">x
                      </BButton>
                      <BButton size="sm" variant="primary" @click="addCategory()">Додати</BButton>
                    </BInputGroup>
                  </div>

                  <div class="mt-1 mb-1">
                    <BFormInput v-model="searchQuery" placeholder="Пошук по назві категорії"/>
                  </div>

                  <div> Кількість {{ filteredCategories.length }}</div>
                  <div v-for="mycategory in filteredCategories" :key="mycategory.id" class="mt-2">
                    <div class="d-flex ">
                      <div> {{ mycategory.name }}</div>

                    </div>
                  </div>
                </BModal>
              </template>


              <BDropdown v-if="Object.keys(priceTypes).length > 1"
                         :text="'Тип ціни: ' + priceTypes[selectedPriceTypeId]?.name "
                         variant="outline-primary"
                         boundary="viewport"
                         container="body"
              >
                <BDropdownItem v-for="priceType in Object.values(priceTypes)" :key="priceType.id"
                               @click="selectPriceType(priceType.id)">
                  {{ priceType.name }}
                </BDropdownItem>
              </BDropdown>

              <BDropdown v-if="Object.keys(stockNames).length > 1"
                         text="Склади"
                         variant="outline-primary"
                         boundary="viewport"
                         container="body"
              >
                <BDropdownItem v-for="stock in stockNames" :key="stock.id" @click="selectStock(stock)">
                  {{ stock.name }}
                </BDropdownItem>
              </BDropdown>

              <!--              <template>
                                          <BButtonGroup>
                                            <template>
                                              <BButton variant="outline-danger" size="sm" @click="dropOrders"
                                                       :class="{ 'disabled': !isAnyRowSelected }"
                                                       :disabled="!isAnyRowSelected">
                                                Видалити
                                              </BButton>
                                            </template>
                                          </BButtonGroup>
                            </template>-->
              <div v-show="!isEditedProductsEmpty" class="btn-fixed-bottom-right ">
                <BButton variant="success" size="lg"
                         @click="saveChanges"
                >Зберегти зміни
                </BButton>
              </div>
            </BButtonGroup>
          </BButtonToolbar>
          <draggable v-model="headers" :item-key="header => header.columnId" class="div-table-row border-top">
            <template #item="{ element: header }">
              <div v-if="header.columnId === 'checkboxCol'"
                   :class="['div-table-cell dragging', header.columnWidth]"></div>
              <div v-else :class="['div-table-cell dragging', header.columnWidth]">{{ header.text }}</div>
            </template>
          </draggable>
          <div class="div-table-row">
            <div v-for="header in headers" :key="header.columnId"
                 :class="[header.columnWidth]">
              <div class="min-vh-40">
                <VueMultiselect
                    v-if="header.options"
                    v-model="header.filter"
                    :options="header.options"
                    :taggable="false"
                    :multiple="true"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :selectLabel="''"
                    placeholder="Фільтр"
                    label="value"
                    track-by="value"
                    @open="() => handleOpen(header)"
                >
                  <template #option="{ option }">
                    {{ option.value }} ({{ option.quantity }})
                  </template>
                </VueMultiselect>
                <input v-else-if="header.columnId === 'checkboxCol'"
                       :class="['div-table-cell',  header.columnWidth]"
                       :type="header.type"
                       v-model="header.filter"
                       @click="header.ev($event.target.checked)"
                       placeholder="Шукати"
                       class="input-multiselect-style"
                />
                <input v-else
                       :class="['div-table-cell',  header.columnWidth]"
                       :type="header.type"
                       v-model="header.filter"
                       placeholder="Шукати"
                       class="input-multiselect-style"
                />
              </div>
              <div>
                <BInputGroup v-if="
                   header.columnId === 'status'
                  || header.columnId === 'categories'
                  || header.columnId === 'brand'
                  || header.columnId === 'country'
                  || header.columnId === 'unit'
                  || header.columnId === 'currency'
                  || header.columnId === 'currency_purchase'"
                >
                  <BDropdown
                      :class="[ header.columnWidth, 'custom-dropdown'] "
                      size="sm"
                      bottom
                      boundary="viewport"
                      container="body"
                      text="Массові дії"
                      :title="!isAnyRowSelected ? 'Спочатку оберіть рядки' : ''"
                      :disabled="!isAnyRowSelected"
                      variant="outline-secondary"
                  >
                    <BDropdownItem
                        v-for="optionsArray in Object.values(header.allOptions)"
                        :key="optionsArray.name"
                        @click="setNewValue(optionsArray, header.columnId)">Змінити на "{{ optionsArray.name }}"
                    </BDropdownItem>
                  </BDropdown>
                </BInputGroup>
                <BInputGroup v-else-if="header.allOptions !== null">
                  <BDropdown
                      v-model="dropdownItemsState[header.columnId]"
                      :class="[ header.columnWidth, 'custom-dropdown'] "
                      size="sm"

                      bottom
                      :title="!isAnyRowSelected ? 'Спочатку оберіть рядки' : ''"
                      :disabled="header.columnId === 'stock' ? false : !isAnyRowSelected"
                      :text="header.columnId === 'stock' ? 'Дії': 'Массові дії2'"
                      variant="outline-secondary"
                      auto-close="outside"

                  >
                    <!--<BDropdownItem v-if="header.allOptions !== null && setValue !== true "-->
                    <!--                  <BDropdownItemButton v-if="header.allOptions !== null && setValue !== true"
                                                           v-for="actionsArray in Object.values(header.allOptions)"
                                                           :key="actionsArray.action"
                                                           @click="showBulkActionInput($event, actionsArray, header.columnId)"
                                      >
                                        {{ actionsArray.name }}
                                      </BDropdownItemButton>-->
                    <BInputGroup v-if="header.columnId === 'price'" size="sm" class="ml-3 mr-3 w300px">
                      <template #prepend>
                        <BInputGroupText size="sm">Розрахувати на основі</BInputGroupText>
                      </template>
                      <template #append>
                        <template>
                          <BDropdown size="sm"
                                     container="body"
                                     :text="selectedRecalulateColumnValue"
                                     variant="outline-secondary"
                          >
                            <BDropdownItem @click="selectedRecalulateColumn('Поточна колонка', header.columnId)">Поточна
                              колонка
                            </BDropdownItem>
                            <BDropdownItem @click="selectedRecalulateColumn('Закупівельна ціна', header.columnId)">
                              Закупівельна ціна
                            </BDropdownItem>
                          </BDropdown>
                        </template>
                      </template>
                    </BInputGroup>
                    <BInputGroup size="sm" class="ml-3 mr-3 w300px">
                      <template #prepend>
                        <BDropdown
                            v-model="secondDropdownItemsState[header.columnId]"
                            :class="[ header.columnWidth, 'custom-dropdown'] "
                            size="sm"
                            boundary="viewport"
                            container="body"
                            bottom
                            :text="actionTitle"
                            variant="outline-secondary"
                            auto-close="outside"

                        >
                          <!--<BDropdownItem v-if="header.allOptions !== null && setValue !== true "-->
                          <BDropdownItemButton v-if="header.allOptions !== null"
                                               v-for="actionsArray in Object.values(header.allOptions)"
                                               :key="actionsArray.action"
                                               @click="showBulkActionInput(actionsArray, header.columnId)"
                          >
                            {{ actionsArray.name }}
                          </BDropdownItemButton>
                        </BDropdown>
                      </template>
                      <BFormInput v-if="actionName[header.columnId] !== 'increaseIndividual'"
                                  v-model="bulkActionInputValue" type="number"
                                  :step="header.step" class="border-bottom"/>
                      <BInputGroupAppend v-if="actionName[header.columnId] !== 'increaseIndividual'">
                        <span class="input-group-text">{{ unitName }}</span>
                        <BButton :disabled="actionTitle === 'Оберіть дію'"
                                 @click="setNewValue(null, header.columnId)"
                                 variant="primary">Ok
                        </BButton>
                        <BButton variant="danger">X</BButton>
                      </BInputGroupAppend>
                    </BInputGroup>
                  </BDropdown>
                </BInputGroup>


              </div>
            </div>
          </div>
        </div>

        <!-- filtering dropdown for resycle  -->

        <div v-show="isDropdownOpen" class="dropdown-menu show newable" :style="[dropdownStyle]">
          <a v-for="option in dropdownContent" :key="option.id" class="dropdown-item"
             @click="selectDropDownElement(option)">
            {{ option.name }}
          </a>
        </div>

        <BSpinner v-if="spinnerState" variant="success"/>

        <RecycleScroller v-else :items="filteredProducts"
                         :item-size="100"
                         key-field="id"
                         class="div-table-body scroller"
                         page-mode
                         v-slot="{ item, index }"
        >
          <!--          <div class="div-table-row" :key="item.id">
                      <div v-for="header in headers" :key="header.columnId"
                           :id="header.columnId"
                           :class="[header.columnWidth, { 'selected-row': isRowSelected(item.id) }]"
                      >-->
          <div
              class="div-table-row"
              :key="item.id"
              :class="{ 'selected-row': isRowSelected(item.id) }"
          >
            <div
                v-for="header in headers"
                :key="header.columnId"
                :id="header.columnId"
                :class="header.columnWidth"
            >
              <!--              <div v-if="header.columnId === 'checkbox'" class="checkbox-cell first">-->
              <template v-if="header.columnId === 'checkboxCol'">
                <BFormCheckbox v-model="selectedProducts" class="second" :key="item.id" :value="item.id"/>
              </template>

              <template v-else-if="header.columnId === 'thumbnail_url' && header.type === 'img'"
                        :id="'preview-img' + index"
                        class="img"
              >
<!--                <span style="font-size:10px" v-if="!item.thumbnail_url">вкажіть url зображення</span>

                <BImg v-else class="max-height-100px" :src="item.thumbnail_url" alt=""/>
                <BPopover
                    :target="'preview-img' + index"
                    triggers="hover click"
                    placement="right"
                    strategy="fixed"
                >

                  <BImg style="width: 100%; max-width: 400px; height: auto" :src="item.thumbnail_url" alt=""/>
                </BPopover>-->


                <span style="font-size:10px" v-if="!item.thumbnail_url">вкажіть url зображення</span>

                <BImg
                    v-else
                    class="max-height-100px"
                    :src="item.thumbnail_url"
                    alt=""
                    :id="'preview-img' + index"
                />

                <BPopover
                    container="body"
                    :target="'preview-img' + index"
                    triggers="hover click"
                    placement="right"
                    strategy="fixed"
                >
                  <BImg
                      style="width: 100%; max-width: 400px; height: auto"
                      :src="item.thumbnail_url"
                      alt=""
                  />
                </BPopover>



              </template>
              <template v-else-if="header.columnId === 'categories'">
                <BFormTextarea :value="item.categories?.name"
                               @input="setItemPropertyValue(item, header.columnId, $event)"
                               @click="openDropdown($event, item, header.columnId)"
                               rows="5"
                />
              </template>
              <template v-else-if="header.columnId === 'currency'
              ">
                <!--              <BFormInput v-model="item.currency.name"-->
                <BFormInput v-model="currencies[item.currency_id].name"
                            @click="openDropdown($event, item, header.columnId)"/>
              </template>
              <template v-else-if=" header.columnId === 'currency_purchase'
              ">
                <!--              <BFormInput v-model="item.currency_purchase.name"-->
                <BFormInput v-model="currencies[item.purchase_currency_id].name"
                            @click="openDropdown($event, item, header.columnId)"/>
              </template>

              <template v-else-if="header.columnId === 'status'">
                <BFormInput :value="item[header.columnId] === 'available' ? 'В наявності' : 'Не в наявності'"
                            @click="openDropdown($event, item, header.columnId)"
                />
              </template>

              <template v-else-if="header.columnId === 'unit'">
                <!--              <BFormInput v-model="item.unit.name" @click="openDropdown($event, item, 'unit')"/>-->
                <BFormInput v-model="units[item.unit_id].name" @click="openDropdown($event, item, 'unit')"/>
              </template>
              <!--            <BFormInput v-else :type="header.type" :step="header.step" v-model="item[header.columnId]"/>-->


              <template v-else-if="header.columnId === 'stock'">
                <div class="input-group" style="display:flex">
                  <div>
                    <BFormInput
                        :type="header.type"
                        :disabled="actionName[header.columnId] === 'increaseIndividual'"
                        :step="header.step"
                        :style="{ width: actionName[header.columnId] === 'increaseIndividual' ? '70px' : '150px' }"
                        v-model="item.stock[selectedStockName].stock_qty"/>
                  </div>
                  <div>
                    <BFormInput v-show="actionName[header.columnId] === 'increaseIndividual'"
                                :type="header.type"
                                :step="header.step"
                                v-model="item.stock[selectedStockName].add_to_stock_qty"
                                style="width:70px"
                    />
                  </div>
                </div>
              </template>


              <!--
              те що нижче треба на ціну переробити
              -->
              <template v-else-if="header.columnId === 'price'">
                <div class="input-group" style="display:flex">
                  <div>
                    <!--                    <BFormInput v-model="item.prices[selectedPriceTypeId].price" />-->

                    <BFormInput
                        :type="header.type"
                        :step="header.step"
                        v-model="item.prices[selectedPriceTypeId].price"

                    />

                  </div>
                </div>
              </template>

              <template v-else-if="header.columnId === 'name' || header.columnId === 'short_name'">
                <BFormTextarea rows="5" :type="header.type" :step="header.step" v-model="item[header.columnId]"/>
              </template>

              <template v-else>
                <BFormInput class="else" :type="header.type" :step="header.step" v-model="item[header.columnId]"/>
              </template>
            </div>
          </div>
        </RecycleScroller>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, watch, ref, watchEffect} from "vue";
import {RecycleScroller} from "vue-virtual-scroller";
import {cloneDeep} from 'lodash';
import apiCall from "@/axios/apiRequests";
import {useStore} from "vuex";
import priceTypes from '@/components/priceTypes'

import {
  BButton,
  BButtonGroup,
  BButtonToolbar,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BFormCheckbox,
  BFormTextarea,
  BFormInput,
  BPopover,
  BModal
} from 'bootstrap-vue-next';
import VueMultiselect from "vue-multiselect";
import draggable from 'vuedraggable'
import {
  deleteProducts,
  getAllPriceTypesByUser, getCategoriesByUser, getHoroshopProducts,
  getKastaProducts, getOpencartProducts,
  getPromProducts,
  getRozetkaProducts, saveCateg, savePrices
} from "@/axios/settignsRequests";


export default {
  name: "ProductList",
  setup() {
    const products = ref([]);
    const categories = ref([]);
    const brands = ref([]);
    const countries = ref([]);
    const units = ref([]);
    const currencies = ref([])
    const currencyPurchase = ref([])
    const selectedStockName = ref(0);
    const selectedPriceTypeId = ref(0);


    const statuses = ref({
      'available': {
        // 'id': 'available',
        'name': 'В наявності',
      },
      'unavailable': {
        // 'id': 'unavailable',
        'name': 'Не в наявності',
      },
    })

    const store = useStore()

    const stockNames = ref([])
  //  const preventableModal = ref(false)

    const priceTypes = ref([]);

    async function getPriceTyesFromServer() {
      await getAllPriceTypesByUser().then(res => {
        console.log('priceTypes', res)
        priceTypes.value = res
      })
    }

    async function getCategoriesFromServer() {
      await getCategoriesByUser().then(res => {
        if (res.status === 'succees') {
          console.log('categories response', res.data)
          categories.value = res.data
        }
      })
    }

    getPriceTyesFromServer();

/*    async function editPriceTypes() {
      preventableModal.value = true
      await getPriceTyesFromServer()
    }*/

    const categoriesModal = ref(false);

    async function editCagerories() {
      categoriesModal.value = true
      await getCategoriesFromServer();
    }

/*    async function savePriceTypes() {
      // Перетворюємо об'єкт назад у масив
      const preparedPriceTypes = Object.values(priceTypes.value).map(priceType => {
        // Перевірка, чи є user_id
        if (!priceType.user_id) {
          // Якщо user_id відсутній, видаляємо id
          priceType.id = null;
        }
        return priceType;
      });

      console.log('preparedPriceTypes', preparedPriceTypes);

      // Збереження даних
      await savePrices({pricesTypes: preparedPriceTypes}).then(response => {
        console.log('response', response);

        if (response.status === 'success') {
          priceTypes.value = response.data;
          store.commit('alert/triggerAlert', {
            'show': true,
            'variant': 'success',
            'message': 'Типи цін успішно збережені'
          });
        } else {
          store.commit('alert/triggerAlert', {
            'show': true,
            'variant': 'danger',
            'message': 'Помилка при збереженні типів цін'
          });
        }
      });

      preventableModal.value = false

    }*/


    async function saveCategories() {

      /*   const preparedCategories = Object.values().reduce(category => {
           // Перевірка, чи є user_id
           if (!category.user_id) {
             // Якщо user_id відсутній, видаляємо id
             category.id = null;
             return category;
           }
             });
   */

      let categoriesForSave = [];

      Object.values(categories.value).forEach(category => {
        if (!category.user_id) {
          category.id = null;
          categoriesForSave.push(category);
        }
      })

      console.log('categoriesForSave', categoriesForSave);

      // Збереження даних
      await saveCateg({categories: categoriesForSave}).then(response => {
        console.log('response', response);
        if (response.status === 'success') {
          categories.value = response.data;
          store.commit('alert/triggerAlert', {
            'show': true,
            'variant': 'success',
            'message': 'Типи цін успішно збережені'
          });
        } else {
          store.commit('alert/triggerAlert', {
            'show': true,
            'variant': 'danger',
            'message': 'Помилка при збереженні типів цін'
          });
        }
      });
      preventableModal.value = false
    }


/*    function addPriceType() {
      const newId = generateUniqueId(); // Функція для генерації унікального ID
      priceTypes.value[newId] = {
        'id': newId,
        'name': '',
        'value': '',
        'description': null,
        'user_id': null,
      };
    }*/

    const categoryInputValue = ref('')

    function addCategory() {

      const newId = generateUniqueId(); // Функція для генерації унікального ID
      const newCategory = {
        [newId]: {
          'id': newId,
          "parent_id": null,
          "category_id": null,
          "marketplaces_categories": null,
          "name": categoryInputValue.value,
          // "user_id": null,
          "code_group": null,
          "length": 0,
          "height": 0,
          "width": 0,
          "cargo_description_id": null,
          "weight": 0,
          "description": null,
        }
      };

      // Додаємо новий елемент на початок об'єкта
      categories.value = {...newCategory, ...categories.value};

      categoryInputValue.value = '';

      // console.log('categories.value', categories.value);

    }


    function generateUniqueId() {
      return Date.now().toString(); // Або інший метод для генерації унікального ID
    }

    const selectedRecalulateColumnValue = ref('');

    selectedRecalulateColumnValue.value = 'Поточна колонка';

    const setValue = ref(true);

    const dropdownItemsState = ref({
      'purchase_price': false,
      'price': false,
      'stock': false,
      'length': false,
      'height': false,
      'width': false,
      'weight': false,
    });

    const secondDropdownItemsState = ref({
      'purchase_price': false,
      'price': false,
      'stock': false,
      'length': false,
      'height': false,
      'width': false,
      'weight': false,
    });

    function selectedRecalulateColumn(value, header) {
      selectedRecalulateColumnValue.value = value;
      dropdownItemsState.value[header] = true;
    }


    const spinnerState = ref(false);


    onMounted(async () => {
      spinnerState.value = true;
      const response = await apiCall('get', `/get-products-by-user-id`);

      products.value = response.products;
      //  console.log('products', products.value);
      spinnerState.value = false;
      categories.value = response.categories;
      brands.value = response.brands;
      countries.value = response.countryManufacture;
      currencies.value = response.currency;
      units.value = response.units;

      currencyPurchase.value = response.currencyPurchase;

      ensurePricesExist();
      ensureStocksExist();

      //watchEffect();
    });

    function selectStock(item) {
      selectedStockName.value = item.id;
    }

    function selectPriceType(item) {
      console.log('price types', priceTypes.value)
      console.log('item', item)
      selectedPriceTypeId.value = item;
      ensurePricesExist();
    }

    stockNames.value = localStorage.getItem('stockNames') ? JSON.parse(localStorage.getItem('stockNames')) : [];
    selectedStockName.value = localStorage.getItem('senderSettings') ? JSON.parse(localStorage.getItem('senderSettings')).default_stock_name_id : null;

    console.log('selectedStockName', selectedStockName.value)

    // selectedPriceTypeId

    // selectPriceType(localStorage.getItem('senderSettings') ? JSON.parse(localStorage.getItem('senderSettings')).default_price_type_id : null);

    /*
    [
  {
    "id": 26036,
    "user_id": 46,
    "name": "товар",
    "short_name": null,
    "article": "9sd789f",
    "code_product": null,
    "barcode": null,
    "status": "available",
    "user_categories_id": null,
    "description": null,
    "thumbnail_url": null,
    "deleted_at": null,
    "brand_id": null,
    "country_id": null,
    "unit_id": 3,
    "price": "515.00",
    "currency_id": 1,
    "purchase_price": "0.00",
    "purchase_currency_id": 1,
    "qty_in_stock": null,
    "length": 0,
    "height": 0,
    "width": 0,
    "weight": 0.8,
    "created_at": "2024-03-20T21:08:03.000000Z",
    "updated_at": "2024-03-20T21:08:03.000000Z",
    "rozetka_products_mapping": [],
    "prom_products_info_mapping": [],
    "stock": {
      "id": 24740,
      "product_id": 26036,
      "stock_qty": -6
    },
    "unit": {
      "id": 3,
      "name": "шт."
    },
    "currency": {
      "id": 1,
      "name": "грн."
    },
    "currency_purchase": {
      "id": 1,
      "name": "грн."
    },
    "categories": null,
    "brand": null,
    "country": null
  }
]
    * */

    /*    const productElement = {
          'new_product': true,
          "id": null,
          "name": '',
          "short_name": '',
          "article": '',
          "code_product": '',
          "barcode": '',
          "status": "available",
          "user_categories_id": null,
          "description": '',
          "thumbnail_url": null,
          "brand_id": null,
          "country_id": null,
          "unit_id": 3,
          "price": 0.00,
          "currency_id": 1,
          "purchase_price": 0.00,
          "purchase_currency_id": 1,
          "stock": [
            {
              "id": null,
              "product_id": null,
              "stock_qty": 0,
              "add_to_stock_qty": 0,
            }
          ],
          "currency": {
            "id": 1,
            "name": "грн."
          },

          "unit": {
            "id": 3,
            "name": "шт."
          },
          "currency_purchase": {
            "id": 1,
            "name": "грн."
          },
          "length": 0,
          "height": 0,
          "width": 0,
          "weight": 0.1,
        }*/


    const productElement = {
      'new_product': true,
      "id": null,
      "uuid": null,
      "name": '',
      "short_name": '',
      "article": '',
      "code_product": '',
      "barcode": '',
      "status": "available",
      "user_categories_id": null,
      "description": '',
      "thumbnail_url": null,
      "brand_id": null,
      "country_id": null,
      "unit_id": 3,

      "price": 0.00,
      "qty_in_stock": null,
      "deleted_at": null,

      "currency_id": 1,
      "purchase_price": 0.00,
      "purchase_currency_id": 1,
      "stock": {
        [selectedStockName.value]: {
          "id": null,
          "product_id": null,
          "stock_qty": 0,
          "add_to_stock_qty": 0,
          'stock_name_id': selectedStockName.value,
        }
      },

      "prices": {
        [selectedPriceTypeId.value]: {
          "id": null,
          "product_id": null,
          "price": 0.00,
          "price_type_id": selectedPriceTypeId.value,
        }
      },

      "currency": {
        "id": 1,
        "name": "грн."
      },
      "unit": {
        "id": 3,
        "name": "шт."
      },
      "currency_purchase": {
        "id": 1,
        "name": "грн."
      },
      "length": 0,
      "height": 0,
      "width": 0,
      "weight": 0.1,
    }


    let editedProducts = ref({
      'stock': [],
      'products': [],
      'prices': [],
    });

    const columnWithRelationId = {
      'categories': 'user_categories_id',
      'currency': 'currency_id',
      'currency_purchase': 'purchase_currency_id',
      'unit': 'unit_id',
      'brand': 'brand_id',
      'country': 'country_id',
      //'stock': 'stock.stock_qty'
    }

    watchEffect(() => {
      products.value.forEach(product => {

        // Watch all the simple properties of the product
        const simpleProps = [
          'name', 'short_name', 'article', 'code_product', 'barcode', 'status',
          'user_categories_id', 'brand_id', 'country_id', 'description',
          'unit_id', 'currency_id', 'thumbnail_url', 'purchase_currency_id',
          'purchase_price', 'price', 'length', 'height', 'width', 'weight'
        ];

        simpleProps.forEach(prop => {
          watch(() => product[prop], () => handleProductEdit(product.id));
        });

        // Watch the nested stock structure
        Object.keys(product.stock).forEach(stockId => {
          const stockItem = product.stock[stockId];
          watch(() => stockItem.stock_qty, () => handleProductEdit(product.id, 'stock'));
          watch(() => stockItem.add_to_stock_qty, () => handleProductEdit(product.id, 'stock'));
        });


        Object.keys(product.prices).forEach(priceId => {
          const priceItem = product.prices[priceId];
          watch(() => priceItem.price, () => handleProductEdit(product.id, 'prices'));
        });

      });
    });

    function handleProductEdit(productId, key = null) {
      // console.log('handleProductEdit', productId);
      if (key === 'stock' && productId !== null && !editedProducts.value['stock'].includes(productId)) {
        editedProducts.value['stock'].push(productId);

      } else if (key === 'prices' && productId !== null && !editedProducts.value['prices'].includes(productId)) {
        editedProducts.value['prices'].push(productId);
      } else if ((key !== 'stock' && key !== 'prices') && !editedProducts.value['products'].includes(productId)) {
        editedProducts.value['products'].push(productId);
      }

    }


    // Перевірити чи є зміни в товарах, цінах, залишках для відображення кнопки "Зберегти"
    const isEditedProductsEmpty = computed(() => {
      // Check if both "stock" and "products" arrays are empty
      console.log('editedProducts.value', editedProducts.value);

      return editedProducts.value.stock.length === 0
          && editedProducts.value.products.length === 0
          && editedProducts.value.prices.length === 0
          ;
    });


    const numericBulkActions = {

      'increaseIndividual': {
        'name': 'Додати в одиницях (індивідуально)',
        'shortName': 'Додати (індивідуально)',
        'action': 'increaseIndividual'
      },

      'setStaticValue': {
        'name': 'Вказати конкретне значення',
        'shortName': 'Вказати значення',
        'action': 'setStaticValue'
      },

      'increase': {
        'name': 'Додати в одиницях',
        'shortName': 'Додати',
        'action': 'increase'
      },
      'decrease': {
        'name': 'Відняти в одиницях',
        'shortName': 'Відняти',
        'action': 'decrease'
      },
      'increaseInPercent': {
        'name': 'Додати у відсотках',
        'shortName': 'Додати',
        'action': 'increaseInPercent'
      },
      'decreaseInPercent': {
        'name': 'Відняти у відсотках',
        'shortName': 'Відняти',
        'action': 'decreaseInPercent'
      },
    }

    const textActions = {
      'findAndReplace': 'Знайти і замінити',
      'addPrefix': 'Додати на початок',
      'addSuffix': 'Додати у кінець',
    }


    const isAllRowsSelected = ref(false);
    const selectedProducts = ref([]);
    const selectAllProducts = ref(false);
    const productStatus = ref([])
    const category = ref([])
    const brand = ref([])
    const country = ref([])
    const unit = ref([])
    const currency = ref([])
    const purchaseCurrency = ref([])
    const searchByName = ref('');
    const searchByShortName = ref('');
    const searchByArticle = ref('');
    const searchByCodeProduct = ref('');
    const searchByBarcode = ref('');
    const selectedProductStatus = ref([]);
    const selectedCategory = ref([]);
    const selectedBrand = ref([]);
    const selectedCountry = ref([]);
    const selectedUnit = ref([]);
    const searchByPrice = ref('');
    const selectedCurrency = ref([]);
    const searchByPurchasePrice = ref('');
    const selectedPurchaseCurrency = ref([])
    const searchByQtyInStock = ref('')
    const searchByLength = ref('')
    const searchByHeight = ref('')
    const searchByWidth = ref('')
    const searchByWeight = ref('')

    const headers = ref([
      {
        // 'text': 'Select',
        'columnId': 'checkboxCol',
        'allOptions': null,
        'columnWidth': 'selectOrderWidth',
        'filter': selectAllProducts,
        'type': 'checkbox',
        ev: (isChecked) => toggleselectAllProducts(isChecked)
      },
      {
        'text': 'Зображення',
        'allOptions': null, // textActions,
        'columnId': 'thumbnail_url',
        'columnWidth': 'rowNumWidth',
        'filter': '',
        'type': 'img'
      },
      {
        'text': 'Назва',
        'allOptions': null, // textActions,
        'columnId': 'name',
        'columnWidth': 'OrderNumberWidth',
        'filter': searchByName,
        'type': 'text'
      },
      {
        'text': 'Скорочена назва',
        'allOptions': null, // textActions,
        'columnId': 'short_name',
        'columnWidth': 'OrderNumberWidth',
        'filter': searchByShortName,
        'type': 'text'
      },

      {
        'text': 'url зображ',
        'allOptions': null, // textActions,
        'columnId': 'thumbnail_url',
        'columnWidth': 'OrderNumberWidth',
        'filter': '',
        'type': 'text'
      },
      {
        'text': 'Артикул',
        'allOptions': null, // textActions,
        'columnId': 'article',
        'columnWidth': 'OrderNumberWidth text-align-end',
        'filter': searchByArticle,
        'type': 'text'
      },

      {
        'text': 'Наявність',
        'columnId': 'status',
        'allOptions': statuses,
        'columnWidth': 'OrderNumberWidth text-align-end',
        'filter': selectedProductStatus,
        'options': productStatus,
      },

      {
        'text': 'Закуп. ціна',
        'allOptions': numericBulkActions,
        'step': '0.01',
        'columnId': 'purchase_price',
        'columnWidth': 'OrderNumberWidth text-align-end',
        'filter': searchByPurchasePrice,
        'type': 'number'
      },
      {
        'text': 'Розд. ціна',
        'allOptions': numericBulkActions,
        'step': '0.01',
        'columnId': 'price',
        'columnWidth': 'OrderNumberWidth text-align-end',
        'filter': searchByPrice,
        'type': 'number'
      },
      {
        'text': 'Залишок',
        'step': '1',
        'allOptions': numericBulkActions,
        'columnId': 'stock',
        'columnWidth': 'OrderNumberWidth text-align-end',
        'filter': searchByQtyInStock,
        'type': 'number'
      },
      {
        'text': 'Валюта',
        'columnId': 'currency',
        'allOptions': currencies,
        'columnWidth': 'OrderNumberWidth text-align-end',
        'filter': selectedCurrency,
        'options': currency,
      },
      {
        'text': 'Валюта закупки',
        'columnId': 'currency_purchase',
        'allOptions': currencies,
        'columnWidth': 'OrderNumberWidth text-align-end',
        'filter': selectedPurchaseCurrency,
        'options': currency,
      },
      {
        'text': 'Категорія',
        'columnId': 'categories',
        'allOptions': categories,
        'columnWidth': 'OrderNumberWidth text-align-end',
        'filter': selectedCategory,
        'options': category,
      },
      {
        'text': 'Код товару',
        'allOptions': null,
        'columnId': 'code_product',
        'columnWidth': 'OrderNumberWidth text-align-end',
        'filter': searchByCodeProduct,
        'type': 'number'
      },
      {
        'text': 'Штрих-код',
        'allOptions': null,
        'columnId': 'barcode',
        'columnWidth': 'OrderNumberWidth text-align-end',
        'filter': searchByBarcode,
        'type': 'text'
      },
      {
        'text': 'Бренд',
        'columnId': 'brand',
        'allOptions': brands,
        'columnWidth': 'OrderNumberWidth text-align-end',
        'filter': selectedBrand,
        'options': brand,
      },
      {
        'text': 'Країна виробник',
        'columnId': 'country',
        'allOptions': countries,
        'columnWidth': 'OrderNumberWidth text-align-end',
        'filter': selectedCountry,
        'options': country,
        'type': 'text'
      },
      {
        'text': 'Од. вим.',
        'columnId': 'unit',
        'allOptions': units,
        'columnWidth': 'OrderNumberWidth text-align-end',
        'filter': selectedUnit,
        'options': unit,
      },
      {
        'text': 'Довжина(см)',
        'step': '1',
        'allOptions': numericBulkActions,
        'columnId': 'length',
        'columnWidth': 'OrderNumberWidth text-align-end',
        'filter': searchByLength,
        'type': 'number'
      },
      {
        'text': 'Висота(см)',
        'step': '1',
        'allOptions': numericBulkActions,
        'columnId': 'height',
        'columnWidth': 'OrderNumberWidth text-align-end',
        'filter': searchByHeight,
        'type': 'number'
      },
      {
        'text': 'Ширина(см)',
        'step': '1',
        'allOptions': numericBulkActions,
        'columnId': 'width',
        'columnWidth': 'OrderNumberWidth text-align-end',
        'filter': searchByWidth,
        'type': 'number'
      },
      {
        'text': 'Вага(кг)',
        'step': '0.1',
        'allOptions': numericBulkActions,
        'columnId': 'weight',
        'columnWidth': 'OrderNumberWidth text-align-end',
        'filter': searchByWeight,
        'type': 'number'
      }
    ])


    /*    function createProduct() {
          // add to products.value new element
          const copiedProduct = {...productElement};
          copiedProduct.id = products.value.length + 1;
          products.value = [copiedProduct, ...products.value ];
        }*/


    function createProduct() {
      console.log('selectedPriceTypeId', selectedPriceTypeId.value);
      console.log('productElement', productElement);
      const copiedProduct = cloneDeep(productElement);
      copiedProduct.id = products.value.length + 1;
      copiedProduct.prices[selectedPriceTypeId.value] = {
        "id": null,
        "product_id": null,
        "price": 0.00,
        "price_type_id": selectedPriceTypeId.value,
      };
      products.value = [copiedProduct, ...products.value];
    }


    function copySelectedProduct() {
      //  console.log('selected products', selectedProducts.value);
      //  console.log('products.value', products.value)
      const existedProduct = products.value.find(product => product.id === selectedProducts.value[0]);
      const copiedProduct = {...existedProduct};
      copiedProduct.id = products.value.length + 1;
      copiedProduct.new_product = true;
      copiedProduct.stock[selectedStockName.value].id = null;
      copiedProduct.stock[selectedStockName.value].product_id = null;
      products.value = [...products.value, copiedProduct];
      // console.log('products.value', products.value);
    }

    function toggleselectAllProducts(isChecked) {
      // console.log('isChecked', isChecked);
      // console.log('toggleSelectAllOrders')
      if (isChecked) {
        isAllRowsSelected.value = true

        selectedProducts.value = filteredProducts.value.map(product => product.id);
      } else {

        selectedProducts.value = [];
        isAllRowsSelected.value = false
      }
    }


    const filteredProducts = computed(() => {


      // console.log('selectedStockName.value', selectedStockName.value)


      if (
          searchByName.value === ''
          && searchByShortName.value === ''
          && searchByArticle.value === ''
          && searchByCodeProduct.value === ''
          && searchByBarcode.value === ''
          && searchByPrice.value === ''
          && searchByPurchasePrice.value === ''
          && searchByQtyInStock.value === ''
          && searchByLength.value === ''
          && searchByHeight.value === ''
          && searchByWidth.value === ''
          && searchByWeight.value === ''
          && selectedProductStatus.value.length === 0
          && selectedCategory.value.length === 0
          && selectedBrand.value.length === 0
          && selectedCountry.value.length === 0
          && selectedUnit.value.length === 0
          && selectedCurrency.value.length === 0
          && selectedPurchaseCurrency.value.length === 0
      ) {
        return products.value;
      }
      return products.value.filter(product => {
        //  console.log('product', product)
        const name = product.name;
        const short_name = product.short_name;
        const article = product.article;
        const code_product = product.code_product;
        const barcode = product.barcode;

        const status = product.status;
        const categories = product.categories?.name;
        const brand = product.brand?.name;
        const country = product.country?.name;
        const unit = product.unit?.name;
        const currency = product.currency?.name;
        const currency_purchase = product.currency_purchase;


        const purchase_price = product.purchase_price;
        const price = product.price;


        if (!product.stock) {
          product.stock = {};
        }
        if (!product.stock[selectedStockName.value]) {
          product.stock[selectedStockName.value] = {};
        }
        if (!product.stock[selectedStockName.value].stock_qty) {
          product.stock[selectedStockName.value].stock_qty = 0;
        }


        const stock = product.stock[selectedStockName.value].stock_qty;

        // const stock = product.stock[selectedStockName.value].stock_qty;
        const length = product.length;
        const height = product.height;
        const width = product.width;
        const weight = product.weight;


        let productNameMatches = true;
        if (searchByName.value) {
          productNameMatches = name
              ? name.toLowerCase().includes(searchByName.value.toLowerCase())
              : false;
        }

        let shortNameMatches = true;
        if (searchByShortName.value) {
          shortNameMatches = short_name
              ? short_name.toLowerCase().includes(searchByShortName.value.toLowerCase())
              : false;
        }

        let articleMatches = true;
        if (searchByArticle.value) {
          articleMatches = article
              ? article.toLowerCase().includes(searchByArticle.value.toLowerCase())
              : false;
        }

        let codeProductMatches = true;
        if (searchByCodeProduct.value) {
          codeProductMatches = code_product
              ? String(code_product).includes(searchByCodeProduct.value)
              : false;
        }

        let barcodeMatches = true;
        if (searchByBarcode.value) {
          barcodeMatches = barcode
              ? barcode.toLowerCase().includes(searchByBarcode.value.toLowerCase())
              : false;
        }

        let statusMatches = true;
        if (selectedProductStatus.value.length > 0) {
          statusMatches = status
              ? selectedProductStatus.value.map(selectedStatus => {
                //  console.log('status', status)
                //  console.log('selectedStatus', selectedStatus);
                return selectedStatus.value
              }).includes(status)
              : false;
        }

        let userCategoriesIdMatches = true;
        if (selectedCategory.value.length > 0) {
          userCategoriesIdMatches = categories
              ? selectedCategory.value.map(cat => cat.value).includes(categories)
              : false;
          // console.log('userCategoriesIdMatches', userCategoriesIdMatches); // false
        }

        let brandIdMatches = true;
        if (selectedBrand.value.length > 0) {
          brandIdMatches = brand
              ? selectedBrand.value.map(br => br.value).includes(brand)
              : false;
        }

        let countryMatches = true;
        if (selectedCountry.value.length > 0) {
          countryMatches = country
              ? selectedCountry.value.map(item => item.value).includes(country)
              : false;
        }

        let unitMatches = true;
        if (selectedUnit.value.length > 0) {
          unitMatches = unit
              ? selectedUnit.value.map(item => item.value).includes(unit)
              : false;
        }

        let currencyIdMatches = true;
        if (selectedCurrency.value.length > 0) {
          currencyIdMatches = currency
              ? selectedCurrency.value.map(item => item.value).includes(currency)
              : false;
        }


        let purchaseCurrencyIdMatches = true;
        if (selectedPurchaseCurrency.value.length > 0) {
          purchaseCurrencyIdMatches = currency_purchase
              ? selectedPurchaseCurrency.value.map(item => item.value).includes(currency_purchase)
              : false;
        }

        let priceMatches = true;

        if (searchByPrice.value) {
          console.log('searchByPrice.value', searchByPrice.value); // 4

          priceMatches = price
              ? String(price).includes(String(searchByPrice.value))
              : false;
        }

        let purchasePriceMatches = true;
      /*  if (searchByPurchasePrice.value) {

          console.log('searchByPurchasePrice.value', searchByPurchasePrice.value); // 1

          purchasePriceMatches = purchase_price
              ? purchase_price.includes(String(searchByPurchasePrice.value)) // TypeError: purchase_price.includes is not a function
              : false;
        }*/

        if (searchByPurchasePrice.value) {
          console.log('searchByPurchasePrice.value', searchByPurchasePrice.value); // 1

          purchasePriceMatches = purchase_price
              ? String(purchase_price).includes(String(searchByPurchasePrice.value))
              : false;
        }



        let qtyInStockMatches = true;
        if (searchByQtyInStock.value) {
          qtyInStockMatches = stock
              ? stock.toLowerCase().includes(searchByQtyInStock.value.toLowerCase())
              : false;
        }

        let lengthMatches = true;
        if (searchByLength.value) {
          lengthMatches = length
              ? length.toLowerCase().includes(searchByLength.value.toLowerCase())
              : false;
        }

        let heightMatches = true;
        if (searchByHeight.value) {
          heightMatches = height
              ? height.toLowerCase().includes(searchByHeight.value.toLowerCase())
              : false;
        }

        let widthMatches = true;
        if (searchByWidth.value) {
          widthMatches = width
              ? width.toLowerCase().includes(searchByWidth.value.toLowerCase())
              : false;
        }

        let weightMatches = true;
        if (searchByWeight.value) {
          weightMatches = weight
              ? weight.toLowerCase().includes(searchByWeight.value.toLowerCase())
              : false;
        }

        return productNameMatches &&
            shortNameMatches &&
            articleMatches &&
            codeProductMatches &&
            barcodeMatches &&
            statusMatches &&
            userCategoriesIdMatches &&
            brandIdMatches &&
            countryMatches &&
            unitMatches &&
            priceMatches &&
            purchasePriceMatches &&
            currencyIdMatches &&
            purchaseCurrencyIdMatches &&
            qtyInStockMatches &&
            lengthMatches &&
            heightMatches &&
            widthMatches &&
            weightMatches;
      });
    });

    const handleOpen = (header) => {
      //  console.log('header', header);
      //  console.log('header.columnId', header.columnId);
      header.options = parseOrdersForOptions(header.columnId);
      //  console.log('header.options', header.options);
    };

    /*   const parseOrdersForOptions = (value) => {
         console.log('value', value);
         const valueCounts = {};

         filteredProducts.value.forEach(product => {
           // Cache the properties to avoid repeated lookups
           console.log('product', product);
           let productValue = null;

           if(value === 'currency_id'){
             // productValue = currencies.value[product[value]].name
             productValue = { name: currencies.value[product[value]].name, id: product[value]}
           } else if(value === 'purchase_currency_id'){
            // productValue = currencies.value[product[value]].name
             productValue = { name: currencies.value[product[value]].name, id: product[value]}
           } else if(value === 'status'){
             //productValue = statuses[product[value]].name
             productValue =  { name: statuses[product[value]].name, id: product[value]}
           } else if(value === 'user_categories_id'){
           //  productValue = { name: categories.value[product[value]].name, id: product[value] }
             productValue = { name: categories.value[product[value]].name, id: product[value] }
           } else if(value === 'brand_id'){
             //productValue = brands.value[product[value]].name
             productValue = { name: brands.value[product[value]].name, id: product[value] }
           } else if(value === 'country_id'){
             //productValue = countries.value[product[value]].name
             productValue = { name: countries.value[product[value]].name, id: product[value] }
           } else if(value === 'unit_id'){
             //productValue = units.value[product[value]].name
             productValue = { name: units.value[product[value]].name, id: product[value] }
           }
           valueCounts[productValue] = (valueCounts[productValue] || 0) + 1;
         });

         console.log('valueCounts', valueCounts)
         // Convert valueCounts to options array
         return Object.entries(valueCounts).map(([key, count]) => ({value: key, quantity: count}));
       };*/

    const parseOrdersForOptions = (value) => {
      const valueCounts = {};
      //  console.log('value1245', value);
      filteredProducts.value.forEach(product => {
        let productValue = product[value]; // Directly use the ID as key
        // let name;

        //  console.log('productValue', productValue);


        /*        if (value === 'currency_id' || value === 'purchase_currency_id') {
                  name = currencies.value[product[value]]?.name;
                } else if (value === 'status') {
                  name = statuses.value[product[value]]?.name;
                } else if (value === 'user_categories_id') {
                  name = categories.value[product[value]]?.name;
                } else if (value === 'brand_id') {
                  name = brands.value[product[value]]?.name;
                } else if (value === 'country_id') {
                  name = countries.value[product[value]]?.name;
                } else if (value === 'unit_id') {
                  name = units.value[product[value]]?.name;
                }*/

        if (value === 'currency') {
          productValue = product.currency?.name;
        } else if (value === 'currency_purchase') {
          productValue = product.currency_purchase?.name;
        } else if (value === 'status') {
        } else if (value === 'categories') {
          productValue = product.categories?.name;
        } else if (value === 'brand') {
          productValue = product.brand?.name;
        } else if (value === 'country') {
          productValue = product.country?.name;
        } else if (value === 'unit') {
          productValue = product.unit?.name;
        }


        valueCounts[productValue] = (valueCounts[productValue] || 0) + 1;

        /*     if (!valueCounts[productValue]) {
               valueCounts[productValue] = {name, count: 1};
             } else {
               valueCounts[productValue].count++;
             }*/
      });


      // console.log('valueCounts', valueCounts);

      // Convert valueCounts to options array with id included
      /*    return Object.entries(valueCounts).map(([id, {name, count}]) => ({
            value: id,
            name: name,
            quantity: count
          }));*/

      return Object.entries(valueCounts).map(([key, count]) => ({value: key, quantity: count}));
    };

    const searchTerm = ref('');

    const currentColumnType = ref(null);
    const dropdownPosition = ref({top: 0, left: 0});
    const isDropdownOpen = ref(false);

    const selectedElementId = ref(null);

    /*    function openModal() {
          isDropdownOpen.value = !isDropdownOpen.value;
          console.log('isDropdownOpen', isDropdownOpen.value)
        }*/

    function openDropdown(event, item, columnType) {
      inputFilter.value = '';
      console.log('open  resycle dropdown for set possition', item);
      console.log('columnType', columnType);
      const rect = event.target.getBoundingClientRect();
      selectedElementId.value = item.id;
      currentColumnType.value = columnType; // Assume you've defined currentColumnType similarly to currentColumnId
      dropdownPosition.value = {
        top: rect.top + window.scrollY - 20,
        left: rect.left + window.scrollX - 10,
      };
      isDropdownOpen.value = true;
    }

    /*    const dropdownContent = computed(() => {
          switch (currentColumnType.value) {
            case 'unit_id':
              return units.value;
            case 'currency_id':
              return currencies.value;
            case 'user_categories_id':
              return categories.value;
            case 'status':
              return statuses.value;
            default:
              return []; // Default case to prevent errors
          }
        });*/

    const inputFilter = ref('');

    const setItemPropertyValue = (item, propertyName, event) => {
      // console.log('event', event);
      inputFilter.value = event; // Extract the value from the event
      // console.log('inputFilter', inputFilter.value);
      // console.log('item in setItemPropertyValue', item);
      // console.log('propertyName', propertyName);
      // selectDropDownElement(event, 'name');
    };


    const dropdownContent = computed(() => {
      const inputValue = inputFilter.value.toLowerCase(); // Assuming inputFilter is a ref holding the input value
      //  console.log('inputValue', inputValue);
      const columnType = currentColumnType.value;
      // console.log('columnType', columnType);
      switch (columnType) {
        case 'unit':
          return filterItems(units.value, inputValue);
        case 'currency':
          return filterItems(currencies.value, inputValue);
        case 'categories':
          return filterItems(categories.value, inputValue);
        case 'status':
          return filterItems(statuses.value, inputValue);
        case 'currency_purchase':
          //  return filterItems(currencyPurchase.value, inputValue);
          return filterItems(currencies.value, inputValue);
        default:
          return []; // Default case to prevent errors
      }
    });


// Function to filter dropdown items based on input value and name
    const filterItems = (items, inputValue) => {
      // console.log('items filter', items);
      //  console.log('inputValue for filter', inputValue); // empty
      let da = Object.values(items).filter(item => {
        //   console.log('item123', item);
        return item.name.toLowerCase().includes(inputValue);
      });
      // console.log('filtered data', da);
      return da;
    };

    /*    const getItemPropertyValue = (item, propertyName) => {
          if (propertyName === 'user_categories_id' && categories.value[item[propertyName]]) {
            return categories.value[item[propertyName]].name;
          } else if ((propertyName === 'currency_id' || propertyName === 'purchase_currency_id') && currencies.value[item[propertyName]]) {
            return currencies.value[item[propertyName]].name;
          } else if (propertyName === 'status' && statuses.value[item[propertyName]]) {
            return statuses.value[item[propertyName]].name;
          } else if (propertyName === 'unit_id' && units.value[item[propertyName]]) {
            return units.value[item[propertyName]].name;
          } else {
            return item[propertyName];
          }
        };*/

    const dropdownStyle = computed(() => {
      //   console.log('top', dropdownPosition.value.top)
      //  console.log('left', dropdownPosition.value.left)
      return {
        position: 'absolute',
        top: `${dropdownPosition.value.top}px`,
        left: `${dropdownPosition.value.left}px`,
      };
    });

    function showEditedProducts() {
      //  console.log('edited products', editedProducts.value);
    }

    const selectDropDownElement = (dropEleme) => {
      //  console.log('dropEleme', dropEleme);
      //  console.log('selectedElementId', selectedElementId.value);
      //  console.log('currentColumnType', currentColumnType.value);


      const editingProduct = products.value.find(product => product.id === selectedElementId.value);

      relateAndFillColumnValue(editingProduct, dropEleme, currentColumnType.value);
      // setNewValue(dropEleme, currentColumnType.value);
      isDropdownOpen.value = false;
      return;

      let inputValue = '';
      // для усіх у кого є id (категорія, валюта, одиниця виміру)
      if (dropEleme.id) {
        inputValue = dropEleme.id;
        isDropdownOpen.value = false;
        inputFilter.value = '';
        // console.log('join in id')
        // для статуса наявностіть available not available
      } else if (currentColumnType.value === 'status') {
        // console.log('jsoin in status')
        if (dropEleme.name === 'В наявності') {
          //  console.log('join in available')
          inputValue = 'available';
        } else {
          //  console.log('join in unavailable')
          inputValue = 'unavailable';
        }
        isDropdownOpen.value = false;
      } else {
        // console.log('join in else')
        inputValue = dropEleme;
        isDropdownOpen.value = false;
      }
      // console.log('inputValue2312', inputValue);
      // Встановлюємо обране дропдауном значення в обраному елементі
      products.value.find((product) => {
        if (product.id === selectedElementId.value) {
          if (dropEleme.id) {
            //  console.log('set by id')
            product[currentColumnType.value].id = dropEleme.id;
            product[currentColumnType.value].name = dropEleme.name;
          } else {
            //  console.log('currentColumnType.value', currentColumnType.value);
            product[currentColumnType.value] = inputValue;
          }
          // console.log('product[currentColumnType]', product[currentColumnType.value]);
          // console.log('product', product);
          //  product[currentColumnType.value] = inputValue; // для Product Status
        }
      });
    };

    const actionTitle = ref('Оберіть дію');
    const actionName = ref({
      'purchase_price': null,
      'price': null,
      'stock': null,
      'length': null,
      'height': null,
      'width': null,
      'weight': null,
    });
    const unitName = ref('од.');
    const currentColumnName = ref('');

    function showBulkActionInput(actionAtr, columnName) {

      console.log('actionAtr', actionAtr);
      console.log('columnName', columnName);

      actionTitle.value = actionAtr.shortName;
      actionName.value[columnName] = actionAtr.action;
      currentColumnName.value = columnName;

      // console.log('actionName', actionName.value);

      /*
      increaseIndividual
      setStaticValue
      increase
      decrease
      increaseInPercent
      decreaseInPercent
       */

      if (columnName === 'purchase_price' && (actionAtr.action === 'increase' || actionAtr.action === 'decrease')) {
        unitName.value = '.00';
      } else if (columnName === 'purchase_price' && (actionAtr.action === 'increaseInPercent' || actionAtr.action === 'decreaseInPercent')) {
        unitName.value = '%';
      } else if (columnName === 'price' && actionAtr.action === 'setStaticValue') {
        unitName.value = '';
      } else if (columnName === 'purchase_price' && actionAtr.action === 'setStaticValue') {
        unitName.value = '';
      } else if (columnName === 'stock' && actionAtr.action === 'setStaticValue') {
        unitName.value = 'од.';
      } else if (columnName === 'length' && actionAtr.action === 'setStaticValue') {
        unitName.value = 'см';
      } else if (columnName === 'height' && actionAtr.action === 'setStaticValue') {
        unitName.value = 'см';
      } else if (columnName === 'width' && actionAtr.action === 'setStaticValue') {
        unitName.value = 'см';
      } else if (columnName === 'weight' && actionAtr.action === 'setStaticValue') {
        unitName.value = 'кг';
      } else if (columnName === 'price' && (actionAtr.action === 'increase' || actionAtr.action === 'decrease')) {
        unitName.value = '.00';
      } else if (columnName === 'price' && (actionAtr.action === 'increaseInPercent' || actionAtr.action === 'decreaseInPercent')) {
        unitName.value = '%';
      } else if (columnName === 'stock' && (actionAtr.action === 'increase' || actionAtr.action === 'decrease')) {
        unitName.value = 'од.';
      } else if (columnName === 'stock' && (actionAtr.action === 'increaseInPercent' || actionAtr.action === 'decreaseInPercent')) {
        unitName.value = '%';
      } else if (columnName === 'length' && (actionAtr.action === 'increase' || actionAtr.action === 'decrease')) {
        unitName.value = 'см';
      } else if (columnName === 'length' && (actionAtr.action === 'increaseInPercent' || actionAtr.action === 'decreaseInPercent')) {
        unitName.value = '%';
      } else if (columnName === 'height' && (actionAtr.action === 'increase' || actionAtr.action === 'decrease')) {
        unitName.value = 'см';
      } else if (columnName === 'height' && (actionAtr.action === 'increaseInPercent' || actionAtr.action === 'decreaseInPercent')) {
        unitName.value = '%';
      } else if (columnName === 'width' && (actionAtr.action === 'increase' || actionAtr.action === 'decrease')) {
        unitName.value = 'см';
      } else if (columnName === 'width' && (actionAtr.action === 'increaseInPercent' || actionAtr.action === 'decreaseInPercent')) {
        unitName.value = '%';
      } else if (columnName === 'weight' && (actionAtr.action === 'increase' || actionAtr.action === 'decrease')) {
        unitName.value = 'кг';
      } else if (columnName === 'weight' && (actionAtr.action === 'increaseInPercent' || actionAtr.action === 'decreaseInPercent')) {
        unitName.value = '%';
      }

      secondDropdownItemsState.value[columnName] = false;
      dropdownItemsState.value[columnName] = true;

      if (actionAtr.action === 'increaseIndividual') {
        dropdownItemsState.value[columnName] = false;
      }
      // console.log('actionTitle', actionTitle.value);
      //  console.log('unitName', unitName.value);
    }

    const parseMethod = (columnId) => {
      switch (columnId) {
        case 'stock':
          return 'parseInt';
        case 'length':
          return 'parseInt';
        case 'height':
          return 'parseInt';
        case 'width':
          return 'parseInt';
        default:
          return 'parseFloat';
      }
    }

    const bulkActionInputValue = ref(null);

    function relateAndFillColumnValue(product, newValue, columnName) {

      console.log('product', product);
      /*
    product =  {
  "id": 40303,
  "uuid": "f4a5aeef-7d3e-4ab4-a9f3-57d95d075e62",
  "user_id": 66,
  "name": "Маленька чоловіча сумка на пояс, через плече, на сині джинси TARWA RK-1350-3md",
  "short_name": "Маленька чоловіча сумка на пояс, через плече, на сині джинси TARWA RK-1350-3md",
  "article": "75007",
  "code_product": null,
  "barcode": null,
  "status": "available",
  "user_categories_id": null,
  "description": null,
  "thumbnail_url": "https://content2.rozetka.com.ua/goods/images/big_tile/333655534.jpg",
  "deleted_at": null,
  "brand_id": null,
  "country_id": null,
  "unit_id": 3,
  "price": "600.02",
  "currency_id": 1,
  "purchase_price": "400.02",
  "purchase_currency_id": 1,
  "qty_in_stock": null,
  "length": 0,
  "height": 0,
  "width": 0,
  "weight": 1,
  "created_at": "2024-04-17T19:12:54.000000Z",
  "updated_at": "2024-05-13T18:19:47.000000Z",
  "hash": "22398f30793a58f0eb01baf678638c00ca219d7ff8f6bbe7512671b7bc8bca67",
  "rozetka_products_mapping": [],
  "prom_products_info_mapping": [],
  "stock": {
      "19": {
          "id": 32396,
          "product_id": 40303,
          "stock_qty": 83,
          "reserved_qty": 1,
          "on_return_qty": 0,
          "stock_name_id": 19,
          "stock_names": {
              "id": 19,
              "name": "Основний склад"
          }
      },
      "53": {
          "id": 53618,
          "product_id": 40303,
          "stock_qty": 70,
          "reserved_qty": 0,
          "on_return_qty": 0,
          "stock_name_id": 53,
          "stock_names": {
              "id": 53,
              "name": "Додатковий склад"
          }
      }
  },
  "unit": {
      "id": 3,
      "name": "шт."
  },
  "currency": {
      "id": 1,
      "name": "грн."
  },
  "currency_purchase": {
      "id": 1,
      "name": "грн."
  },
  "categories": null,
  "brand": null,
  "country": null
}
       */
      console.log('columnName', columnName); // columnName categories
      console.log('newValue234', newValue); //
      /*
            newValue =  {
          "id": 493,
          "parent_id": null,
          "user_id": 66,
          "category_id": null,
          "marketplaces_categories": null,
          "name": "Подорож/Шкіряні спортивні сумки",
          "code_group": 1117,
          "length": 0,
          "height": 0,
          "width": 0,
          "cargo_description_id": null,
          "weight": 0,
          "description": null,
          "created_at": "2024-04-17T19:12:54.000000Z",
          "updated_at": "2024-04-17T19:12:54.000000Z"
      }
       */

      if (columnName === 'status') {

        if (newValue.name === 'В наявності') {
          //  console.log('join in available')
          product.status = 'available';
        } else {
          product.status = 'unavailable';
        }
        //  product.status = newValue;
      } else if (   // це вибор з дропдауна, не відноситься до вільного вводу значень
          columnName === 'categories'
          || columnName === 'currency'
          || columnName === 'currency_purchase'
          || columnName === 'unit'
          || columnName === 'brand'
          || columnName === 'country'
          || columnName === 'unit'
      ) {


        if (!product[columnName]) { // додав бо була помилка
          product[columnName] = {};
        }

        product[columnName].id = newValue.id; // TypeError: Cannot set properties of null (setting 'id')
        product[columnName].name = newValue.name;
        product[columnWithRelationId[columnName]] = newValue.id;
      } else if (
          columnName === 'purchase_price'
          || columnName === 'price'
          || columnName === 'length'
          || columnName === 'height'
          || columnName === 'width'
          || columnName === 'weight'
          || columnName === 'stock') {
        let actionResult = applyOperation(product, columnName, actionName.value[columnName], newValue);

        // If the operation was for 'stock', update the nested property
        if (columnName === 'stock') {
          product.stock[selectedStockName.value].stock_qty = actionResult;
        } else if (columnName === 'price') {
          console.log('join in column name = price', product)
          product.prices[selectedPriceTypeId.value].price = actionResult;
          product.prices[selectedPriceTypeId.value].price_type_id = selectedPriceTypeId.value;
        } else {
          // For other properties, update the property directly
          product[columnName] = actionResult;
        }
      } else {
        // console.log('join in else');
        setValue.value = true;
        product[columnName] = newValue;
      }

    }

    function applyOperation(product, columnName, actionName, newValue) {
      let currentValue, newVal;

      console.log("product", product);
      console.log('columnName', columnName);
      console.log('actionName', actionName);
      console.log('newValue', newValue);
      //  console.log('parseMethod', parseMethod);

      if (columnName === 'stock') {
        currentValue = parseFloat(product.stock[selectedStockName.value].stock_qty || 0);
        newVal = parseFloat(newValue);
      } else {
        let columnValue = product[columnName] || 0;
        currentValue = parseMethod(columnName) === 'parseInt' ? parseInt(columnValue, 10) : parseFloat(columnValue);

        if (columnName === 'price' && selectedRecalulateColumnValue.value === 'Закупівельна ціна') {
          columnName = 'purchase_price';
          console.log('columnName', columnName);
          currentValue = parseMethod(columnName) === 'parseInt' ? parseInt(product[columnName], 10) : parseFloat(product[columnName]);
        } else if (columnName === 'price' && selectedRecalulateColumnValue.value === 'Поточна колонка') {

          columnName = 'price';
          console.log('columnName', columnName);
          currentValue = parseFloat(product.prices[selectedPriceTypeId.value].price);

        }

        newVal = parseMethod(columnName) === 'parseInt' ? parseInt(newValue, 10) : parseFloat(newValue);
      }


      switch (actionName) {
        case 'setStaticValue':
          return newVal;
        case 'increase':
          return currentValue + newVal;
        case 'decrease':
          return currentValue - newVal;
        case 'increaseInPercent':
          return currentValue + (currentValue * newVal / 100);
        case 'decreaseInPercent':
          return currentValue - (currentValue * newVal / 100);
        default:
          return currentValue; // Return the current value if the action is not recognized
      }
    }

    const setNewValue = (newValue, columnName) => {
      newValue = newValue !== null ? newValue : bulkActionInputValue.value;

      selectedProducts.value.forEach(productId => {
        products.value.find(product => {
          if (product.id === productId) {
            relateAndFillColumnValue(product, newValue, columnName);
          }
        });
      });
      dropdownItemsState.value[columnName] = false;
    }

    const isAnyRowSelected = computed(() => {
      return selectedProducts.value.length > 0;
    });

    const saveChanges = async () => {
      spinnerState.value = true;
      // get edited products by product id
      const dataForSave = {
        products: [],
        stock: [],
        //  productsForCreate: [],
        prices: [],
      };

      // console.log('editedProducts', editedProducts.value);

      products.value.forEach(product => {
        // Check if the product is in the edited products list
        if (editedProducts.value.products.includes(product.id)) {
          let productCopy = {...product};  // Create a shallow copy of the product

          // Remove unnecessary properties
          delete productCopy.prom_products_info_mapping;
          delete productCopy.rozetka_products_mapping;
          delete productCopy.unit;
          delete productCopy.currency;
          delete productCopy.currency_purchase;
          delete productCopy.categories;
          delete productCopy.brand;
          delete productCopy.country;
          delete productCopy.created_at;
          delete productCopy.updated_at;

          // Handle new products
          if (product.new_product === true) {
            productCopy.id = null;
            delete productCopy.new_product;
          }
          dataForSave.products.push(productCopy);
        }


        // Check if the stock is in the edited stock list


        // console.log('editedProducts.value', editedProducts.value);

        if (editedProducts.value.stock.includes(product.id)) {
          console.log('stock have changes', product);

          Object.values(product.stock).forEach(stock => {
            let stockCopy = {...stock};  // Create a shallow copy of the stock item

            delete stockCopy.stock_names;

            // Determine the update method and modify stock_qty accordingly
            console.log('actionName.value', actionName.value);

            if (actionName.value.stock === 'increaseIndividual') {
              console.log('join in increaseIndividual');
              stockCopy.update_stock_method = 'calculate';
              stockCopy.stock_qty = stock.add_to_stock_qty || 0;
              stock.stock_qty = parseInt(stock.stock_qty, 10) + parseInt(stock.add_to_stock_qty, 10);
              delete stock.add_to_stock_qty;
            } else {
              stockCopy.update_stock_method = 'replace';
            }

            dataForSave.stock.push(stockCopy);
          });
        } else if (editedProducts.value.prices.includes(product.id)) {
          Object.values(product.prices).forEach(price => {
            let priceCopy = {...price};  // Create a shallow copy of the stock item
            dataForSave.prices.push(priceCopy);
          });
        }


      });

      console.log('dataForSave', dataForSave);

      const response = await apiCall('post', '/update-products', dataForSave);
      //  console.log('update product response', response);
      editedProducts.value = {
        products: [],
        stock: [],
        prices: [],
      };

      spinnerState.value = false;

    }


    function closeDropdown() {
      isDropdownOpen.value = false;
    }

    const inputWidth = computed(() => {
      return actionName.value.stock === 'increaseIndividual' ? '63px' : '126px';
    });

    const isRowSelected = computed(() => {

      return (productId) => {
        //  console.log('selectedProducts.value', selectedProducts.value);
        //  console.log('productId', productId);
        return selectedProducts.value.includes(productId);
      };
    });


    function importProduct(resource) {
      if (resource === 'rozetka') {
        getRozetkaProducts();
      } else if (resource === 'prom') {
        getPromProducts();
      } else if (resource === 'kasta') {
        getKastaProducts();
      } else if (resource === 'opencart') {
        getOpencartProducts();
      } else if (resource === 'horoshop') {
        getHoroshopProducts();
      }
    }

    function stockQty(item) {
      // console.log('item', item) ;
      return item.stock[selectedStockName.value]?.stock_qty ?? 0;
    }

    async function deleteSelectedProducts() {
      await deleteProducts({
        productIds: selectedProducts.value
      }).catch(err => {
        console.log('err', err);
      }).finally(() => {
        products.value = products.value.filter(product => !selectedProducts.value.includes(product.id));
        selectedProducts.value = [];
      });
    }


    const ensurePriceExists = (item) => {
      console.log('focus colled', item)
      if (!item.prices[selectedPriceTypeId.value]) {
        item.prices[selectedPriceTypeId.value].price = 0;
      }
    };


    const ensurePricesExist = () => {
      products.value.forEach((item) => {
        if (!item.prices[selectedPriceTypeId.value]) {
          item.prices[selectedPriceTypeId.value] = {
            id: null, // or a default id if necessary
            price: 0.00,
            price_type_id: selectedPriceTypeId.value,
            product_id: item.id,
            user_id: null // or a default user id if necessary
          };
        }
      });
    };


    const ensureStocksExist = () => {
      products.value.forEach((item) => {
        if (!item.stock[selectedStockName.value]) {
          item.stock[selectedStockName.value] = {
            id: null, // or a default id if necessary
            stock_qty: 0,
            stock_name_id: selectedStockName.value,
            product_id: item.id,
            user_id: null // or a default user id if necessary
          };
        }
      });
    };

    selectPriceType(localStorage.getItem('senderSettings') ? JSON.parse(localStorage.getItem('senderSettings')).default_price_type_id : null);

    const searchQuery = ref('');

    // Обчислюване значення для фільтрації категорій
    const filteredCategories = computed(() => {
      if (!searchQuery.value) {
        return Object.values(categories.value);
      }
      const query = searchQuery.value.toLowerCase();
      return Object.values(categories.value).filter(category =>
          category.name.toLowerCase().split(' ').some(word => word.startsWith(query))
      );
    });

    const priceTypesResponseHandler = (data) => {
      console.log('priceTypesResponse', data)
      priceTypes.value = data
    }

    return {
      openDropdown,
      //  getItemPropertyValue,
      setItemPropertyValue,
      dropdownStyle,
      isDropdownOpen,
      handleOpen,
      inputWidth,
      filteredProducts,
      headers,
      selectedProducts,
      products,
      categories,
      brands,
      countries,
      units,
      spinnerState,
      isRowSelected,
      searchTerm,
      // filteredCategories,
      //  stopPropagation,
      currencies,
      // openModal,
      selectDropDownElement,
      statuses,
      dropdownContent,
      setNewValue,
      dropdownItemsState,
      secondDropdownItemsState,
      setValue,
      showBulkActionInput,
      actionName,
      actionTitle,
      isAnyRowSelected,
      unitName,
      bulkActionInputValue,
      selectedRecalulateColumnValue,
      saveChanges,
      // showEditedProducts,
      isEditedProductsEmpty,
      createProduct,
      copySelectedProduct,
      importProduct,
      stockNames,
      selectStock,
      selectPriceType,
      selectedStockName,
      stockQty,
      deleteSelectedProducts,
    //  preventableModal,
    //  editPriceTypes,
      priceTypes,
     // addPriceType,
    //  savePriceTypes,
      saveCategories,
      selectedPriceTypeId,
      ensurePriceExists,

      editCagerories,
      categoriesModal,
      addCategory,
      categoryInputValue,
      searchQuery,
      filteredCategories,
      /*      onDropdownShown(name, model) {
              console.log(`Dropdown ${name} shown with model:`, model);
            },
            onDropdownHidden(name, model) {
              console.log(`Dropdown ${name} hidden with model:`, model);
            }*/

      selectedRecalulateColumn,
      priceTypesResponseHandler
    };

  },

  components: {
    RecycleScroller,
    VueMultiselect,
    cloneDeep,
    draggable,
    BFormCheckbox,
    BFormTextarea,
    BSpinner,
    BFormInput,
    BButton,
    BButtonGroup,
    BButtonToolbar,
    BDropdown,
    BDropdownItem,
    BPopover,
    BModal,
    priceTypes

  },


}
</script>

<style scoped>

.input-group {
  display: flex;
}

.static-width-3100px {
  width: 3100px;
}

.input-group > * {
  margin-right: 5px; /* Adjust spacing between the elements */
}

.input-group > :last-child {
  margin-right: 0; /* Remove margin for the last element */
}

.min-vh-40 {
  min-height: 40px;
}

.form-control {
  font-size: 0.75rem;
  border-radius: 0;
  border-left: unset;
  #border-bottom: unset;
}

.dropdown-item {
  background-color: white !important;
}

.btn-fixed-bottom-right {
  z-index: 11;
  width: 180px;
  position: fixed;
  right: 20px; /* Adjust as needed */
  bottom: 20px;
}

.input-group.input-group-sm.ml-3.mr-3.w300px {
  width: 351px;
  #border-bottom: 1px #dde2e6 solid;
  margin: 0 5px;
  #background-color: #69757d;
}


.newable {
  z-index: 8888888888999999999999998888;
  #display: block;
  #width: 300px;
  #height: 200px;
  background-color: white;
  border-radius: 0;
  border: 1px solid #e0e0e0;
  position: absolute;
}

.max-height-100px {
  max-height: 100px;
}

</style>