<template>


  <!--  @hidden="closeOrderModal"
    @hide="closeOrderModal"
    @close="closeOrderModal"-->

  <BModal
      size="lg"
      scrollable

      hide-footer
      class="p-3"
      :id="myId"
      lazy
  >
    <!-- Кастомний заголовок модального вікна -->
    <template #header>
      <div class="d-flex justify-content-between align-items-center w-100">
        <!-- Заголовок модального вікна -->
        <h5 class="mb-0">{{ modalTitle }}</h5>
        <SupportButtonDropdown/>
        <!-- Кнопка закриття модального вікна -->
        <BCloseButton @click="closeOrderModal"/>
      </div>

    </template>

    <div>
      <BSpinner v-if="mainSpiner" style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow"/>
      <BTabs v-else v-model="globalTabs" class="parent-tabs">
        <!-- Вкладка для API ключа Нової Пошти -->
        <BTab title="Перевізники" key="shippers" active>
          <BTabs v-model="shippingTabs" size="sm" class="child-tabs">
            <BTab title="Нова Пошта" key="np" active>
              <BTabs v-model="npSubTabs">
                <BTab title="Кабінети НП/Відправники" key="npsenders" active>
                  <!-- створення кабінету НП -->
                  <!--  кабінети НП -->
                  <BCard class="m-2">
                    <template>
                      <BRow v-if="Object.keys(npCabinetsForEditing).length > 0">
                        <div>
                          <template>
                            <BTableSimple hover small caption-top responsive>
                              <colgroup>
                                <col/>
                                <col/>
                                <col/>
                              </colgroup>
                              <BThead head-variant="dark">
                                <BTr>
                                  <BTh>Відправник</BTh>
                                  <BTh>Контакна особа</BTh>
                                  <BTh>Контрагент</BTh>
                                </BTr>
                              </BThead>
                              <BTbody>
                                <BTr v-for="npCabinetForEditing in npCabinetsForEditing"
                                     :key="npCabinetForEditing.contact_person_id"
                                >
                                  <BTd>
                                    <BButton size="sm" type="outline-primary"
                                             @click="selectContactPerson(npCabinetForEditing)">
                                      Ред
                                    </BButton>
                                  </BTd>
                                  <BTd>{{ npCabinetForEditing.contactPersonaName }}</BTd>
                                  <BTd>{{ npCabinetForEditing.CounterpartyFullName }}</BTd>
                                  <BTd>
                                    <span v-if="npCabinetForEditing.defaultSenderGlobaly">За умовч</span>
                                  </BTd>
                                </BTr>
                              </BTbody>
                            </BTableSimple>
                          </template>
                        </div>
                      </BRow>
                      <BRow v-else>
                        <b>Відправники відсутні, додайти новий кабінет</b>
                      </BRow>
                      <!--                    <div v-if="addNewNpCabinet || selectedNpContactPerson.contact_person_id !== null">-->
                      <BFormText class="link-info">Скопіюйте api ключ від мобільного додатку у <a
                          href="https://new.novaposhta.ua/dashboard/settings/developers"
                          target="_blank">
                        кабінеті Нової Пошти</a>
                      </BFormText>
                      <BFormInput
                          v-model="novaPoshaApikey"
                          class="m-2"
                          :state="novaPoshaApikey.length === 32"
                          :placeholder="customPlaceholder"
                      />
                      <div class="border-1">
                        <BRow>
                          <BInputGroup size="sm" prepend="Опис вантажу за умовченням">
                            <template #append>
                              <BDropdown size="sm"
                                         boundary="viewport"
                                         container="body"
                                         :text="selectedNpContactPerson?.cargo_description?.name === '' ? 'Обрати опис вантажу' : selectedNpContactPerson?.cargo_description?.name"
                                         variant="outline-secondary">
                                <BFormInput @click.stop
                                            type="text"
                                            v-model="inputSearchValue"
                                            placeholder='Дрібна побутова техніка'
                                            @keyup.enter.prevent="search('cargoDesc', inputSearchValue)"
                                            @input="search('cargoDesc', inputSearchValue)"
                                />
                                <BDropdownItem
                                    v-for="(cargoDesc, index) in searchResults"
                                    :key="index"
                                    @click="selectCagroDesc(cargoDesc)">
                                  {{ cargoDesc?.name }}
                                </BDropdownItem>
                              </BDropdown>
                            </template>
                          </BInputGroup>
                          <!--                          загальні налаштування при додаванні кабінету (копія коду)-->
                          <template v-if="Object.keys(npCabinetsForEditing).length === 0">
                            <BCard class="m-2">
                              <BRow>
                                <BInputGroup size="sm" prepend="Додатковий опис вантажу">
                                  <template #append>
                                    <BDropdown size="sm"
                                               :text="senderSettings.article_or_name === 'name' ? 'Назва товару' : 'Артикуль товару'"
                                               variant="outline-secondary">
                                      <BDropdownItem
                                          v-for="(descriptions, index) in addiditionalTTnInfo"
                                          :key="index"
                                          @click="setNewValueForSelectedNpContactPerson('article_or_name', descriptions.value, 'senderSettings')">
                                        {{ descriptions.text }}
                                      </BDropdownItem>
                                    </BDropdown>
                                    <BButton variant="info" class="btn-info-circle" v-b-tooltip.hover
                                             title='У поле "На ТТН э поле Додатковий опис вантажу, для зручності упаковуння туди можно виводити назву товарів, або артикулі. Якщо в основному у кожному замовленні багато товарів, то вкажіть артикуль, якщо ні, то назву товару'>
                                      ?
                                    </BButton>
                                  </template>
                                </BInputGroup>
                              </BRow>
                              <BFormCheckbox size="sm" v-model="senderSettings.senderIsDeliveryPayer">Чи виступаєте ви
                                платником
                                за
                                доставку,
                                в залежності від
                                вартості замовлення?(поставте прапорець якщо так)
                              </BFormCheckbox>
                              <template v-if="senderSettings.senderIsDeliveryPayer">
                                <label for="senderPayerIfSumBigger">Починаючи з якої вартості замовлення, ви є платником
                                  доставки</label>
                                <BInputGroup size="sm" id="senderPayerIfSumBigger1" prepend="грн" append=".00">
                                  <BFormInput :state="senderSettings.senderPayerIfSumBigger !== 0"
                                              v-model="senderSettings.senderPayerIfSumBigger"/>
                                </BInputGroup>
                              </template>

                              <BFormCheckbox size="sm" v-model="senderSettings.create_return_id_if_canceled">Автоматично
                                створювати
                                повернення
                                відправлення якщо від посилки відмовились
                              </BFormCheckbox>
                              <BFormCheckbox size="sm" v-model="senderSettings.create_return_if_storage_n_days">
                                Автоматично
                                створювати
                                повернення якщо незабаром почнеться платне зберігання та замовлення не сплачене
                              </BFormCheckbox>
                              <BRow>
                                <template
                                    v-if="senderSettings.create_return_id_if_canceled || senderSettings.create_return_if_storage_n_days">
                                  <label for="min_product_price_for_return">Починаючи з якої вартості замовлення,
                                    створювати
                                    повернення?</label>
                                  <BInputGroup size="sm" id="min_product_price_for_return1"
                                               prepend="грн" append=".00">
                                    <BFormInput
                                        type="number"
                                        step="0.01"
                                        v-model="senderSettings.min_product_price"/>
                                  </BInputGroup>
                                </template>
                              </BRow>
                              <BRow>
                                <BInputGroup size="sm"

                                             prepend="Вага відправлення за умовченням" append="кг">
                                  <BFormInput type="number"
                                              step="0.1"
                                              v-model="senderSettings.default_weight"/>
                                </BInputGroup>
                              </BRow>
                              <BButton variant="success" v-if="npSenders?.length > 0" @click="saveSenderSettings">
                                Зберегти
                                зміни
                              </BButton>
                            </BCard>
                          </template>
                        </BRow>
                      </div>
                      <!--                    </div>-->
                      <!-- додати новий кабінет кнопка-->
                      <div v-if="selectedNpContactPerson.contact_person_id === null">
                        <!--                      <BButton size="sm" variant="outline-primary" @click="addNewNpCabinet = !addNewNpCabinet">
                                                {{ addNewNpCabinet ? 'Скасувати' : 'Додати новий кабінет' }}
                                              </BButton>-->
                        <BButton size="sm" variant="primary" v-if="novaPoshaApikey !== ''" @click="saveApiKeyNp">
                          Зберегти кабінет
                        </BButton>
                      </div>
                      <!-- редагування кабінету детально-->
                      <div v-if="selectedNpContactPerson.contact_person_id !== null">
                        <BFormCheckbox size="sm" v-model="selectedNpContactPerson.defaultSenderGlobaly">
                          Використовувати цього відправника за умовчанням
                        </BFormCheckbox>
                        <BFormCheckbox size="sm" v-model="selectedNpContactPerson.import_ttns_from_lk ">
                          Імпортувати ТТН з особистого кабінету Нової Пошти
                        </BFormCheckbox>
                        <BFormCheckbox size="sm" v-model="selectedNpContactPerson.disabled">
                          Кабінет не активний (не буде показуватись у списку відправників у замовленні, при потребі
                          можно
                          активувати)
                        </BFormCheckbox>

                        <!--        <BDropdown size="sm" :text="paymentTypes[selectedNpContactPerson.payment_method].name"
                                           variant="outline-secondary">
                                  <BDropdownItem
                                      v-for="(paymentType, index) in paymentTypes"
                                      :key="index"
                                      @click="selectedNpContactPerson.payment_method = index">
                                    {{ paymentType.name }}
                                  </BDropdownItem>
                                </BDropdown>-->
                        <BRow>
                          <BInputGroup size="sm" prepend="Спосіб оплати відправки за умовченням">
                            <template #append>
                              <BDropdown size="sm"
                                         :text="contactPersonDefaultPaymentType"
                                         variant="outline-secondary">
                                <BDropdownItem
                                    v-for="(paymentType, index) in paymentTypes"
                                    :key="index"
                                    @click="setNewValueForSelectedNpContactPerson('payment_method_id', index, 'selectedNpContactPerson')">
                                  {{ paymentType.name }}
                                </BDropdownItem>
                              </BDropdown>
                              <BButton variant="info" class="btn-info-circle" v-b-tooltip.hover
                                       title="Це поле використовується для автоматичного вибору типу оплати замовлення при ручному створенні замовлення. Тобто ви відкриваєте замовлення у вас автоматично буде обирано як відправляється замовлення, з післяплатою або без післяплати.">
                                ?
                              </BButton>
                            </template>
                          </BInputGroup>
                        </BRow>


                        <BRow>
                          <BInputGroup size="sm" prepend="Платник доставки вантажу за умовченням">
                            <template #append>
                              <BDropdown size="sm"
                                         :text="selectedNpContactPerson.default_payer_id === 3 ?  payerTypes[3].name : payerTypes[4].name"
                                         variant="outline-secondary">
                                <BDropdownItem
                                    v-for="(payerType, index) in payerTypes"
                                    :key="index"
                                    @click="setNewValueForSelectedNpContactPerson('default_payer_id', index, 'selectedNpContactPerson')">
                                  {{ payerType.name }}
                                </BDropdownItem>
                              </BDropdown>
                              <BButton variant="info" class="btn-info-circle" v-b-tooltip.hover
                                       title='Обраний платик доставки буде автоматично обиратись при ручному створенні замовлення та при імпорті замовлень з сайтів. Якщо ви є платником доставки в залежності від суми, то тут поставте \"Одержувач\", а нижче є налаштування, де можно поставити прапорець та вкажіть суму від якої ви є платником.'>
                                ?
                              </BButton>
                            </template>
                          </BInputGroup>
                        </BRow>

                        <!--     <BFormCheckbox size="sm" v-model="selectedNpContactPerson.non_cache">
                             Безготівковий розрахунок з Новою поштою за відправки (За умовчанням)
                           </BFormCheckbox>
                         <BFormCheckbox size="sm" v-model="selectedNpContactPerson.payment_control">
                             Контроль оплати за умовчанням для післяплати (інакше грошовий переказ)
                           </BFormCheckbox>-->

                        <div class="d-flex space-between" v-if="selectedNpContactPerson?.contactPersonaName">
                          <BButton variant="success"
                                   @click="saveNpContactPersonChanges">Зберегти зміни
                            <BSpinner v-if="saveNewWarehouseSpinner" small/>
                          </BButton>
                          <BButton variant="outline-danger" @click="clearSelectedContactPersonValues">Відминити
                            редагування
                          </BButton>
                        </div>
                      </div>
                    </template>
                  </BCard>
                </BTab>
                <BTab title="Загальні налаштування" key="SenderSettings">

                  <template>
                    <BCard class="m-2">
                      <BRow>
                        <BInputGroup size="sm" prepend="Додатковий опис вантажу">
                          <template #append>
                            <BDropdown size="sm"
                                       :text="senderSettings.article_or_name === 'name' ? 'Назва товару' : 'Артикуль товару'"
                                       variant="outline-secondary">
                              <BDropdownItem
                                  v-for="(descriptions, index) in addiditionalTTnInfo"
                                  :key="index"
                                  @click="setNewValueForSelectedNpContactPerson('article_or_name', descriptions.value, 'senderSettings')">
                                {{ descriptions.text }}
                              </BDropdownItem>
                            </BDropdown>
                            <BButton variant="info" class="btn-info-circle" v-b-tooltip.hover
                                     title='У поле "На ТТН э поле Додатковий опис вантажу, для зручності упаковуння туди можно виводити назву товарів, або артикулі. Якщо в основному у кожному замовленні багато товарів, то вкажіть артикуль, якщо ні, то назву товару'>
                              ?
                            </BButton>
                          </template>
                        </BInputGroup>
                      </BRow>


                      <BFormCheckbox size="sm" v-model="senderSettings.senderIsDeliveryPayer">Чи виступаєте ви платником
                        за
                        доставку,
                        в залежності від
                        вартості замовлення?(поставте прапорець якщо так)
                      </BFormCheckbox>
                      <template v-if="senderSettings.senderIsDeliveryPayer">
                        <label for="senderPayerIfSumBigger">Починаючи з якої вартості замовлення, ви є платником
                          доставки</label>
                        <BInputGroup size="sm" id="senderPayerIfSumBigger" prepend="грн" append=".00">
                          <BFormInput :state="senderSettings.senderPayerIfSumBigger !== 0"
                                      v-model="senderSettings.senderPayerIfSumBigger"/>
                        </BInputGroup>
                      </template>

                      <BFormCheckbox size="sm" v-model="senderSettings.create_return_id_if_canceled">Автоматично
                        створювати
                        повернення
                        відправлення якщо від посилки відмовились
                      </BFormCheckbox>
                      <BFormCheckbox size="sm" v-model="senderSettings.create_return_if_storage_n_days">Автоматично
                        створювати
                        повернення якщо незабаром почнеться платне зберігання та замовлення не сплачене
                      </BFormCheckbox>
                      <BRow>
                        <template
                            v-if="senderSettings.create_return_id_if_canceled || senderSettings.create_return_if_storage_n_days">
                          <label for="min_product_price_for_return">Починаючи з якої вартості замовлення, створювати
                            повернення?</label>
                          <BInputGroup size="sm" id="min_product_price_for_return"
                                       prepend="грн" append=".00">
                            <BFormInput
                                type="number"
                                step="0.01"
                                v-model="senderSettings.min_product_price"/>
                          </BInputGroup>
                        </template>
                      </BRow>
                      <BRow>
                        <BInputGroup size="sm"

                                     prepend="Вага відправлення за умовченням" append="кг">
                          <BFormInput type="number"
                                      step="0.1"
                                      v-model="senderSettings.default_weight"/>
                        </BInputGroup>
                      </BRow>

                      <BButton variant="success" v-if="npSenders?.length > 0" @click="saveSenderSettings">Зберегти
                        зміни
                      </BButton>

                    </BCard>
                  </template>
                </BTab>
                <BTab title="Відділення відправника" key="senderWarehouses">
                  <div>
                    <BRow v-if="Object.keys(senderAddressNp).length > 0">
                      <BInputGroup size="sm" prepend="Відділення відправника за умовченням">
                        <template #append>
                          <BDropdown size="sm"
                                     boundary="viewport"
                                     container="body"
                                     :text="senderAddressNp[selectedSenderWarehouse]?.name ?? 'Додайте нове відділення'"
                                     variant="outline-secondary">
                            <BDropdownItem
                                v-for="senderAddress in Object.values(senderAddressNp)"
                                :key="senderAddress.id"
                                @click="selectSenderWarehouse(senderAddress)"
                            >
                              {{ senderAddress.name }}
                            </BDropdownItem>
                          </BDropdown>
                          <BButton v-if="warehouseWasChanged" variant="success"
                                   @click="saveWarehouse('updateDefaultWarehouse')">
                            Зберегти зміни
                          </BButton>
                          <!--                                <BButton variant="info" class="btn-info-circle" v-b-tooltip.hover
                                                                   title='Виберіть відправника, який буде використовуватись за умовчанням при створенні замовлення'>
                                                            ?
                                                          </BButton>-->
                        </template>
                      </BInputGroup>
                    </BRow>

                    <template v-if="Object.keys(senderAddressNp).length === 0 || addingNewWarehouse">
                      <BRow>
                        <BInputGroup size="sm" prepend="Місто відправника">
                          <template #append>

                            <BDropdown size="sm"
                                       :text="selectedDefaultWarehouse.city?.name ?? 'Оберіть місто'"
                                       variant="outline-secondary"
                                       boundary="viewport"
                                       container="body"
                            >
                              <BFormInput @click.stop
                                          v-model="inputSearchValue"
                                          placeholder='Введіть назву міста'
                                          @keyup.enter.prevent="search('city', inputSearchValue)"
                                          @input="search('city', inputSearchValue)"
                              />
                              <BDropdownItem
                                  v-for="(city, index) in searchResults"
                                  :key="index"
                                  @click="selectCity(city)">

                                {{ city.name }}
                              </BDropdownItem>
                            </BDropdown>
                          </template>
                        </BInputGroup>
                      </BRow>
                      <BRow>
                        <BInputGroup size="sm" prepend="Відділення відправника">
                          <template #append>
                            <BDropdown size="sm"
                                       :text="selectedDefaultWarehouse.sender_warehouse?.name ?? 'Оберіть відділення'"
                                       boundary="viewport"
                                       container="body"
                                       variant="outline-secondary">
                              <BFormInput @click.stop
                                          type="number"
                                          v-model="inputSearchValue"
                                          placeholder='Введіть номер відділення'
                                          @keyup.enter.prevent="search('npWarehouse', inputSearchValue)"
                                          @input="search('npWarehouse', inputSearchValue)"
                              />
                              <BDropdownItem
                                  v-for="(warehouse, index) in searchResults"
                                  :key="index"
                                  @click="selectWarehouse(warehouse)">
                                {{ warehouse?.name }}
                              </BDropdownItem>
                            </BDropdown>
                          </template>
                        </BInputGroup>
                      </BRow>
                    </template>

                    <BRow v-if="selectedDefaultWarehouse.city.id !== null
              && selectedDefaultWarehouse.sender_warehouse.id !== null
              && Object.keys(senderAddressNp).length > 0">

                      <BButton variant="success" @click="saveWarehouse('create')">
                        <BSpinner v-show="spinerSaveWarehouseActive" small/>
                        Зберегти відділеня відправника
                      </BButton>
                    </BRow>

                    <BRow v-if="Object.values(senderAddressNp).length > 0">
                      <BButton variant="outline-primary" @click="addingNewWarehouse = !addingNewWarehouse">Додати
                        відділення відправника
                      </BButton>
                    </BRow>
                  </div>
                </BTab>


              </BTabs>


            </BTab>
            <BTab title="Укрпошта" key="urkposhtaApiKey" lazyOnce>
              <template>
                <BRow v-if="senderSettingsFromDb.urk_post_sender_data.length > 0 ">


                  <!--                <BInputGroup size="sm" prepend="Відправник">
                                    <template #append>
                                      <div>
                                        <BDropdown size="sm"
                                                   :text="ukrPoshtaData.sender_name  === '' ? 'Оберіть відправника для редагування' : ukrPoshtaData.sender_name"
                                                   variant="outline-secondary">
                                          <BDropdownItem
                                              v-for="upCabinetForEditing in senderSettingsFromDb.urk_post_sender_data"
                                              :key="upCabinetForEditing.id"
                                              @click="selectUpCabinetId(upCabinetForEditing)">
                                            {{ upCabinetForEditing.sender_name }}
                                          </BDropdownItem>
                                        </BDropdown>
                                      </div>
                                    </template>
                                  </BInputGroup>-->


                  <BTableSimple hover small caption-top responsive>
                    <colgroup>
                      <col/>
                      <col/>
                      <col/>
                    </colgroup>
                    <BThead head-variant="dark">
                      <BTr>
                        <BTh>Дії</BTh>
                        <BTh>Назва</BTh>
                      </BTr>
                    </BThead>
                    <BTbody>
                      <BTr v-for="upCabinetForEditing in senderSettingsFromDb.urk_post_sender_data"
                           :key="upCabinetForEditing.id"
                      >
                        <BTd>
                          <BButton size="sm" type="outline-primary"
                                   @click="selectUpCabinetId(upCabinetForEditing)">
                            Ред
                          </BButton>
                        </BTd>
                        <BTd>{{ upCabinetForEditing.sender_name }}</BTd>

                      </BTr>
                    </BTbody>
                  </BTableSimple>
                </BRow>
                <template>
                  <BButton variant="primary" v-if="showUpFields !== true" @click="showUpFields = true">
                    Додати кабінет відправника
                  </BButton>
                </template>

                <BCard v-if="showUpFields">


                  <!--                <BRow :variant="responseStatus" v-if="requestMessageStatus">{{
                                      responseMessage
                                    }}
                                  </BRow>-->

                  <BFormText> Вам треба укласти договір з Укрпоштою. <br>
                    API ключі приходять на пошту від <a
                        href="mailto:pentela-mv@ukrposhta.ua">pentela-mv@ukrposhta.ua</a>
                  </BFormText>
                  <BRow>
                    <BInputGroup size="sm" prepend="Відправник">
                      <BFormInput placeholder="ФОП Шевченко Т.Г." :state="ukrPoshtaData.sender_name !== ''"
                                  v-model="ukrPoshtaData.sender_name"/>
                    </BInputGroup>
                  </BRow>
                  <BRow>
                    <BInputGroup size="sm" prepend="Індекс">
                      <BFormInput placeholder="54051" :state="ukrPoshtaData.index?.length === 5"
                                  v-model="ukrPoshtaData.index"/>
                    </BInputGroup>
                  </BRow>
                  <BRow>
                    <BFormInput placeholder="email (не обов'язково)" v-model="ukrPoshtaData.email"/>
                  </BRow>
                  <BRow>
                    <BInputGroup>
                      <template #prepend>
                        <BDropdown size="sm" :text="edroupOrIpnValue.text" variant="info">
                          <BDropdownItem @click="selectEdrouIpn('edrpou')">ЕДПРОУ</BDropdownItem>
                          <BDropdownItem @click="selectEdrouIpn('ipn')">ІПН</BDropdownItem>
                        </BDropdown>
                      </template>
                      <BFormInput size="sm" :state="innVal !== null" :placeholder="edroupOrIpnValue.placeholder"
                                  v-model="innVal"/>
                    </BInputGroup>
                  </BRow>
                  <!--      <BInputGroup size="sm" prepend="ЕДПРОУ">
                        <BFormInput placeholder="ЕДПРОУ" v-model="ukrPoshtaData.edrpou"/>
                        </BInputGroup>
                        <BInputGroup size="sm" prepend="ІПН">
                        <BFormInput placeholder="ІПН" v-model="ukrPoshtaData.tin"/>
                        </BInputGroup>-->
                  <BRow>
                    <BInputGroup size="sm" prepend="IBAN">
                      <BFormInput size="sm" placeholder="UA023052990000026001020808537"
                                  :state="ukrPoshtaData.bank_account?.length === 29"
                                  v-model="ukrPoshtaData.bank_account"/>
                    </BInputGroup>
                  </BRow>
                  <template v-if="ukrPoshtaData.bank_account?.length === 29">
                    <BFormCheckbox v-model="ukrPoshtaData.return_money_on_bank">Повертати перекази на рахунок
                    </BFormCheckbox>
                    <BFormCheckbox v-model="ukrPoshtaData.auto_change_order_status_to_delivered">Змінювати статус
                      замовлення
                      на
                      'завершенні', якщо
                      Укрпошта переказала вам післяплату по замовленню
                    </BFormCheckbox>
                  </template>
                  <BRow>
                    <BInputGroup size="sm" prepend="Телефон">
                      <BFormInput placeholder="380938507918"
                                  :state="ukrPoshtaData.phone.length === 12"
                                  v-model="ukrPoshtaData.phone"/>
                    </BInputGroup>
                  </BRow>
                  <BRow>
                    <BInputGroup size="sm" prepend="PRODUCTION BEARER eCom">
                      <BFormInput placeholder="PRODUCTION BEARER eCom" :state="ukrPoshtaData.bearer_ecom !== ''"
                                  v-model="ukrPoshtaData.bearer_ecom"/>
                    </BInputGroup>
                  </BRow>
                  <BRow>
                    <BInputGroup size="sm" prepend="PRODUCTION BEARER StatusTracking">
                      <BFormInput placeholder="PRODUCTION BEARER StatusTracking"
                                  :state="ukrPoshtaData.tracking_key !== ''"
                                  v-model="ukrPoshtaData.tracking_key"/>
                    </BInputGroup>
                  </BRow>
                  <BRow>
                    <BInputGroup size="sm" prepend="PROD_COUNTERPARTY TOKEN">
                      <BFormInput placeholder="PROD_COUNTERPARTY TOKEN"
                                  :state="ukrPoshtaData.counterparty_token !== ''"
                                  v-model="ukrPoshtaData.counterparty_token"/>
                    </BInputGroup>
                  </BRow>
                  <BRow>
                    <BInputGroup size="sm" prepend="COUNTERPARTY UUID">
                      <BFormInput placeholder="COUNTERPARTY UUID"
                                  :state="ukrPoshtaData.counterparty_uuid !== ''"
                                  v-model="ukrPoshtaData.counterparty_uuid"/>
                    </BInputGroup>
                  </BRow>

                  <BRow>
                    <BInputGroup size="sm" prepend="Опис вантажу за умовченням">
                      <template #append>
                        <BDropdown size="sm"
                                   :text="ukrPoshtaData.description?.name === '' ? 'Обрати опис вантажу' : ukrPoshtaData.description?.name"
                                   boundary="viewport"
                                   container="body"
                                   variant="outline-secondary">
                          <BFormInput @click.stop
                                      type="text"
                                      v-model="inputSearchValue"
                                      placeholder='Дрібна побутова техніка'
                                      @keyup.enter.prevent="search('cargoDesc', inputSearchValue)"
                                      @input="search('cargoDesc', inputSearchValue)"
                          />
                          <BDropdownItem
                              v-for="(cargoDesc, index) in searchResults"
                              :key="index"
                              @click="selectUpCagroDesc(cargoDesc)">
                            {{ cargoDesc?.name }}
                          </BDropdownItem>
                        </BDropdown>
                      </template>
                    </BInputGroup>


                  </BRow>

                  <BFormCheckbox v-model="showUpAddiditionalSettings">Показати додаткові налаштування</BFormCheckbox>
                  <template v-if="showUpAddiditionalSettings">
                    <p>Оберіть значенния для відправлень які будуть використовуватись за умовченням</p>
                    <BFormCheckbox v-model="ukrPoshtaData.is_standard">Укрпошта Стандарт за умовченням для підстановки у
                      замовлення
                    </BFormCheckbox>
                    <BFormCheckbox v-model="ukrPoshtaData.fragile">Громіздке</BFormCheckbox>
                    <BFormCheckbox v-model="ukrPoshtaData.bulky">Крихке</BFormCheckbox>
                    <BFormCheckbox v-model="ukrPoshtaData.fitting_allowed">Примірка дозволена</BFormCheckbox>
                    <BFormCheckbox v-model="ukrPoshtaData.delivery_payer_id">Платник одержувач</BFormCheckbox>
                    <BFormCheckbox v-model="ukrPoshtaData.backward_delivery_payer">Платник зворотньої доставки одержувач
                    </BFormCheckbox>
                    <BFormCheckbox v-model="ukrPoshtaData.check_on_delivery">Перевірка відправлення одержувачем
                    </BFormCheckbox>
                    <!--        <BFormCheckbox v-model="ukrPoshtaData.packed_by_sender">Упаковано відправником</BFormCheckbox>
                            <BFormCheckbox v-model="ukrPoshtaData.delivery_tehnology">Технологія доставки</BFormCheckbox>-->
                    <BFormSelect v-model="uponFailReceiveTypes[ukrPoshtaData.onFailReceiveType].value"
                                 :options="uponFailReceiveTypes" :select-size="4"/>
                    <BFormCheckbox v-model="ukrPoshtaData.auto_change_order_status_to_returned">Змінювати статус
                      замовлення
                      на
                      'повернені', якщо
                      ви одержали повернення
                    </BFormCheckbox>
                  </template>

                  <div>
                    <BButton size="sm" variant="primary" @click="saveApiKeyUkrPoshta">Зберегти</BButton>
                    <BButton size="sm" variant="outline-danger" @click="resetUkrPoshta">Відминити
                      редагування
                    </BButton>
                  </div>

                </BCard>
              </template>
            </BTab>
          </BTabs>
        </BTab>

        <!-- Вкладка для SMS Club -->
        <BTab title="Розсилки (СМС)" key="smsClub">
          <BTabs v-model="smsTabs" class="child-tabs">
            <BTab title="Налаштування" key="smsClub" active>
              <template v-if="smsStep === 'smsClub'">
                <BCard class="m-2">
                  <BFormText>
                    Для массової розсилки СМС з номерами ТТН, або для відправки повідомлень з реквізитами на оплату,
                    треба
                    створити
                    обліковий запис smsclub.mobi та вказати токен<br>
                    У самому низу "Токен" <a class="link-info" href="https://my.smsclub.mobi/uk/profile"
                                             target="_blank">Перейти
                    у кабінет SMS Club</a>
                  </BFormText>

                  <BInputGroup size="sm" id="smsClubApiKey" prepend="Токен">
                    <BFormInput :state="smsSettings.api_key !== undefined && smsSettings.api_key.length > 0"
                                placeholder="Gol8B18hkqDVUrw"
                                v-model="smsSettings.api_key"
                    />
                  </BInputGroup>

                  <BRow v-if="Object.keys(loadedAlphaNames).length > 0" class="mt-2">
                    <BInputGroup size="sm" prepend="Ім'я відправника СМС за умовченням">
                      <template #append>
                        <BDropdown size="sm"
                                   :text="defautAlphaName?.name === '' ? 'Виберіть альфа ім\'я' : defautAlphaName?.name"
                                   boundary="viewport"
                                   container="body"
                                   variant="outline-secondary">
                          <BDropdownItem
                              v-for="(alphaName, index) in loadedAlphaNames"
                              :key="index"
                              @click="selectAlphaName(alphaName)">
                            {{ alphaName?.name }}
                          </BDropdownItem>
                        </BDropdown>
                      </template>
                    </BInputGroup>
                    <BFormText>
                      Оберіть альфа ім'я відправника СМС за умовченням.
                      Обране тут альфа ім'я буде використовуватись для автоматичної
                      підстановки у новостворене замовлення
                    </BFormText>
                  </BRow>

                  <div v-if="saveNewAlphaNameButtonActive">
                    <BButton
                        @click="saveNewAlphaName"
                        variant="success">
                      Зберегти обране альфа ім'я
                    </BButton>
                  </div>

                </BCard>
                <BCard class="m-2">
                  <BFormText>Бувають ситуації коли ви приносите відправку у відділення, але її 'гублять' у відділенні і
                    вона
                    не відправляється клієнту, що б не втратити з поля зору цю відправку, тобто не помічати посилку як
                    'Віправлена',
                    поставте прапорець нижче
                  </BFormText>
                  <BFormCheckbox size="sm" v-model="smsSettings.send_sms_if_ttn_tracked">Не відправляти СМС З ТТН якщо
                    посилка
                    ще
                    не у відділенні
                  </BFormCheckbox>

                  <BFormCheckbox size="sm" v-model="smsSettings.auto_send_check_with_ttn">Автоматично створювати чек і
                    відправляти в
                    при розсилці СМС з ТТН
                  </BFormCheckbox>


                </BCard>

                <BCard class="m-2">
                  <BFormText>Якщо ви забули розіслати СМС з ТТН або небажаєте робити це самостійно, поставте чекбокс
                    нижче
                  </BFormText>
                  <BFormCheckbox size="sm" v-model="smsSettings.auto_send_ttn_in_sms">
                    Автоматично відправляти СМС з ТТН о 20:00
                  </BFormCheckbox>
                </BCard>

                <!--      <template v-else>
                        <BCard class="m-2">
                          <label for="rekvisits">Шаблон СМС для відправки реквізитів та сумми для предоплати за товар</label>
                          <BFormInput class="row" size="sm" placeholder="Назва шаблону" v-model="messagePaymentTemplateName"/>
                          <BFormTextarea id="rekvisits" size="sm" v-model="messagePaymentTemplate"></BFormTextarea>
                        </BCard>
                        <BCard class="m-2">
                          <label for="np" class="row">Шаблон СМС для Нової Пошти</label>
                          <BFormTextarea id="np" size="sm" v-model="npSmsTemplate"></BFormTextarea>
                        </BCard>
                        <BCard class="m-2">
                          <label for="up" class="row">Шаблон СМС для Укрпошти</label>
                          <BFormTextarea id="up" size="sm" v-model="upSmsTemplate"></BFormTextarea>
                        </BCard>
                        <BCard class="m-2">
                          <label for="rz" class="row">Шаблон СМС для Розетка делівері</label>
                          <BFormTextarea id="rz" size="sm" v-model="rzSmsTemplate"></BFormTextarea>
                        </BCard>
                      </template>-->
              </template>

              <BButton @click="saveApiKeySmsClub" variant="success">
                <BSpinner v-show="spinerSaveWarehouseActive" small/>
                Зберегти
              </BButton>

            </BTab>
            <BTab title="Шаблони СМС" key="smsTemplates">
              <template>
                <BFormText> Використовуйте оператори:<br>
                  <b>@ttn</b> - підставить номер ТТН перевізника у шаблон СМС;<br>
                  <b>@Sum</b> - Підставить суму замовлення;<br>
                  <b>@orderNumber</b> - підставить номер замовлення у шаблон.<br>
                  <b>@products</b> - підставить товарний склад з цінами у шаблон.<br>
                  <b>@name</b> - підставить ім'я покупця у шаблон.<br>
                  <b>@phone</b> - підставить телефон покупця у шаблон.<br>
                  <b>@del</b> - підставить місто, відділення у шаблон.<br>
                </BFormText>
                <BCard v-for="smsTemplates in smsSettings.sms_templates">
                  <BInputGroup>
                    <template #prepend>
                      <BFormInput size="sm" placeholder="Назва шаблону" prepend v-model="smsTemplates.name"/>
                    </template>

                    <template #append>
                      <BDropdown append size="sm" variant="outline-secondary"
                                 boundary="viewport"
                                 container="body"
                                 :text="smsTemplWithKeys[smsTemplates.value_type]" class="m-2 ">
                        <BDropdownItem
                            v-for="type in SMS_TEMPLATE_TYPES"
                            :key="type.value"
                            @click="updateSmsTemplateValue(smsTemplates, type.value)">
                          {{ type?.text }}
                        </BDropdownItem>
                      </BDropdown>
                    </template>
                  </BInputGroup>
                  <BFormTextarea v-model="smsTemplates.sms_text_value"></BFormTextarea>
                </BCard>
                <BButtonGroup>
                  <BButton variant="outline-primary" @click="addSmsTemplate">Додати шаблон повідомлення</BButton>
                  <BButton @click="saveApiKeySmsClub" variant="success">
                    <BSpinner v-show="spinerSaveWarehouseActive" small/>
                    Зберегти
                  </BButton>
                </BButtonGroup>
              </template>
            </BTab>
          </BTabs>
        </BTab>

        <!-- Вкладка для Маркетплейсів -->
        <BTab title="Маркетплейси/сайти" key="marketplaces">
          <BTabs v-model="marketplacesTabs" class="child-tabs">
            <BTab size="sm" title="Prom API" key="promApiKey" active>
              <template>
                <BRow v-if="senderSettingsFromDb?.prom_source_import_type?.length > 0">
                  <h3>Список кабінетів prom</h3>
                  <BTableSimple hover small caption-top responsive>
                    <colgroup>
                      <col/>
                      <col/>
                      <col/>
                    </colgroup>
                    <BThead head-variant="dark">
                      <BTr>
                        <BTh>Дії</BTh>
                        <BTh>Назва</BTh>
                        <BTh>Токен</BTh>
                        <BTh>Статус</BTh>
                      </BTr>
                    </BThead>
                    <BTbody>
                      <BTr v-for="promCabinetForEditing in senderSettingsFromDb.prom_source_import_type"
                           :key="promCabinetForEditing.id"
                      >
                        <BTd>
                          <BButton size="sm" type="outline-primary"
                                   @click="selectPromCabinet(promCabinetForEditing)">
                            Ред
                          </BButton>
                        </BTd>
                        <BTd>{{ promCabinetForEditing.name }}</BTd>
                        <BTd>{{ promCabinetForEditing.api_key }}</BTd>
                        <BTd>
                          <span v-if="promCabinetForEditing.is_active">Активний</span>
                        </BTd>


                      </BTr>
                    </BTbody>
                  </BTableSimple>
                </BRow>
                <span class="mt-2" v-else>Додайте token кабінету prom.ua.
                <a class="link-primary"
                   href="https://my.prom.ua/cms/settings/public_api_tokens"
                   target="_blank"
                >
                  Управління токенами Prom</a><br>
                <b>Дозволи ключа мають бути для роботи з замовленнями та товарами</b></span>

                <div>
                  <b v-if="promData.api_key === ''">Додавання нового кабінету</b>
                  <b v-else>Редагування кабінету</b><br>
                  <BFormText>
                    Товари які у наявності завантажаться у продовж 10-20 хв.
                    Замовлення будуть завантажуватись кожні 5 хв
                    у
                    статус
                    "Нові"
                  </BFormText>
                </div>
                <!--              <BRow :variant="responseStatus" v-if="requestMessageStatus">{{
                                  responseMessage
                                }}
                              </BRow>-->
                <BRow>
                  <BInputGroup prepend="Назва магазину скорочено">
                    <BFormInput placeholder="em-store" :state="promData.name !== '' " v-model="promData.name"/>
                  </BInputGroup>
                </BRow>
                <BRow>
                  <BInputGroup prepend="API ключ">
                    <BFormInput placeholder="a7356008d8873a649aff0a3489636ed244b790am"
                                :state="promData.api_key?.length === 40" v-model="promData.api_key"/>
                  </BInputGroup>
                </BRow>

                <template v-if="promData.api_key?.length === 40">

                  <BFormCheckbox size="sm" v-model="promData.is_active">Кабінет активний</BFormCheckbox>


                  <BFormCheckbox size="sm" v-model="promData.change_order_statuses">Автоматично змінювати статуси
                    замовлень на
                    Prom.ua відповідно до статусів у CRM
                  </BFormCheckbox>
                  <BFormCheckbox size="sm" v-model="promData.send_ttn_to_marketplace">Автоматично вивантажувати номери
                    ТТН
                    на
                    prom.ua при розсилці СМС з ТТН клієнтам
                  </BFormCheckbox>
                  <BFormCheckbox size="sm" v-model="promData.update_stocks">Автоматично вивантажувати залишки на Prom.ua
                  </BFormCheckbox>


                  <BRow v-if="Object.keys(npCabinetsForEditing)?.length > 1">
                    <BInputGroup size="sm" prepend="Кабінети Нової Пошти">
                      <template #append>
                        <div>
                          <BDropdown size="sm"
                                     :text="npCabinetsForEditing[promData.np_cabinet_defoult?.toString()]?.contactPersonaName ?? 'Оберіть відправника за умовч'"
                                     :state="promData.np_cabinet_defoult !== null"
                                     variant="outline-secondary">
                            <BDropdownItem
                                v-for="npCabinetForEditing in npCabinetsForEditing"
                                :key="npCabinetForEditing.contact_person_id"
                                @click="selectPromNpCabinetId(npCabinetForEditing)">
                              {{ npCabinetForEditing.contactPersonaName }} ,
                              {{ npCabinetForEditing.CounterpartyFullName }},
                              <b> {{ npCabinetForEditing.defaultSenderGlobaly === true ? ' | За умовч' : '' }}</b>
                            </BDropdownItem>
                          </BDropdown>
                        </div>
                      </template>
                    </BInputGroup>
                  </BRow>

                  <!--        </BCard>-->
                  <!--        <BInputGroup prepend="Кабінет Укрпошти за умовченням">
                            <BFormSelect :state="promData.up_cabinet_defoult !== null" v-model="promData.up_cabinet_defoult"
                                         :options="cabinetsNp"/>
                          </BInputGroup>-->

                  <BRow>
                    <BInputGroup size="sm" prepend="Ім'я відправника СМС для цього кабінету">
                      <template #append>
                        <BDropdown size="sm"
                                   boundary="viewport"
                                   container="body"
                                   :text="loadedAlphaNames[promData.alpha_name_id?.toString()]?.name ?? 'Оберіть Альфа-ім\'я за умовч'"
                                   variant="outline-secondary">
                          <BDropdownItem
                              v-for="(alphaName, id) in loadedAlphaNames"
                              :key="id"
                              @click="selectPromAlphaName(alphaName)">
                            {{ alphaName?.name }}
                          </BDropdownItem>

                        </BDropdown>
                      </template>
                    </BInputGroup>
                  </BRow>
                  <BRow>

                    <selectDefaultKassa
                        :kassy="checkBoxCabinets"
                        :currentSourceKassaId="promData.check_type_id"
                        :defaultGlobalKassa="senderSettings.defaultKassa.default_rro_id"
                        @update:defaultKassaId="promData.check_type_id = $event"
                    />

                  </BRow>
                  <BButton size="sm" variant="primary" @click="saveApiKeyProm">Зберегти</BButton>
                  <BButton size="sm" variant="outline-danger" @click="resetPromData">Відминити
                    редагування
                  </BButton>
                </template>


              </template>
            </BTab>

            <BTab size="sm" title="Rozetka API" key="rozetaApiKey">
              <template>

                <!--              <BRow v-if="senderSettingsFromDb?.rozetka_cab_settings?.length > 0">
                                <BInputGroup size="sm" prepend="Кабінети seller.rozetka.com.ua">
                                  <template #append>
                                    <div>
                                      <BDropdown size="sm"
                                                 :text="RozetkaSettings.username === '' ? 'Оберіть кабінет для редагування' : RozetkaSettings.username"
                                                 variant="outline-secondary">
                                        <BDropdownItem
                                            v-for="cabinetForEditing in senderSettingsFromDb.rozetka_cab_settings"
                                            :key="cabinetForEditing.id"
                                            @click="selectRozetkaCabinet(cabinetForEditing)">
                                          {{ cabinetForEditing.username }}
                                        </BDropdownItem>
                                      </BDropdown>
                                    </div>
                                  </template>
                                </BInputGroup>
                              </BRow>-->


                <template v-if="senderSettingsFromDb?.rozetka_cab_settings?.length > 0">
                  <h3>Список кабінетів rozetka</h3>
                  <BTableSimple hover small caption-top responsive>
                    <colgroup>
                      <col/>
                      <col/>
                      <col/>
                    </colgroup>
                    <BThead head-variant="dark">
                      <BTr>
                        <BTh>Дії</BTh>
                        <BTh>Назва</BTh>
                        <!--                    <BTh>Токен</BTh>-->
                        <BTh>Статус</BTh>
                      </BTr>
                    </BThead>
                    <BTbody>
                      <BTr v-for="rozetkaCabinetForEditing in senderSettingsFromDb.rozetka_cab_settings"
                           :key="rozetkaCabinetForEditing.id"
                      >
                        <BTd>
                          <BButton size="sm" type="outline-primary"
                                   @click="selectRozetkaCabinet(rozetkaCabinetForEditing)">
                            Ред
                          </BButton>
                        </BTd>
                        <BTd>{{ rozetkaCabinetForEditing.username }}</BTd>
                        <!--                    <BTd>{{ rozetkaCabinetForEditing.api_key }}</BTd>-->
                        <BTd>
                          <span v-if="rozetkaCabinetForEditing.is_active">Активний</span>
                        </BTd>
                      </BTr>
                    </BTbody>
                  </BTableSimple>
                </template>

                <BFormText class="row">
                  Товари які у наявності завантажаться у продовж 10-20 хв та будуть сихронізовані з пром товарами по
                  коду
                  товара
                  та по артикулю.
                  Замовлення будуть завантажуватись кожні 5 хв у статус "Нові"
                </BFormText>
                <!--              <p class="text-danger" v-if="requestMessageStatus && !RozetkaSettings.password.length > 0">{{
                                  responseMessage
                                }}
                              </p>-->


                <!--              <BRow :variant="responseStatus" v-if="requestMessageStatus && !RozetkaSettings.password.length > 0">{{
                                  responseMessage
                                }}
                              </BRow>-->

                <BFormText class="row">
                  <b> Щоб додати новий кабінет вкажіть логін/пароль від Розетки маркетплейс</b>
                </BFormText>
                <BRow>
                  <BInputGroup prepend="Логін">
                    <BFormInput :state="RozetkaSettings.username !== ''" placeholder="CherniakShop"
                                v-model="RozetkaSettings.username"/>
                  </BInputGroup>
                </BRow>

                <BRow>
                  <BInputGroup prepend="Пароль">
                    <BFormInput placeholder="Пароль"
                                :state="RozetkaSettings.password !== ''"
                                type="password"
                                v-model="RozetkaSettings.password"/>
                  </BInputGroup>
                </BRow>

                <template v-if="RozetkaSettings.username !== '' && RozetkaSettings.password !== ''">

                  <BFormCheckbox size="sm" v-model="RozetkaSettings.is_active">Кабінет активний</BFormCheckbox>

                  <BFormCheckbox size="sm" v-model="RozetkaSettings.change_order_statuses">Автоматично змінювати статуси
                    замовлень
                    на
                    Rozetka відповідно до статусів у CRM
                  </BFormCheckbox>
                  <BFormCheckbox size="sm" v-model="RozetkaSettings.send_ttn_to_marketplace">Автоматично вивантажувати
                    номери
                    ТТН
                    на розетку при
                    розсилці СМС з ТТН клієнтам
                  </BFormCheckbox>
                  <BFormCheckbox size="sm" v-model="RozetkaSettings.update_stocks">Автоматично вивантажувати залишки на
                    Rozetka
                  </BFormCheckbox>

                  <!--                  rozetka aplha names-->
                  <BRow>
                    <BInputGroup size="sm" prepend="Ім'я відправника СМС для цього кабінету">
                      <template #append>
                        <BDropdown size="sm"
                                   boundary="viewport"
                                   container="body"
                                   :text="loadedAlphaNames[RozetkaSettings.alpha_name_id?.toString()]?.name ?? 'Оберіть Альфа-ім\'я за умовч'"
                                   variant="outline-secondary">
                          <BDropdownItem
                              v-for="(alphaName, id) in loadedAlphaNames"
                              :key="id"
                              @click="selectRozetkaAlphaName(alphaName)">
                            {{ alphaName?.name }}
                          </BDropdownItem>
                        </BDropdown>
                      </template>
                    </BInputGroup>
                  </BRow>
                  <BRow v-if="Object.keys(npCabinetsForEditing).length > 1 ">
                    <p>Оберіть кабінет Нової пошти за умовченням для цього кабінету Rozetka</p>
                    <BInputGroup size="sm" prepend="Кабінети Нової Пошти">
                      <template #append>
                        <div>
                          <BDropdown size="sm"
                                     boundary="viewport"
                                     container="body"
                                     :text="npCabinetsForEditing[RozetkaSettings.np_cabinet_default_id?.toString()]?.contactPersonaName ?? 'Оберіть відправника за умовч'"
                                     variant="outline-secondary">
                            <BDropdownItem
                                v-for="npCabinetForEditing in npCabinetsForEditing"
                                :key="npCabinetForEditing.contact_person_id"
                                @click="selectRozetkaNpCabinetId(npCabinetForEditing)">
                              {{ npCabinetForEditing.contactPersonaName }} ,
                              {{ npCabinetForEditing.CounterpartyFullName }},
                              <b> {{ npCabinetForEditing.defaultSenderGlobaly === true ? ' | За умовч' : '' }}</b>
                            </BDropdownItem>
                          </BDropdown>
                        </div>
                      </template>
                    </BInputGroup>
                  </BRow>


                  <selectDefaultKassa
                      :kassy="checkBoxCabinets"
                      :currentSourceKassaId="RozetkaSettings.check_type_id"
                      :defaultGlobalKassa="senderSettings.defaultKassa.default_rro_id"
                      @update:defaultKassaId="RozetkaSettings.check_type_id = $event"
                  />


                  <BButton size="sm" variant="primary" @click="saveRozetkaCabinet">Зберегти</BButton>
                  <BButton size="sm" variant="outline-danger" @click="resetRozetkaSettings">Відминити
                    редагування
                  </BButton>
                </template>
              </template>
            </BTab>

            <!-- kasta api -->
            <BTab size="sm" title="Kasta API" key="kasta">
              <template>
                <BRow v-if="senderSettingsFromDb?.kasta_cabinet_settings_model?.length > 0">
                  <!--                <BInputGroup size="sm" prepend="Кабінети Kasta">
                                    <template #append>
                                      <div>
                                        <BDropdown size="sm"
                                                   :text="selectedKastaKabinet.name === '' ? 'Оберіть кабінет для редагування' : selectedKastaKabinet.name"
                                                   variant="outline-secondary">
                                          <BDropdownItem
                                              v-for="kastaCabinetForEditing in senderSettingsFromDb.kasta_cabinet_settings_model"
                                              :key="kastaCabinetForEditing.id"
                                              @click="selectKastaCabinet(kastaCabinetForEditing)">
                                            {{ kastaCabinetForEditing.name }} ,
                                            {{ kastaCabinetForEditing.api_key }},
                                          </BDropdownItem>
                                        </BDropdown>
                                      </div>
                                    </template>
                                  </BInputGroup>-->
                  <h3>Список кабінетів Kasta</h3>
                  <BTableSimple hover small caption-top responsive>
                    <colgroup>
                      <col/>
                      <col/>
                      <col/>
                    </colgroup>
                    <BThead head-variant="dark">
                      <BTr>
                        <BTh>Дії</BTh>
                        <BTh>Назва</BTh>
                        <BTh>Статус</BTh>
                      </BTr>
                    </BThead>
                    <BTbody>
                      <BTr v-for="kastaCabinetForEditing in senderSettingsFromDb.kasta_cabinet_settings_model"
                           :key="kastaCabinetForEditing.id"
                      >
                        <BTd>
                          <BButton size="sm" type="outline-primary"
                                   @click="selectKastaCabinet(kastaCabinetForEditing)">
                            Ред
                          </BButton>
                        </BTd>
                        <BTd>{{ kastaCabinetForEditing.name }}</BTd>
                        <BTd><span v-if="kastaCabinetForEditing.is_active">Активний</span></BTd>
                      </BTr>
                    </BTbody>
                  </BTableSimple>


                </BRow>

                <!--
                api_key = value.api_key;+
                name = value.name;+
                promData.change_order_statuses = true;+
                send_ttn_to_marketplace = true;+
                alpha_name_id = value.alpha_name_id; +
                np_cabinet_defoult = value.np_cabinet_defoult ; +
                up_cabinet_defoult = value.up_cabinet_defoult;
                rozetka_sender_settings_id = value.rozetka_sender_settings_id;
                rozetka_cabinet_settings_id = value.rozetka_cabinet_settings_id;
                is_active = value.is_active; +
                update_stocks = value.update_stocks +
                -->

                <div>
                  <BFormText>
                    Товари які у наявності завантажаться у продовж 10-20 хв.
                    Замовлення будуть завантажуватись кожні 5 хв у статус "Нові"
                  </BFormText>
                </div>
                <!--              <p class="text-danger" v-if="requestMessageStatus && selectedKastaKabinet.name !== ''">{{
                                  responseMessage
                                }}
                              </p>-->

                <!--              <BRow :variant="responseStatus" v-if="requestMessageStatus && selectedKastaKabinet.name !== ''">{{
                                  responseMessage
                                }}
                              </BRow>-->


                <BRow>
                  <BInputGroup prepend="Назва магазину скорочено">
                    <BFormInput placeholder="em-store" :state="selectedKastaKabinet.name !== '' "
                                v-model="selectedKastaKabinet.name"/>
                  </BInputGroup>
                </BRow>
                <BRow>
                  <BInputGroup prepend="Токен">
                    <BFormInput placeholder="lhn1im6wldoertkbxc5m4yijyarm6vzgeq4ezce5"
                                :state="selectedKastaKabinet.api_key?.length === 40"
                                v-model="selectedKastaKabinet.api_key"/>
                  </BInputGroup>
                </BRow>

                <template v-if="selectedKastaKabinet.api_key?.length === 40">

                  <BFormCheckbox size="sm" v-model="selectedKastaKabinet.is_active">Кабінет активний</BFormCheckbox>

                  <BFormCheckbox size="sm" v-model="selectedKastaKabinet.change_order_statuses">Автоматично змінювати
                    статуси
                    замовлень на
                    Kasta відповідно до статусів у CRM
                  </BFormCheckbox>
                  <BFormCheckbox size="sm" v-model="selectedKastaKabinet.send_ttn_to_marketplace">Автоматично
                    вивантажувати
                    номери ТТН на
                    Kasta при розсилці СМС з ТТН клієнтам
                  </BFormCheckbox>
                  <BFormCheckbox size="sm" v-model="selectedKastaKabinet.update_stocks">Автоматично вивантажувати
                    залишки
                    на
                    kasta.ua
                  </BFormCheckbox>
                  <BRow v-if="Object.keys(npCabinetsForEditing)?.length > 1">
                    <BInputGroup size="sm" prepend="Кабінети Нової Пошти">
                      <template #append>
                        <div>
                          <BDropdown size="sm"
                                     boundary="viewport"
                                     container="body"
                                     :text="npCabinetsForEditing[selectedKastaKabinet.np_cabinet_default_id?.toString()]?.contactPersonaName ?? 'Оберіть відправника за умовч'"
                                     :state="selectedKastaKabinet.np_cabinet_default_id !== null"
                                     variant="outline-secondary">

                            <BDropdownItem
                                v-for="npCabinetForEditing in npCabinetsForEditing"
                                :key="npCabinetForEditing.contact_person_id"
                                @click="selectPromNpCabinetId(npCabinetForEditing, 'kasta')">
                              {{ npCabinetForEditing.contactPersonaName }} ,
                              {{ npCabinetForEditing.CounterpartyFullName }},
                              <b> {{ npCabinetForEditing.defaultSenderGlobaly === true ? ' | За умовч' : '' }}</b>
                            </BDropdownItem>
                          </BDropdown>
                        </div>
                      </template>
                    </BInputGroup>
                  </BRow>
                  <BRow>
                    <BInputGroup size="sm" prepend="Ім'я відправника СМС для цього кабінету">
                      <template #append>
                        <BDropdown size="sm"
                                   boundary="viewport"
                                   container="body"
                                   :text="loadedAlphaNames[selectedKastaKabinet.alpha_name_id?.toString()]?.name ?? 'Оберіть Альфа-ім\'я для Каста'"
                                   variant="outline-secondary">
                          <BDropdownItem
                              v-for="(alphaName, id) in loadedAlphaNames"
                              :key="id"
                              @click="selectPromAlphaName(alphaName, 'kasta')">
                            {{ alphaName?.name }}
                          </BDropdownItem>
                        </BDropdown>
                      </template>
                    </BInputGroup>
                  </BRow>

                  <selectDefaultKassa
                      :kassy="checkBoxCabinets"
                      :currentSourceKassaId="selectedKastaKabinet.check_type_id"
                      :defaultGlobalKassa="senderSettings.defaultKassa.default_rro_id"
                      @update:defaultKassaId="selectedKastaKabinet.check_type_id = $event"
                  />

                  <BButton size="sm" variant="primary" @click="saveApiKeyKasta">Зберегти</BButton>
                  <BButton size="sm" variant="outline-danger" @click="resetKastaCabinet">Відминити
                    редагування
                  </BButton>

                </template>
              </template>
            </BTab>


            <BTab size="sm" title="Horoshop API" key="horoshop">
              <template>

                <BRow v-if="senderSettingsFromDb?.horoshop_cabinet_settings_model?.length > 0">
                  <h3>Список кабінетів Horoshop</h3>

                  <BTableSimple hover small caption-top responsive>
                    <colgroup>
                      <col/>
                      <col/>
                      <col/>
                    </colgroup>
                    <BThead head-variant="dark">
                      <BTr>
                        <BTh>Дії</BTh>
                        <BTh>Назва</BTh>
                        <BTh>Статус</BTh>
                      </BTr>
                    </BThead>
                    <BTbody>
                      <BTr v-for="horoshopCabinetForEditing in senderSettingsFromDb.horoshop_cabinet_settings_model"
                           :key="horoshopCabinetForEditing.id"
                      >
                        <BTd>
                          <BButton size="sm" type="outline-primary"
                                   @click="selectHoroshopCabinet(horoshopCabinetForEditing)">
                            Ред
                          </BButton>
                        </BTd>
                        <BTd>{{ horoshopCabinetForEditing.site_url }}</BTd>
                        <BTd><span v-if="horoshopCabinetForEditing.is_active">Активний</span></BTd>
                      </BTr>
                    </BTbody>
                  </BTableSimple>
                </BRow>

                <!--        "id": 7,
                        "user_id": 66,
                        "username": "Remshopnet",
                        "password": "+2496701213",
                        "change_order_statuses": 0,
                        "send_ttn_to_marketplace": 0,
                        "alpha_name_id": 96,
                        "is_active": 1,
                        "np_cabinet_default_id": 82,
                        "up_cabinet_default_id": 8,
                        "update_stocks": 0,
                        "site_url": "remshop.net"-->

                <div>
                  <BFormText>
                    Товари які у наявності завантажаться у продовж 10-20 хв.
                    Замовлення будуть завантажуватись кожні 5 хв у статус "Нові"
                  </BFormText>
                </div>
                <!--              <p class="text-danger" v-if="requestMessageStatus && selectedHoroshopKabinet.username !== ''">{{
                                  responseMessage
                                }}
                              </p>-->

                <!--
                              <BRow :variant="responseStatus" v-if="requestMessageStatus && selectedHoroshopKabinet.username !== ''">{{
                                  responseMessage
                                }}
                              </BRow>-->

                <BRow>
                  <BInputGroup prepend="Логін">
                    <BFormInput placeholder="em-store" :state="selectedHoroshopKabinet.username !== '' "
                                v-model="selectedHoroshopKabinet.username"/>
                  </BInputGroup>
                </BRow>
                <BRow>
                  <BInputGroup prepend="Пароль">
                    <BFormInput placeholder="lhn1im6wldoe"
                                :state="selectedHoroshopKabinet.password?.length > 0"
                                v-model="selectedHoroshopKabinet.password"/>
                  </BInputGroup>
                </BRow>

                <BRow>
                  <BInputGroup prepend="Адреса сайту">
                    <BFormInput placeholder="myshop.com.ua"
                                :state="selectedHoroshopKabinet.site_url?.length > 0"
                                v-model="selectedHoroshopKabinet.site_url"/>
                  </BInputGroup>
                </BRow>

                <template v-if="selectedHoroshopKabinet.site_url?.length > 0">
                  <BFormCheckbox size="sm" v-model="selectedHoroshopKabinet.is_active">Кабінет активний</BFormCheckbox>
                  <BFormCheckbox size="sm" v-model="selectedHoroshopKabinet.change_order_statuses">Автоматично змінювати
                    статуси
                    замовлень на
                    Horoshop відповідно до статусів у CRM
                  </BFormCheckbox>
                  <BFormCheckbox size="sm" v-model="selectedHoroshopKabinet.send_ttn_to_marketplace">Автоматично
                    вивантажувати
                    номери ТТН на
                    Horoshop при розсилці СМС з ТТН клієнтам
                  </BFormCheckbox>
                  <BFormCheckbox size="sm" v-model="selectedHoroshopKabinet.update_stocks">Автоматично вивантажувати
                    залишки на
                    сайт хорошоп
                  </BFormCheckbox>
                  <BRow v-if="Object.keys(npCabinetsForEditing)?.length > 1">
                    <BInputGroup size="sm" prepend="Кабінети Нової Пошти">
                      <template #append>
                        <div>
                          <BDropdown size="sm"

                                     :text="npCabinetsForEditing[selectedHoroshopKabinet.np_cabinet_default_id?.toString()]?.contactPersonaName ?? 'Оберіть відправника за умовч'"
                                     :state="selectedHoroshopKabinet.np_cabinet_default_id !== null"
                                     variant="outline-secondary">

                            <BDropdownItem
                                v-for="npCabinetForEditing in npCabinetsForEditing"
                                :key="npCabinetForEditing.contact_person_id"
                                @click="selectPromNpCabinetId(npCabinetForEditing, 'horoshop')">
                              {{ npCabinetForEditing.contactPersonaName }} ,
                              {{ npCabinetForEditing.CounterpartyFullName }},
                              <b> {{ npCabinetForEditing.defaultSenderGlobaly === true ? ' | За умовч' : '' }}</b>
                            </BDropdownItem>
                          </BDropdown>
                        </div>
                      </template>
                    </BInputGroup>
                  </BRow>
                  <BRow>
                    <BInputGroup size="sm" prepend="Ім'я відправника СМС для цього кабінету">
                      <template #append>
                        <BDropdown size="sm"
                                   boundary="viewport"
                                   container="body"
                                   :text="loadedAlphaNames[selectedHoroshopKabinet.alpha_name_id?.toString()]?.name ?? 'Оберіть Альфа-ім\'я для Horoshop'"
                                   variant="outline-secondary">
                          <BDropdownItem
                              v-for="(alphaName, id) in loadedAlphaNames"
                              :key="id"
                              @click="selectPromAlphaName(alphaName, 'horoshop')">
                            {{ alphaName?.name }}
                          </BDropdownItem>
                        </BDropdown>
                      </template>
                    </BInputGroup>
                  </BRow>

                  <selectDefaultKassa
                      :kassy="checkBoxCabinets"
                      :currentSourceKassaId="selectedHoroshopKabinet.check_type_id"
                      :defaultGlobalKassa="senderSettings.defaultKassa.default_rro_id"
                      @update:defaultKassaId="selectedHoroshopKabinet.check_type_id = $event"
                  />

                  <BButton size="sm" variant="primary" @click="saveHoroshopCabinet">Зберегти</BButton>
                  <BButton size="sm" variant="outline-danger" @click="resetHoroshopCabinet">Відминити</BButton>


                </template>
              </template>
            </BTab>
            <BTab size="sm" title="Opencart" key="opencart">
              <template>
                <BRow v-if="senderSettingsFromDb?.opencart_settings?.length > 0">
                  <!--                <BInputGroup size="sm" prepend="Магазини на cms opencart">
                                    <template #append>
                                      <div>
                                        <BDropdown size="sm"
                                                   boundary="viewport"
                                                   container="body"
                                                   :text="selectedOpencartCabinet.name === '' ? 'Оберіть кабінет для редагування' : selectedOpencartCabinet.name"
                                                   variant="outline-secondary">
                                          <BDropdownItem
                                              v-for="opencartCabinetForEditing in senderSettingsFromDb.opencart_settings"
                                              :key="opencartCabinetForEditing.id"
                                              @click="selectOpencartStore(opencartCabinetForEditing)">
                                            {{ opencartCabinetForEditing.name }}
                                          </BDropdownItem>
                                        </BDropdown>
                                      </div>
                                    </template>
                                  </BInputGroup>-->

                  <BRow>
                    <h6>План дій</h6>

                    <ul class="ml-2xl">

                      <li>Натисніть кнопку "Завантажити"</li>
                      <li>Файл 'export_orders_json.php' який буде завантажено передаєте вашему програмісту opencart
                        або самостійно через ftp/ssh
                        копіюєте файл у корінь сайту opencart, корінь сайту - це там де лежить файл index.php
                      </li>
                      <li>Після того як файл буде у корні вашого сайту, натисніть "редагувати" сайт, та поставте
                        прапорець біля
                        "Активний"
                      </li>
                      <li>
                        Далі можете перейти у
                        <a href="#" @click="moveToProductsPage">товари</a> і імпортувати товари з опенкарт
                      </li>
                    </ul>

                  </BRow>
                  <h5>Список кабінетів Opencart</h5>
                  <BTableSimple hover small caption-top responsive>
                    <colgroup>
                      <col/>
                      <col/>
                      <col/>
                    </colgroup>
                    <BThead head-variant="dark">
                      <BTr>
                        <BTh>Дії</BTh>
                        <BTh>Назва</BTh>
                        <BTh>url</BTh>
                        <BTh>Статус</BTh>
                        <BTh>Завантажити</BTh>
                      </BTr>
                    </BThead>
                    <BTbody>
                      <BTr v-for="opencartCabinetForEditing in senderSettingsFromDb.opencart_settings"
                           :key="opencartCabinetForEditing.id"
                      >
                        <BTd>
                          <BButton size="sm" type="outline-primary"
                                   @click="selectOpencartStore(opencartCabinetForEditing)">
                            Ред
                          </BButton>
                        </BTd>
                        <BTd>{{ opencartCabinetForEditing.name }}</BTd>
                        <BTd>{{ opencartCabinetForEditing.url }}</BTd>
                        <BTd> {{ opencartCabinetForEditing.is_active ? 'Активний' : 'Не активний' }}</BTd>
                        <BTd>
                          <BButton size="sm" type="outline-danger"
                                   @click="loadMaket(opencartCabinetForEditing.id)">
                            завантажити
                          </BButton>
                        </BTd>
                      </BTr>
                    </BTbody>
                  </BTableSimple>
                </BRow>

                <!--
                api_key = value.api_key;+
                name = value.name;+
                promData.change_order_statuses = true;+
                send_ttn_to_marketplace = true;+
                alpha_name_id = value.alpha_name_id; +
                np_cabinet_defoult = value.np_cabinet_defoult ; +
                up_cabinet_defoult = value.up_cabinet_defoult;
                rozetka_sender_settings_id = value.rozetka_sender_settings_id;
                rozetka_cabinet_settings_id = value.rozetka_cabinet_settings_id;
                is_active = value.is_active; +
                update_stocks = value.update_stocks +
                -->

                <div>
                  <!--                <BFormText>
                                    Товари які у наявності завантажаться у продовж 10-20 хв. Замовлення будуть завантажуватись кожні 5 хв у
                                    статус
                                    "Нові"
                                  </BFormText>-->
                </div>
                <!--              <p class="text-danger"
                                 v-if="requestMessageStatus && selectedOpencartCabinet.name !== ''">
                                {{
                                  responseMessage
                                }}
                              </p>-->

                <!--              <BRow :variant="responseStatus" v-if="requestMessageStatus && selectedOpencartCabinet.name !== ''">{{
                                  responseMessage
                                }}
                              </BRow>-->


                <!--                  'id': null,
                      'name': '',+
                      'url': '', +
                       'site_url': '',-
                      'is_active': true, +
                      'change_order_status': false, +
                      'update_stock': false,+
                       'np_cabinet_default_id': null, +
                      'alpha_name_id': null, +
                      'up_cabinet_default_id': null, +

                      'source_id': 5, / oc
                      'uuid': '',
                      'price_type_id': null,
                      'stock_id': null
                      -->
                <BRow>
                  <h5>Додайте новий кабінет Opencart</h5>
                  <BInputGroup prepend="Назва магазину скорочено">
                    <BFormInput placeholder="em-store"
                                :state="selectedOpencartCabinet.name !== '' "
                                v-model="selectedOpencartCabinet.name"/>
                  </BInputGroup>
                </BRow>
                <BRow>
                  <BInputGroup prepend="Адреса сайту">
                    <BFormInput placeholder="samorez.com.ua"
                                :state="selectedOpencartCabinet.url !== ''"
                                v-model="selectedOpencartCabinet.url"/>
                  </BInputGroup>
                </BRow>

                <template v-if="selectedOpencartCabinet.url !== ''">
                  <!-- is_active, change_order_status, update_stock -->
                  <BFormCheckbox size="sm"
                                 v-model="selectedOpencartCabinet.is_active">
                    Кабінет активний
                  </BFormCheckbox>
                  <BFormCheckbox size="sm"
                                 v-model="selectedOpencartCabinet.change_order_status">
                    Автоматично змінювати статуси
                  </BFormCheckbox>
                  <BFormCheckbox size="sm"
                                 v-model="selectedOpencartCabinet.update_stock"
                  >Автоматично вивантажувати залишки на цей сайт
                  </BFormCheckbox>
                  <!-- np_cabinet_default_id  -->
                  <BRow v-if="Object.keys(npCabinetsForEditing)?.length > 1">
                    <BInputGroup size="sm" prepend="Кабінети Нової Пошти">
                      <template #append>
                        <div>
                          <BDropdown size="sm"
                                     boundary="viewport"
                                     container="body"
                                     :text="npCabinetsForEditing[selectedOpencartCabinet.np_cabinet_default_id?.toString()]?.contactPersonaName
                                   ?? 'Оберіть відправника за умовч'"
                                     :state="selectedOpencartCabinet.np_cabinet_default_id !== null"
                                     variant="outline-secondary">
                            <BDropdownItem
                                v-for="npCabinetForEditing in npCabinetsForEditing"
                                :key="npCabinetForEditing.contact_person_id"
                                @click="selectPromNpCabinetId(npCabinetForEditing, 'opencart')">
                              {{ npCabinetForEditing.contactPersonaName }} ,
                              {{ npCabinetForEditing.CounterpartyFullName }},
                              <b> {{ npCabinetForEditing.defaultSenderGlobaly === true ? ' | За умовч' : '' }}</b>
                            </BDropdownItem>
                          </BDropdown>
                        </div>
                      </template>
                    </BInputGroup>
                  </BRow>
                  <!-- alphna_name_id  -->
                  <BRow>
                    <BInputGroup size="sm" prepend="Ім'я відправника СМС для цього кабінету">
                      <template #append>
                        <BDropdown size="sm"
                                   boundary="viewport"
                                   container="body"
                                   :text="loadedAlphaNames[selectedOpencartCabinet.alpha_name_id?.toString()]?.name ?? 'Оберіть Альфа-ім\'я за умовч'"
                                   variant="outline-secondary">
                          <BDropdownItem
                              v-for="(alphaName, id) in loadedAlphaNames"
                              :key="id"
                              @click="selectPromAlphaName(alphaName, 'opencart')">
                            {{ alphaName?.name }}
                          </BDropdownItem>
                        </BDropdown>
                      </template>
                    </BInputGroup>
                  </BRow>

                  <BRow>
                    <div v-if="Object.keys(priceTypes).length > 0">
                      <BInputGroup size="sm" prepend="Тип ціни">
                        <BDropdown
                            boundary="viewport"
                            container="body"
                            :text="getPriceType(selectedOpencartCabinet)"
                        >
                          <BDropdownItem
                              v-for="priceType in priceTypes"
                              :key="priceType.id"
                              @click="selectPriceType(selectedOpencartCabinet, priceType)"
                          >
                            {{ priceType.name }}
                          </BDropdownItem>
                        </BDropdown>
                      </BInputGroup>
                    </div>
                  </BRow>
                  <BRow>
                    <div v-if="Object.keys(stockTypes).length > 0">
                      <BInputGroup size="sm" prepend="Склад за замовчуванням">
                        <BDropdown
                            boundary="viewport"
                            container="body"
                            :text="getStockType(selectedOpencartCabinet)"
                        >
                          <BDropdownItem
                              v-for="stockType in stockTypes"
                              :key="stockType.id"
                              @click="selectStockType(selectedOpencartCabinet, stockType)"
                          >
                            {{ stockType.name }}
                          </BDropdownItem>
                        </BDropdown>
                      </BInputGroup>
                    </div>
                  </BRow>

                  <selectDefaultKassa
                      :kassy="checkBoxCabinets"
                      :currentSourceKassaId="selectedOpencartCabinet.check_type_id"
                      :defaultGlobalKassa="senderSettings.defaultKassa.default_rro_id"
                      @update:defaultKassaId="selectedOpencartCabinet.check_type_id = $event"
                  />

                  <BButton size="sm" variant="primary" @click="saveOpencartCabinet">Зберегти</BButton>
                  <BButton size="sm" variant="outline-danger" @click="resetOpencartCabinet">Відминити</BButton>

                </template>
              </template>
            </BTab>
            <BTab size="sm" title="hubber" key="hubber">
              <template>
                <BRow v-if="senderSettingsFromDb?.hubber_cabinets?.length > 0">
                  <h5>Список кабінетів hubber</h5>
                  <BTableSimple hover small caption-top responsive>
                    <colgroup>
                      <col/>
                      <col/>
                      <col/>
                    </colgroup>
                    <BThead head-variant="dark">
                      <BTr>
                        <BTh>Дії</BTh>
                        <BTh>Назва</BTh>
                        <BTh>Логін</BTh>
                        <BTh>Активність</BTh>
                      </BTr>
                    </BThead>
                    <BTbody>
                      <BTr v-for="hubberCabinetForEditing in senderSettingsFromDb.hubber_cabinets"
                           :key="hubberCabinetForEditing.id"
                      >
                        <BTd>
                          <BButton size="sm" type="outline-primary"
                                   @click="selecthubberStore(hubberCabinetForEditing)">
                            Ред
                          </BButton>
                        </BTd>
                        <BTd>{{ hubberCabinetForEditing.name }}</BTd>
                        <BTd>{{ hubberCabinetForEditing.login }}</BTd>
                        <BTd> {{ hubberCabinetForEditing.is_active ? 'Активний' : 'Не активний' }}</BTd>

                      </BTr>
                    </BTbody>
                  </BTableSimple>
                </BRow>

                <BRow>
                  <h5>Налаштування кабінету hubber</h5>
                  <BInputGroup prepend="Назва магазину скорочено">
                    <BFormInput placeholder="em-store"
                                :state="selectedHubberCabinet.name !== '' "
                                v-model="selectedHubberCabinet.name"/>
                  </BInputGroup>
                </BRow>


                <BRow>

                  <BInputGroup prepend="Id вашої номпанії на хаббер">
                    <BFormInput placeholder="77777"
                                :state="selectedHubberCabinet.company_id !== '' "
                                v-model="selectedHubberCabinet.company_id"/>
                  </BInputGroup>
                </BRow>
                <BRow>

                  <BInputGroup prepend="Логін">
                    <BFormInput placeholder="em-store"
                                :state="selectedHubberCabinet.login !== '' "
                                v-model="selectedHubberCabinet.login"/>
                  </BInputGroup>
                </BRow>
                <BRow>
                  <BInputGroup prepend="Пароль">
                    <BFormInput
                        :state="selectedHubberCabinet.password !== ''"
                        v-model="selectedHubberCabinet.password"/>
                  </BInputGroup>
                </BRow>

                <template v-if="selectedHubberCabinet.password !== ''">
                  <!-- is_active, change_order_status, update_stock -->
                  <BFormCheckbox size="sm"
                                 v-model="selectedHubberCabinet.is_active">
                    Кабінет активний
                  </BFormCheckbox>
                  <BFormCheckbox size="sm"
                                 v-model="selectedHubberCabinet.change_order_statuses">
                    Автоматично змінювати статуси
                  </BFormCheckbox>
                  <BFormCheckbox size="sm"
                                 v-model="selectedHubberCabinet.update_stocks"
                  >Автоматично вивантажувати залишки на цей сайт
                  </BFormCheckbox>
                  <!-- np_cabinet_default_id  -->
                  <BRow v-if="Object.keys(npCabinetsForEditing)?.length > 1">
                    <BInputGroup size="sm" prepend="Кабінети Нової Пошти">
                      <template #append>
                        <div>
                          <BDropdown size="sm"
                                     boundary="viewport"
                                     container="body"
                                     :text="npCabinetsForEditing[selectedHubberCabinet.np_cabinet_default_id?.toString()]?.contactPersonaName
                                   ?? 'Оберіть відправника за умовч'"
                                     :state="selectedHubberCabinet.np_cabinet_default_id !== null"
                                     variant="outline-secondary">
                            <BDropdownItem
                                v-for="npCabinetForEditing in npCabinetsForEditing"
                                :key="npCabinetForEditing.contact_person_id"
                                @click="selectPromNpCabinetId(npCabinetForEditing, 'hubber')">
                              {{ npCabinetForEditing.contactPersonaName }} ,
                              {{ npCabinetForEditing.CounterpartyFullName }},
                              <b> {{ npCabinetForEditing.defaultSenderGlobaly === true ? ' | За умовч' : '' }}</b>
                            </BDropdownItem>
                          </BDropdown>
                        </div>
                      </template>
                    </BInputGroup>
                  </BRow>
                  <!-- alphna_name_id  -->
                  <BRow>
                    <BInputGroup size="sm" prepend="Ім'я відправника СМС для цього кабінету">
                      <template #append>
                        <BDropdown size="sm"
                                   boundary="viewport"
                                   container="body"
                                   :text="loadedAlphaNames[selectedHubberCabinet.alpha_name_id?.toString()]?.name ?? 'Оберіть Альфа-ім\'я за умовч'"
                                   variant="outline-secondary">
                          <BDropdownItem
                              v-for="(alphaName, id) in loadedAlphaNames"
                              :key="id"
                              @click="selectPromAlphaName(alphaName, 'hubber')">
                            {{ alphaName?.name }}
                          </BDropdownItem>
                        </BDropdown>
                      </template>
                    </BInputGroup>
                  </BRow>

                  <BRow>
                    <div v-if="Object.keys(priceTypes).length > 0">
                      <BInputGroup size="sm" prepend="Тип ціни">
                        <BDropdown
                            boundary="viewport"
                            container="body"
                            :text="getPriceType(selectedHubberCabinet)"
                        >
                          <BDropdownItem
                              v-for="priceType in priceTypes"
                              :key="priceType.id"
                              @click="selectPriceType(selectedHubberCabinet, priceType)"
                          >
                            {{ priceType.name }}
                          </BDropdownItem>
                        </BDropdown>
                      </BInputGroup>
                    </div>
                  </BRow>
                  <BRow>
                    <div v-if="Object.keys(stockTypes).length > 0">
                      <BInputGroup size="sm" prepend="Склад за замовчуванням">
                        <BDropdown
                            boundary="viewport"
                            container="body"
                            :text="getStockType(selectedHubberCabinet)"
                        >
                          <BDropdownItem
                              v-for="stockType in stockTypes"
                              :key="stockType.id"
                              @click="selectStockType(selectedHubberCabinet, stockType)"
                          >
                            {{ stockType.name }}
                          </BDropdownItem>
                        </BDropdown>
                      </BInputGroup>
                    </div>
                  </BRow>

                  <selectDefaultKassa
                      :kassy="checkBoxCabinets"
                      :currentSourceKassaId="selectedHubberCabinet.check_type_id"
                      :defaultGlobalKassa="senderSettings.defaultKassa.default_rro_id"
                      @update:defaultKassaId="selectedHubberCabinet.check_type_id = $event"
                  />

                  <BButton size="sm" variant="primary" @click="savehubberCabinet">Зберегти</BButton>
                  <BButton size="sm" variant="outline-danger" @click="resethubberCabinet">Відминити</BButton>

                </template>
              </template>
            </BTab>


            <BTab size="sm" title="Додати джерело" key="addSource">
              <div>
                <!--          add all source types-->
                <div>

                  <BRow>


                    <BCard>
                      <tr v-for="source in allSources" :key="source.sourceType + source.id">
                        <td style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                          {{ source.sourceType }} {{ source.name || source.username }}
                        </td>
                      </tr>
                    </BCard>

                    <div class="mt-2">
                      <BInputGroup>
                        <template #prepend>
                          <BInputGroupText>Додайте джерело</BInputGroupText>
                        </template>
                        <template #append>
                          <BDropdown
                              boundary="viewport"
                              container="body"
                              :text="orderSourceForSave.source_type.name ?? 'Оберіть тип джерела'">

                            <BDropdownItem
                                v-for="source in filteredOrderSourceTypes"
                                :key="source.id"
                                @click="selectSourceType(source)"
                            >
                              {{ source.name }}
                            </BDropdownItem>
                          </BDropdown>
                        </template>
                      </BInputGroup>
                    </div>
                  </BRow>
                  <div v-if="orderSourceForSave.source_type.name !== null">
                    <BRow>
                      <BInputGroup prepend="Назва джерела">
                        <BFormInput v-model="orderSourceForSave.name" placeholder="samokatiki.ua"/>
                      </BInputGroup>
                    </BRow>
                    <BRow>
                      <BInputGroup prepend="URL">
                        <BFormInput v-model="orderSourceForSave.url" placeholder="необов'язково для соцмереж"/>
                      </BInputGroup>
                    </BRow>
                    <BRow>
                      <BInputGroup size="sm" prepend="Ім'я відправника СМС для цього кабінету"
                                   :state="orderSourceForSave.alphaName !== null"
                      >
                        <template #append>
                          <BDropdown size="sm"
                                     boundary="viewport"
                                     container="body"
                                     :text="loadedAlphaNames[orderSourceForSave.alphaName?.toString()]?.name ?? 'Оберіть Альфа-ім\'я'"
                                     variant="outline-secondary">
                            <BDropdownItem
                                v-for="(alphaName, id) in loadedAlphaNames"
                                :key="id"
                                @click="selectPromAlphaName(alphaName, 'customSource')">
                              {{ alphaName?.name }}
                            </BDropdownItem>
                          </BDropdown>
                        </template>
                      </BInputGroup>
                    </BRow>
                    <BButton @click="addSource" variant="primary">Зберегти</BButton>
                  </div>
                </div>
              </div>
            </BTab>

            <BTab size="sm" title="Типи цін та склади для джерел" key="add" lasyOnce>
              <priceTypes
                  @update:priceTypesResponse="priceTypesResponseHandler"
              />

              <table class="table table-striped">
                <thead>
                <tr>
                  <th>Джерело</th>
                  <th>Тип Цін</th>
                  <th>Склад</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="source in allSources" :key="source.sourceType + source.id">
                  <td style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{ source.sourceType }} {{ source.name }}
                  </td>
                  <td>
                    <div v-if="Object.keys(priceTypes).length > 0">
                      <BDropdown
                          boundary="viewport"
                          container="body"
                          :text="getPriceType(source)"
                      >
                        <BDropdownItem
                            v-for="priceType in priceTypes"
                            :key="priceType.id"
                            @click="selectPriceType(source, priceType)"
                        >
                          {{ priceType.name }}
                        </BDropdownItem>
                      </BDropdown>
                    </div>
                  </td>
                  <td>
                    <div v-if="Object.keys(stockTypes).length > 0">
                      <BDropdown
                          boundary="viewport"
                          container="body"
                          :text="getStockType(source)"
                      >
                        <BDropdownItem
                            v-for="stockType in stockTypes"
                            :key="stockType.id"
                            @click="selectStockType(source, stockType)"
                        >
                          {{ stockType.name }}
                        </BDropdownItem>
                      </BDropdown>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              <BButton v-if="isModified"
                       size="md"
                       variant="success"
                       @click="saveNewPriceStockTypes">Зберегти зміни
              </BButton>
              <BFormCheckbox
                  v-model="senderSettings.showPriceType"
              >Показувати вибір типа ціни у замовленні
              </BFormCheckbox>
            </BTab>
          </BTabs>
        </BTab>

        <!-- Вкладка для додавання користувачів -->
        <BTab title="Користувачі" key="addUsers">
          <!-- Ваш контент для додавання користувачів -->
          <BRow>
            <BCol lg="12">
              <div v-for="(user, index) in users" class="d-flex justify-content-around">
                <BInputGroup prepend="email">
                  <BFormInput :placeholder="user.placeholder" v-model="users[index].email"/>
                </BInputGroup>
                <BDropdown :text="user.source_name === null ? 'Обрати джерело' : user.source_name"
                           variant="outline-secondary"
                           boundary="viewport"
                           container="body"
                >
                  <BDropdownItem v-for="type in Object.values(customSources)" :key="type.id"
                                 @click="setOrderSourceToUser(type, index)">
                    {{ type.sourceType }} {{ type.name || type.username }}
                  </BDropdownItem>
                </BDropdown>
              </div>
              <BButton @click="addUser" class="center mt-2" variant="outline-primary">Додати користувача</BButton>
            </BCol>
          </BRow>


          <div>
            <BButton @click="saveUsers" class="center mt-2" variant="success">Зберегти</BButton>
          </div>

        </BTab>
        <BTab title="ПРРО Checkbox" key="addRro">
          <BRow v-if="checkBoxCabinets.length > 0">
            <h3>Список касс </h3>
            <BTableSimple hover small caption-top responsive>
              <colgroup>
                <col/>
                <col/>
                <col/>
              </colgroup>
              <BThead head-variant="dark">
                <BTr>
                  <BTh>Дії</BTh>
                  <BTh>Назва</BTh>
                  <BTh>Ключ ліцензії</BTh>
                  <BTh>Статус</BTh>
                </BTr>
              </BThead>
              <BTbody>
                <BTr v-for="kassa in checkBoxCabinets"
                     :key="kassa.id"
                >
                  <BTd>
                    <BButton size="sm" type="outline-primary"
                             @click="selectKassaForEdit(kassa)">
                      Ред
                    </BButton>
                  </BTd>
                  <BTd>{{ kassa.name }}</BTd>
                  <BTd>{{ kassa.kassa_license_key }}</BTd>
                  <BTd>
                    <span v-if="kassa.is_active">Активний</span>
                  </BTd>
                </BTr>


              </BTbody>
            </BTableSimple>
          </BRow>
          <BCard v-if="editKassa">
            <BRow>
              <BFormCheckbox
                  v-model="selectedKassa.is_active"
              > Касса активна
              </BFormCheckbox>
            </BRow>
            <BFormCheckbox :model-value="getDefaultKassaVal"
                           @update:model-value="handleChange">Касса за замовчуванням
            </BFormCheckbox>
            <BRow>
              <BInputGroup size="sm" prepend="Назва каси">
                <BFormInput size="sm" v-model="selectedKassa.name"></BFormInput>
              </BInputGroup>
            </BRow>
            <BRow>
              <BInputGroup size="sm" prepend="Ключ ліцензіі каси">
                <BFormInput size="sm" v-model="selectedKassa.kassa_license_key"></BFormInput>
                <a target="_blank" href="https://my.checkbox.ua/dashboard/cashregisters">Скопіюйте тут ключ ліцензії
                  каси</a>
              </BInputGroup>
            </BRow>
            <BRow>
              <BInputGroup size="sm" prepend="Пін код касира">
                <BFormInput size="sm" type="number" v-model="selectedKassa.kassir_pin"></BFormInput>
                <a target="_blank" href="https://my.checkbox.ua/dashboard/employees">Скопіюйте тут пін код касира</a>
              </BInputGroup>
            </BRow>
            <BRow>
              <BInputGroup size="sm" prepend="Назва товару в чеку">
                <BDropdown size="sm" :text="selectedKassa?.product_name_value?.name">
                  <BDropdownItem
                      v-for="ProductNameValue in productNameInCheck"
                      :key="ProductNameValue.code"
                      @click="selectCheckProductNameValues(ProductNameValue)">
                    {{ ProductNameValue.name }}
                  </BDropdownItem>
                </BDropdown>
              </BInputGroup>
            </BRow>
          </BCard>
          <BRow>
            <div>
              <div v-if="!editKassa">
                <BButton @click="addKassa" class="center mt-2" variant="primary">Додати кассу</BButton>
              </div>
              <div v-else class="d-flex">
                <div>
                  <BButton @click="saveKassa" class="center mt-2" variant="success">Зберегти</BButton>
                </div>
                <div>
                  <BButton @click="editKassa = !editKassa" class="center mt-2" variant="outline-secondary">Відмінити
                    редагування
                  </BButton>
                </div>
              </div>
            </div>
          </BRow>
        </BTab>
      </BTabs>
    </div>
    <BBadge :variant="responseStatus" v-if="requestMessageStatus">{{
        responseMessage
      }}
    </BBadge>

  </BModal>
</template>


<script>
import {computed, onMounted, ref, watch, watchEffect} from 'vue'
import {useStore} from 'vuex'
import priceTypes from '@/components/priceTypes'

import selectDefaultKassa from '@/components/selectDefaultKassa'

import {
  createCrmUsersRequest,
  createKastaCabinet,
  createHoroshopCabinet,
  createNpSender,
  createPromCabinet,
  createRozetkaCabinet,
  createUpCabinet,
  editContactPerson,
  editSenderSettings,
  getNpCabinetsList,
  getSearchResults,
  loadAlphaNames,
  PuTUpdateDefaultAlphaName,
  updateSenderWarehouse,
  getOrderSources,
  saveOrderSource,
  updatePricesStockTypesForSources,
  saveOpencartCab,
  loadMaketFromDb,
  createHubberCabinet,
  sendRequestEditKassa,


} from "@/axios/settignsRequests";
import {
  BButton,
  BCard,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormText,
  BFormTextarea,
  BInputGroup,
  BModal,
  BSpinner,
  BTooltip,
  BInputGroupText,
  BTable,
  BTabs,
  BTab,
  BBadge,
  BButtonGroup,
} from 'bootstrap-vue-next'


import SupportButtonDropdown from "@/components/SupportButtonDropdown";
import * as settingsRequest from "@/axios/settignsRequests";
import router from "@/Router/router";

export default {
  name: "SettingsModal",
  props: {
    showSettingsModal: {
      type: Boolean,
    }
  },
  emits: ['update:showSettingsModal'],
  setup(props, {emit}) {

    const store = useStore()

    const orderSourceTypes = ref([])


    const checkBoxCabinets = ref([]);


    /*
    'short_name','short_name_and_article','article'
    */

    /*    const productNameInCheck = {
          "short_name": "Коротке ім'я",
          "short_name_and_article": "Коротке ім'я та артикул",
          "article": "Артикул"
        }*/

    const productNameInCheck = [
      {
        "code": "short_name",
        "name": "Коротке ім'я"
      },
      {
        "code": "short_name_and_article",
        "name": "Коротке ім'я та артикул"
      },
      {
        "code": "article",
        "name": "Артикул"
      }
    ]

    const editKassa = ref(false);

    const selectedKassa = ref({
      active: true,
      name: '',
      kassa_license_key: '',
      is_active: true,
      id: null,
      kassir_pin: '',
      is_default: false,
      product_name_value: productNameInCheck[0]
    });

    console.log('selectedKassa1', selectedKassa.value);

    /*    function selectKassaForEdit(kassa) {
          console.log('checkBoxCabinets before', checkBoxCabinets.value)
          console.log('selectedKassa', kassa);
          selectedKassa.value = kassa;
          selectedKassa.value.is_active = kassa.is_active === 1 ;
          selectedKassa.value.product_name_value = productNameInCheck.find((item) => item.code === kassa.product_name_value)
          editKassa.value = true;
          console.log('filled kassa', selectedKassa.value)
          console.log('checkBoxCabinets after', checkBoxCabinets.value)
         // getDefaultKassaVal()
        }
    */
    function selectCheckProductNameValues(checkProductNameValue) {
      console.log('checkProductNameValue', checkProductNameValue);
      selectedKassa.value.product_name_value = checkProductNameValue;
    }


    function addKassa() {
      resetKassa();
      editKassa.value = true;

      console.log('editKassa', editKassa.value)


    }


    function selectKassaForEdit(kassa) {
      console.log('checkBoxCabinets before', checkBoxCabinets.value);
      console.log('selectedKassa', kassa);

      selectedKassa.value = {...kassa}; // робимо копію об'єкта kassa

      // Приводимо is_active до булевого значення
      if (typeof kassa.is_active === 'boolean') {
        selectedKassa.value.is_active = kassa.is_active;
      } else {
        selectedKassa.value.is_active = kassa.is_active === 1; // Якщо число, переводимо в булеве значення
      }

      // Оновлюємо значення product_name_value
      selectedKassa.value.product_name_value = productNameInCheck.find(
          (item) => item.code === kassa.product_name_value
      );

      editKassa.value = true;

      console.log('filled kassa', selectedKassa.value);
      console.log('checkBoxCabinets after', checkBoxCabinets.value);
    }


    function resetKassa() {
      selectedKassa.value = {
        active: true,
        name: '',
        kassa_license_key: '',
        is_active: true,
        id: null,
        kassir_pin: '',
        product_name_value: productNameInCheck[0]
      }
    }

    function saveKassa() {
      editKassa.value = false;


      selectedKassa.value.is_active = selectedKassa.value.is_active === true ? 1 : 0;
      selectedKassa.value.product_name_value = selectedKassa.value.product_name_value.code
      selectedKassa.value.rro_type = 'checkbox'
      console.log('selectedKassa for save', selectedKassa.value);

      sendRequestEditKassa(selectedKassa.value).then((res) => {
        console.log('res', res);
        checkBoxCabinets.value = res.data;
        store.dispatch('alert/triggerAlert', {
          show: 3000,
          variant: res.status,
          message: res.message,
        }, {root: true});
        // add alert

      })

      resetKassa()
      console.log('selectedKassa', selectedKassa.value);

    }


    const getDefaultKassaVal = computed(() => {
      console.log('selectedKassa.value.id', selectedKassa.value.id) // 8
      console.log('default_rro_id', senderSettings.value.defaultKassa.default_rro_id) // 8
      return selectedKassa.value.id === senderSettings.value.defaultKassa.default_rro_id //
    })

    const handleChange = (newValue) => {

      if (newValue === true) {
        senderSettings.value.defaultKassa.default_rro_id = selectedKassa.value.id;
        selectedKassa.value.is_default = true;
      }

      console.log('newValue', newValue);
    }


    const users = ref([
      {
        'email': '',
        'placeholder': 'some_user',
        'id': null,
        'source_name': 'Джерело за умовч',
        'source_type': 'Обрати',
        'source_id': null
      },
    ]);

    const getSenderSettings = async () => {

      let res = await store.dispatch('order/senderSettings')

      let receivedUsers = await settingsRequest.getUsers()

      fillUsers(receivedUsers)

      if (res.admin !== 'true') {
        await closeOrderModal()
      }

      orderSourceTypes.value = await getOrderSources();
      priceTypes.value = JSON.parse(localStorage.getItem('price_types'));
      stockTypes.value = JSON.parse(localStorage.getItem('stockNames'));

    }


    const customSources = ref([])

    customSources.value = store.getters['order/getCustomSources'];

    function fillUsers(receivedUsers) {
      users.value = receivedUsers.map(user => {
        return {
          email: user.email,
          placeholder: user.email,
          id: user.id,
          source_name: user.source_name,
          source_type: user.source_type,
          source_id: user.source_id
        }
      })

      console.log(users.value)

    }


    const showUpAddiditionalSettings = ref(false)

    const selectedKastaKabinet = ref({
      "id": null,
      "name": "",
      "change_order_statuses": false,
      "send_ttn_to_marketplace": false,
      "alpha_name_id": null,
      "api_key": "",
      "is_active": true,
      "np_cabinet_default_id": null,
      "up_cabinet_default_id": null,
      "update_stocks": false,
      'check_type_id': null,
    })

    const selectedHoroshopKabinet = ref({
      "username": "",
      "password": "",
      "change_order_statuses": false,
      "site_url": "",
      "send_ttn_to_marketplace": false,
      "check_type_id": null,
      "alpha_name_id": null,
      "is_active": true,
      "np_cabinet_default_id": null,
      "up_cabinet_default_id": null,
      "update_stocks": false
    })

    // @ttnNumber , @Sum, @orderNumber

    const npSmsTemplate = ref('Ваш номер ТТН @ttn. Дякуємо за замовлення')
    const upSmsTemplate = ref('Ваш номер ТТН @ttn, Дякуємо за замовлення')
    const rzSmsTemplate = ref('Ваш номер ТТН розетка @ttn, Дякуємо за замовлення')
    const messagePaymentTemplate = ref('Замовлення № @orderNumber 516852558551553 Сергій Стерненко, @Sum грн')
    const messagePaymentTemplateName = ref('Реквізити')

    //  const senderIsDeliveryPayer = ref(false);


    const npSenders = ref([]);


    const addUser = () => {
      users.value.push({
        'email': '',
        'placeholder': 'some_user',
        'id': null,
        'source_name': 'Джерело за умовч',
        'source_type': 'Обрати',
        'source_id': null

      });
    }

    const npSendersString = localStorage.getItem('npSenders');

    const priceTypes = ref({});
    const stockTypes = ref({});


    npSenders.value = JSON.parse(npSendersString);
    const defautAlphaName = ref({
      'id': null,
      'name': '',
    })
    const DeliveyPayerIsRecipient = ref(true);
    const backwardDeliveyPayerIsReceiver = ref(true);
    const uponFailReceiveTypes = { // 'RETURN','RETURN_AFTER_7_DAYS','PROCESS_AS_REFUSAL'
      'RETURN': {value: 1, text: 'Повернути через 14 днів'},
      'RETURN_AFTER_7_DAYS': {value: 2, text: 'Повернути через 7 днів'},
      'PROCESS_AS_REFUSAL': {value: 3, text: 'Знищити'},
    }

    const addiditionalTTnInfo = [
      {value: 'article', text: 'Артикуль'},
      {value: 'name', text: 'Скорочена назва товару'},
    ];

    const innVal = ref(null)

    const payerTypes = ref(store.getters['order/getDeliveryPayerTypes']);
    const edroupOrIpnValue = ref({
      data: null,
      text: 'ІПН',
      type: 'ipn',
      placeholder: 3223907500
    });

    const searchResults = ref()
    const inputSearchValue = ref('')
    const senderSettings = ref({});
    senderSettings.value.article_or_name = 'name';
    senderSettings.value.senderIsDeliveryPayer = false;
    senderSettings.value.senderPayerIfSumBigger = 0;
    senderSettings.value.create_return_id_if_canceled = true;
    senderSettings.value.min_product_price = 100;
    senderSettings.value.create_return_if_storage_n_days = true;
    senderSettings.value.default_weight = 2;


    function resetNpSettings() {
      senderSettings.value.article_or_name = 'name';
      senderSettings.value.senderIsDeliveryPayer = false;
      senderSettings.value.senderPayerIfSumBigger = 0;
      senderSettings.value.create_return_id_if_canceled = true;
      senderSettings.value.min_product_price = 100;
      senderSettings.value.create_return_if_storage_n_days = true;
      senderSettings.value.default_weight = 2;
      novaPoshaApikey.value = '';
    }


    function setNewValueForSelectedNpContactPerson(propertyName, value, varName = 'selectedNpContactPerson') {
      if (varName === 'selectedNpContactPerson') {
        selectedNpContactPerson.value[propertyName] = parseInt(value, 10);
      //  console.log('selectedNpContactPerson.value', selectedNpContactPerson.value);
      } else if (varName === 'senderSettings') {
        senderSettings.value[propertyName] = value;
      //  console.log('senderSettings.value', senderSettings.value);
      }
    }

    /*    const addiditionalTTnInfoValue = {
          'name' : 'Назва товару',
          'article' : 'Артикуль',
      };

        [
        {
          "id": 1,
          "default_cabinet": 1, +
          "sender_guid": "6d3",
          "sender_name": "Ф  Петрович", +
          "ukrposhta_index_id": 2315,
          "phone": 232323256,                               +
          "auto_change_order_status_to_delivered": 1, +
          "auto_change_order_status_to_returned": 1,  +
          "email": "",    +
          "is_standard": 1,
          "fragile": 0,
          "bulky": 0,
          "fitting_allowed": 1,
          "backward_delivery_payer": 4,
          "delivery_payer_id": 4,
          "description": 452,
          "resident": 1,
          "isFOP": 0,
          "edrpou": null,
          "bearer_ecom": "48",
          "tracking_key": "5d61",
          "counterparty_token": "733c10",
          "counterparty_uuid": "d873",
          "type": "PRIVATE_ENTREPRENEUR",
          "tin": 2333333,
          "bank_account": "34",
          "return_money_on_bank": 1,
          "paid_by_recipient": 4,
          "delivery_tehnology": 3,
          "sms": 0,
          "check_on_delivery": 1,
          "payment_method_id": 3,
          "packed_by_sender": 0,
          "onFailReceiveType": "RETURN_AFTER_7_DAYS"
        }
        ]*/

    /*   let returnMoneyOnBank = computed(() => {
         return ukrPoshtaData.value.bank_account.length === 29;
       });*/

    const selectEdrouIpn = (type) => {
      if (type === 'edrpou') {
        ukrPoshtaData.value.tin = null;
        edroupOrIpnValue.value.type = 'edrpou'
        edroupOrIpnValue.value.text = 'ЄДРПОУ'
        edroupOrIpnValue.value.placeholder = 40145700
      } else if (type === 'ipn') {
        ukrPoshtaData.value.edrpou = null;
        edroupOrIpnValue.value.type = 'ipn'
        edroupOrIpnValue.value.text = 'ІПН';
        edroupOrIpnValue.value.placeholder = 3223907500
      }
    }

    const addingNewWarehouse = ref(false);

    const senderAddressNp = ref({});
    senderAddressNp.value = fillSenderAddressNp();
    const selectedSenderWarehouse = ref(findDefaultWarehouseId())

    function fillSenderAddressNp() {
      return store.getters['order/senderAddressesNp'];
    }

    function findDefaultWarehouseId() {
      senderAddressNp.value = fillSenderAddressNp();
      if (senderAddressNp.value) {
        //  console.log('senderAddressNp.value', senderAddressNp.value);
        const defaultWarehouse = Object.values(senderAddressNp.value).find(warehouse => {
          //   console.log('warehouse', warehouse);
          return warehouse.is_default === true;
        });
        //  console.log('defaultWarehouse.id', defaultWarehouse.id);
        return defaultWarehouse ? defaultWarehouse.id : undefined;
      }
    }

    const selectedDefaultWarehouse = ref({
      sender_warehouse: {
        id: null,
        name: null,
        number: null,
      },
      city: {
        id: null,
        name: null,
      },
    })

    const warehouseWasChanged = ref(false);

    function selectSenderWarehouse(warehouse) {
      //  console.log('selectSenderWarehouse', warehouse);
      selectedSenderWarehouse.value = warehouse.id;
      warehouseWasChanged.value = true;

    }

    const spinerSaveWarehouseActive = ref(false)

    async function saveWarehouse(action) {
      spinerSaveWarehouseActive.value = true;
      let warehouseId;
      if (action === 'create') {
        warehouseId = selectedDefaultWarehouse.value.sender_warehouse.id
      } else if (action === 'updateDefaultWarehouse') {
        warehouseId = selectedSenderWarehouse.value
      }
      const sender_warehouse = {
        'sender_warehouse': warehouseId,
        'action': action
      }
      let newWarehouse = {}
      const res = await updateSenderWarehouse(sender_warehouse)
      store.commit('alert/setAlertState', {
        show: 3000,
        variant: res.status,
        message: res.message
      });
      if (action === 'create' && res.status === 'success') {
        newWarehouse[warehouseId] = {
          "is_default": false,
          id: warehouseId,
          name: selectedDefaultWarehouse.value.city.name + ' №' + selectedDefaultWarehouse.value.sender_warehouse.number
        }
        addNewWarehouse(newWarehouse)
      }
      spinerSaveWarehouseActive.value = false;
    }

    function addNewWarehouse(newWarehouse) {
      senderAddressNp.value = {
        ...senderAddressNp.value,
        ...newWarehouse
      };
    }

    const ukrPoshtaData = ref({
      'id': null,
      'default_cabinet': true,
      'sender_name': '',
      'index': '',
      'phone': '',
      'email': null,
      "is_standard": true,
      //'bankAccount': '', // TODO замінити на бекенді на bank_account
      'bank_account': '',
      'return_money_on_bank': false,
      'tin': edroupOrIpnValue.value.type === 'ipn' ? edroupOrIpnValue.value.data : null,
      'edrpou': edroupOrIpnValue.value.type === 'edrpou' ? edroupOrIpnValue.value.data : null,
      'bearer_ecom': '',
      'tracking_key': '',
      'counterparty_token': '',
      'counterparty_uuid': '',
      'resident': true,
      'type': 'PRIVATE_ENTREPRENEUR',
      // 'isFOP': false,
      'auto_change_order_status_to_delivered': true,
      'auto_change_order_status_to_returned': false,
      'fragile': false,
      'bulky': false,
      'paid_by_recipient': true,
      'fitting_allowed': true,
      'description': {
        'id': null,
        'name': '',
      },
      'backward_delivery_payer': DeliveyPayerIsRecipient.value,
      'delivery_payer_id': backwardDeliveyPayerIsReceiver.value,


      'check_on_delivery': true,
      'delivery_tehnology': 3, // 3 - 'Доставка відділенням',
      'payment_method_id': 2,
      'packed_by_sender': false,
      'onFailReceiveType': 'RETURN_AFTER_7_DAYS' // return after 7 days
    });

    function resetUkrPoshta() {

      ukrPoshtaData.value.sender_name = '',
          ukrPoshtaData.value.index = null,
          ukrPoshtaData.value.phone = '',
          ukrPoshtaData.value.email = null,
          ukrPoshtaData.value.bank_account = '',
          ukrPoshtaData.value.tin = edroupOrIpnValue.value.type === 'ipn' ? edroupOrIpnValue.value.data : null,
          ukrPoshtaData.value.edrpou = edroupOrIpnValue.value.type === 'edrpou' ? edroupOrIpnValue.value.data : null,
          ukrPoshtaData.value.bearer_ecom = '',
          ukrPoshtaData.value.tracking_key = '',
          ukrPoshtaData.value.counterparty_token = '',
          ukrPoshtaData.value.counterparty_uuid = ''
      innVal.value = null;
      showUpFields.value = false;
    }


    // ukrPoshtaData.value.return_money_on_bank = returnMoneyOnBank;

    function selectUpCabinetId(upCabinet) {
      showUpFields.value = true;
      console.log('upCabinet', upCabinet)
      innVal.value = upCabinet.tin !== null ? upCabinet.tin : upCabinet.edrpou;
      ukrPoshtaData.value.innVal = innVal.value;
      ukrPoshtaData.value.id = upCabinet.id;
      ukrPoshtaData.value.sender_name = upCabinet.sender_name;
      ukrPoshtaData.value.default_cabinet = upCabinet.default_cabinet === 1;
      ukrPoshtaData.value.index = upCabinet.ukr_posta_indexes.urkpost_index_value;
      ukrPoshtaData.value.phone = '380' + upCabinet.phone.toString();
      ukrPoshtaData.value.email = upCabinet.email;
      ukrPoshtaData.value.is_standard = upCabinet.is_standard === 1;
      ukrPoshtaData.value.bank_account = upCabinet.bank_account;
      ukrPoshtaData.value.return_money_on_bank = upCabinet.return_money_on_bank === 1;
      // ukrPoshtaData.value.tin = upCabinet.tin;
      // ukrPoshtaData.value.edrpou = upCabinet.edrpou;
      ukrPoshtaData.value.bearer_ecom = upCabinet.bearer_ecom;
      ukrPoshtaData.value.tracking_key = upCabinet.tracking_key;
      ukrPoshtaData.value.type = upCabinet.type;
      ukrPoshtaData.value.isFOP = upCabinet.isFOP === 1;
      ukrPoshtaData.value.resident = upCabinet.resident === 1;
      ukrPoshtaData.value.auto_change_order_status_to_delivered = upCabinet.auto_change_order_status_to_delivered === 1;
      ukrPoshtaData.value.auto_change_order_status_to_returned = upCabinet.auto_change_order_status_to_returned === 1;
      ukrPoshtaData.value.counterparty_token = upCabinet.counterparty_token;
      ukrPoshtaData.value.description = upCabinet.np_cargo_description; // todo
      ukrPoshtaData.value.counterparty_uuid = upCabinet.counterparty_uuid;
      ukrPoshtaData.value.fragile = upCabinet.fragile === 1;
      ukrPoshtaData.value.bulky = upCabinet.bulky === 1;
      ukrPoshtaData.value.fitting_allowed = upCabinet.fitting_allowed === 1;
      ukrPoshtaData.value.backward_delivery_payer = upCabinet.backward_delivery_payer === 4;
      ukrPoshtaData.value.delivery_payer_id = upCabinet.delivery_payer_id === 4;
      ukrPoshtaData.value.paid_by_recipient = upCabinet.paid_by_recipient === true ? 4 : 3;
      ukrPoshtaData.value.check_on_delivery = upCabinet.check_on_delivery === 1;
      ukrPoshtaData.value.delivery_tehnology = upCabinet.delivery_tehnology;
      ukrPoshtaData.value.payment_method_id = upCabinet.payment_method_id;
      ukrPoshtaData.value.packed_by_sender = upCabinet.packed_by_sender;
      ukrPoshtaData.value.onFailReceiveType = upCabinet.onFailReceiveType;
      showUpAddiditionalSettings.value = true;
    }

    function selectUpCagroDesc(descVal) {
      ukrPoshtaData.value.description = descVal;
    }

    const changeOrdrerStatuses = ref(true);
    const sendTtnsToProm = ref(true);
    const changeRozetkaOrdrerStatuses = ref(true);
    const uploadStocksToRozetka = ref(false);
    const uploadStocksToProm = ref(false);
    const sendTtnsToRozetka = ref(true);


    // const promKeyValue = ref('Створити кабінет');


    const promData = ref({
      id: null,
      api_key: '',
      name: '',
      change_order_statuses: true,
      send_ttn_to_marketplace: true,
      alpha_name_id: null,
      np_cabinet_defoult: null,
      up_cabinet_defoult: null,
      rozetka_sender_settings_id: null,
      rozetka_cabinet_settings_id: null,
      import_code_type_id: null,
      is_active: true,
      update_stocks: false,
      price_type_id: null,
      stock_id: null,
    })

    /*
      "opencart_settings": [
    {
      "id": 1,
      "name": "opencart1",
      "url": "https:\/\/dachnik.com.ua",
      "alpha_name_id": 95,
      "user_id": 66,
      "np_cabinet_default_id": 82,
      "up_cabinet_default_id": 8,
      "update_stock": 0,
      "source_id": 5,
      "is_active": 1,
      "change_order_status": 0,
      "site_url": "dachnik.com.ua",
      "uuid": "550e8400-e29b-41d4-a716-446655440000",
      "price_type_id": null,
      "stock_id": null
    }
  ],
    */

    const selectedOpencartCabinet = ref({
      'id': null,
      'name': '',
      'url': '',
      'alpha_name_id': null,
      'user_id': null,
      'np_cabinet_default_id': null,
      'up_cabinet_default_id': null,
      'update_stock': false,
      "check_type_id": null,
      'source_id': 5,
      'is_active': false,
      'change_order_status': false,
      'site_url': '',
      'uuid': '',
      'price_type_id': null,
      'stock_id': null
    })

    const promCabinetWasSelected = ref(false);

    function selectPromCabinet(selectedPromCabinet) {
      console.log('selectedPromCabinet', selectedPromCabinet);
      promData.value.id = selectedPromCabinet.id;
      promData.value.api_key = selectedPromCabinet.api_key;
      promData.value.name = selectedPromCabinet.name;
      promData.value.change_order_statuses = selectedPromCabinet.change_order_statuses === 1;
      promData.value.send_ttn_to_marketplace = selectedPromCabinet.send_ttn_to_marketplace === 1;
      promData.value.alpha_name_id = selectedPromCabinet.alpha_name_id;
      promData.value.np_cabinet_defoult = selectedPromCabinet.np_cabinet_defoult;
      promData.value.up_cabinet_defoult = selectedPromCabinet.up_cabinet_defoult;
      promData.value.rozetka_sender_settings_id = selectedPromCabinet.rozetka_sender_settings_id;
      promData.value.rozetka_cabinet_settings_id = selectedPromCabinet.rozetka_cabinet_settings_id;
      promData.value.is_active = selectedPromCabinet.is_active === 1;
      promData.value.update_stocks = selectedPromCabinet.update_stocks === 1;
      // todo додав ці поля для прома, треба і для інших джерел додати і зробити заповнення
      promData.value.stock_id = selectedPromCabinet.stock_id
      promData.value.price_type_id = selectedPromCabinet.price_type_id
      promData.value.check_type_id = selectedPromCabinet.check_type_id
      promCabinetWasSelected.value = true;
    }

    function resetPromData() {
      console.log('resetPromData');
      promData.value.id = null;
      promData.value.api_key = '';
      promData.value.name = '';
      promData.value.change_order_statuses = true;
      promData.value.send_ttn_to_marketplace = true;
      promData.value.alpha_name_id = null;
      promData.value.check_type_id = null;
      promData.value.np_cabinet_defoult = null;
      promData.value.up_cabinet_defoult = null;
      promData.value.rozetka_sender_settings_id = null;
      promData.value.rozetka_cabinet_settings_id = null;
      promData.value.is_active = true;
      promData.value.update_stocks = false;
      promData.value.stock_id = null;
      promData.value.price_type_id = null;
      promCabinetWasSelected.value = false;
    }

    function selectKastaCabinet(kastaCabinet) {
      console.log('kastaCabinet', kastaCabinet);
      selectedKastaKabinet.value.id = kastaCabinet.id;
      selectedKastaKabinet.value.api_key = kastaCabinet.api_key;
      selectedKastaKabinet.value.name = kastaCabinet.name;

      selectedKastaKabinet.value.change_order_statuses = kastaCabinet.change_order_statuses === 1;
      selectedKastaKabinet.value.send_ttn_to_marketplace = kastaCabinet.send_ttn_to_marketplace === 1;
      selectedKastaKabinet.value.alpha_name_id = kastaCabinet.alpha_name_id;
      selectedKastaKabinet.value.check_type_id = kastaCabinet.check_type_id;
      selectedKastaKabinet.value.np_cabinet_default_id = kastaCabinet.np_cabinet_default_id;
      selectedKastaKabinet.value.up_cabinet_default_id = kastaCabinet.up_cabinet_default_id;
      selectedKastaKabinet.value.is_active = kastaCabinet.is_active === 1;
      selectedKastaKabinet.value.update_stocks = kastaCabinet.update_stocks === 1;
    }

    function resetKastaCabinet() {
      selectedKastaKabinet.value.id = null;
      selectedKastaKabinet.value.api_key = '';
      selectedKastaKabinet.value.name = '';
      selectedKastaKabinet.value.check_type_id = null;
      selectedKastaKabinet.value.change_order_statuses = true;
      selectedKastaKabinet.value.send_ttn_to_marketplace = true;
      selectedKastaKabinet.value.alpha_name_id = null;
      selectedKastaKabinet.value.np_cabinet_default_id = null;
      selectedKastaKabinet.value.up_cabinet_default_id = null;
      selectedKastaKabinet.value.is_active = true;
      selectedKastaKabinet.value.update_stocks = false;
    }

    function selectHoroshopCabinet(HoroshopCabinet) {
      console.log('HoroshopCabinet', HoroshopCabinet);
      selectedHoroshopKabinet.value.id = HoroshopCabinet.id;
      selectedHoroshopKabinet.value.username = HoroshopCabinet.username;
      selectedHoroshopKabinet.value.password = HoroshopCabinet.password;
      selectedHoroshopKabinet.value.site_url = HoroshopCabinet.site_url;
      selectedHoroshopKabinet.value.check_type_id = HoroshopCabinet.check_type_id;
      selectedHoroshopKabinet.value.change_order_statuses = HoroshopCabinet.change_order_statuses === 1;
      selectedHoroshopKabinet.value.send_ttn_to_marketplace = HoroshopCabinet.send_ttn_to_marketplace === 1;
      selectedHoroshopKabinet.value.alpha_name_id = HoroshopCabinet.alpha_name_id;
      selectedHoroshopKabinet.value.np_cabinet_default_id = HoroshopCabinet.np_cabinet_default_id;
      selectedHoroshopKabinet.value.up_cabinet_default_id = HoroshopCabinet.up_cabinet_default_id;
      selectedHoroshopKabinet.value.is_active = HoroshopCabinet.is_active === 1;
      selectedHoroshopKabinet.value.update_stocks = HoroshopCabinet.update_stocks === 1;
    }

    function resetHoroshopCabinet() {
      selectedHoroshopKabinet.value.id = null;
      selectedHoroshopKabinet.value.username = '';
      selectedHoroshopKabinet.value.password = '';
      selectedHoroshopKabinet.value.site_url = '';
      selectedHoroshopKabinet.value.check_type_id = null;
      selectedHoroshopKabinet.value.change_order_statuses = true;
      selectedHoroshopKabinet.value.send_ttn_to_marketplace = true;
      selectedHoroshopKabinet.value.alpha_name_id = null;
      selectedHoroshopKabinet.value.np_cabinet_default_id = null;
      selectedHoroshopKabinet.value.up_cabinet_default_id = null;
      selectedHoroshopKabinet.value.is_active = true;
      selectedHoroshopKabinet.value.update_stocks = false;
    }

    function selectOpencartStore(selectedOpencartStore) {
      console.log('selectedOpencartStore', selectedOpencartStore);
      selectedOpencartCabinet.value.id = selectedOpencartStore.id;
      selectedOpencartCabinet.value.name = selectedOpencartStore.name;
      selectedOpencartCabinet.value.url = selectedOpencartStore.url;
      selectedOpencartCabinet.value.alpha_name_id = selectedOpencartStore.alpha_name_id;
      selectedOpencartCabinet.value.user_id = selectedOpencartStore.user_id;
      selectedOpencartCabinet.value.check_type_id = selectedOpencartStore.check_type_id;
      selectedOpencartCabinet.value.np_cabinet_default_id = selectedOpencartStore.np_cabinet_default_id;
      selectedOpencartCabinet.value.up_cabinet_default_id = selectedOpencartStore.up_cabinet_default_id;
      selectedOpencartCabinet.value.update_stock = selectedOpencartStore.update_stock === 1;
      selectedOpencartCabinet.value.source_id = selectedOpencartStore.source_id;
      selectedOpencartCabinet.value.is_active = selectedOpencartStore.is_active === 1;
      selectedOpencartCabinet.value.change_order_status = selectedOpencartStore.change_order_status === 1;
      selectedOpencartCabinet.value.site_url = selectedOpencartStore.site_url;
      selectedOpencartCabinet.value.uuid = selectedOpencartStore.uuid;
      selectedOpencartCabinet.value.price_type_id = selectedOpencartStore.price_type_id;
      selectedOpencartCabinet.value.stock_id = selectedOpencartStore.stock_id;
      console.log("selectedOpencartCabinet", selectedOpencartCabinet.value);
    }

    const selectedHubberCabinet = ref({
      id: null,
      name: '',
      token: '',
      alpha_name_id: null,
      user_id: null,
      price_type_id: null,
      stock_id: null,
      company_id: '',
      login: '',
      password: '',
      is_active: true,
      change_order_statuses: false,
      send_ttn_to_marketplace: false,
      np_cabinet_default_id: null,
      up_cabinet_default_id: null,
      update_stocks: false,
      source_id: 17
    });

    function selecthubberStore(selectedCabinet) {
      /*
          {
      "id": 4,
      "name": "pars",
      "token": null,
      "alpha_name_id": 106,
      "user_id": 116,
      "price_type_id": 1724314516854,
      "stock_id": null,
      "company_id": "51005",
      "login": "parstriko20@gmail.com",
      "password": "9379992",
      "is_active": 1,
      "change_order_statuses": 0,
      "send_ttn_to_marketplace": 0,
      "np_cabinet_default_id": 153,
      "up_cabinet_default_id": null,
      "update_stocks": 0,
      "source_id": 17
    }
      */

      selectedHubberCabinet.value.id = selectedCabinet.id;
      selectedHubberCabinet.value.name = selectedCabinet.name;
      selectedHubberCabinet.value.token = selectedCabinet.token;
      selectedHubberCabinet.value.alpha_name_id = selectedCabinet.alpha_name_id;
      selectedHubberCabinet.value.user_id = selectedCabinet.user_id;
      selectedHubberCabinet.value.price_type_id = selectedCabinet.price_type_id;
      selectedHubberCabinet.value.stock_id = selectedCabinet.stock_id;
      selectedHubberCabinet.value.company_id = selectedCabinet.company_id;
      selectedHubberCabinet.value.login = selectedCabinet.login;
      selectedHubberCabinet.value.password = selectedCabinet.password;
      selectedHubberCabinet.value.is_active = selectedCabinet.is_active === 1;
      selectedHubberCabinet.value.change_order_statuses = selectedCabinet.change_order_statuses === 1;
      selectedHubberCabinet.value.send_ttn_to_marketplace = selectedCabinet.send_ttn_to_marketplace === 1;
      selectedHubberCabinet.value.np_cabinet_default_id = selectedCabinet.np_cabinet_default_id;
      selectedHubberCabinet.value.up_cabinet_default_id = selectedCabinet.up_cabinet_default_id;
      selectedHubberCabinet.value.update_stocks = selectedCabinet.update_stocks === 1;


    }


    function resethubberCabinet() {
      selectedHubberCabinet.value.id = null;
      selectedHubberCabinet.value.name = '';
      selectedHubberCabinet.value.token = '';
      selectedHubberCabinet.value.alpha_name_id = null;
      selectedHubberCabinet.value.user_id = null;
      selectedHubberCabinet.value.price_type_id = null;
      selectedHubberCabinet.value.stock_id = null;
      selectedHubberCabinet.value.company_id = '';
      selectedHubberCabinet.value.login = '';
      selectedHubberCabinet.value.password = '';
      selectedHubberCabinet.value.is_active = true;
      selectedHubberCabinet.value.change_order_statuses = false;
      selectedHubberCabinet.value.send_ttn_to_marketplace = false;
      selectedHubberCabinet.value.np_cabinet_default_id = null;
      selectedHubberCabinet.value.up_cabinet_default_id = null;
      selectedHubberCabinet.value.update_stocks = false;
      selectedHubberCabinet.value.source_id = 17;
    }


    function resetOpencartCabinet() {
      selectedOpencartCabinet.value.id = null;
      selectedOpencartCabinet.value.name = '';
      selectedOpencartCabinet.value.url = '';
      selectedOpencartCabinet.value.alpha_name_id = null;
      selectedOpencartCabinet.value.user_id = null;
      selectedOpencartCabinet.value.np_cabinet_default_id = null;
      selectedOpencartCabinet.value.up_cabinet_default_id = null;
      selectedOpencartCabinet.value.update_stock = false;
      selectedOpencartCabinet.value.source_id = null;
      selectedOpencartCabinet.value.is_active = false;
      selectedOpencartCabinet.value.change_order_status = false;
      selectedOpencartCabinet.value.site_url = '';
      selectedOpencartCabinet.value.uuid = '';
      selectedOpencartCabinet.value.price_type_id = null;
      selectedOpencartCabinet.value.stock_id = null;
    }

    /*
        "id": 2,
        "user_id": 20,
        "username": "OrehovaMold2023",
        "password": "N0hjTjY2Y1BWbnQ3",
        "alpha_name_id": 19,
        "is_active": 1,
        "up_cabinet_default_id": null,
        "np_cabinet_default_id": 15,
        "roz_sender_settings_default_id": null,
        "update_stocks": 0
    */

    const RozetkaSettings = ref({
      'id': null,
      'username': '',
      'password': '',
      'send_ttn_to_marketplace': true,
      'change_order_statuses': true,
      'alpha_name_id': null,
      'is_active': true,
      'check_type_id': null,
      'np_cabinet_default_id': null,
      'up_cabinet_default_id': null,
      'roz_sender_settings_default_id': null,
      'update_stocks': false
    })

    function resetRozetkaSettings() {
      RozetkaSettings.value.id = null;
      RozetkaSettings.value.username = '';
      RozetkaSettings.value.password = '';
      RozetkaSettings.value.send_ttn_to_marketplace = true;
      RozetkaSettings.value.change_order_statuses = true;
      RozetkaSettings.value.alpha_name_id = null;
      RozetkaSettings.value.is_active = true;
      RozetkaSettings.value.np_cabinet_default_id = null;
      RozetkaSettings.value.up_cabinet_default_id = null;
      RozetkaSettings.value.roz_sender_settings_default_id = null;
      RozetkaSettings.value.update_stocks = false;
    }

    const oldRozetkaPassword = ref({});

    function selectRozetkaCabinet(value) {
      console.log('selectRozetkaCabinet', value);
      RozetkaSettings.value.id = value.id;
      RozetkaSettings.value.username = value.username;
      RozetkaSettings.value.password = value.password;
      RozetkaSettings.value.send_ttn_to_marketplace = value.send_ttn_to_marketplace === 1
      RozetkaSettings.value.change_order_statuses = value.change_order_statuses === 1;
      RozetkaSettings.value.alpha_name_id = value.alpha_name_id;
      RozetkaSettings.value.is_active = value.is_active === 1;
      RozetkaSettings.value.check_type_id = value.check_type_id;
      RozetkaSettings.value.np_cabinet_default_id = value.np_cabinet_default_id;
      RozetkaSettings.value.up_cabinet_default_id = value.up_cabinet_default_id;
      RozetkaSettings.value.roz_sender_settings_default_id = value.roz_sender_settings_default_id;
      RozetkaSettings.value.update_stocks = value.update_stocks === 1;
      oldRozetkaPassword.value[value.id] = value.password;
    }

    function selectRozetkaAlphaName(value) {
      console.log('selectPromAlphaName', value)
      RozetkaSettings.value.alpha_name_id = value.id;
    }

    function selectPromAlphaName(value, marketplaceType = 'prom') {
      console.log('selectAlphaName', value)
      switch (marketplaceType) {
        case 'prom':
          promData.value.alpha_name_id = value.id;
          break;
        case 'rozetka':
          RozetkaSettings.value.alpha_name_id = value.id;
          break;
        case 'kasta':
          selectedKastaKabinet.value.alpha_name_id = value.id;
          break;
        case 'opencart':
          selectedOpencartCabinet.value.alpha_name_id = value.id;
          break;
        case 'horoshop':
          selectedHoroshopKabinet.value.alpha_name_id = value.id;
          break;
        case 'hubber':
          selectedHubberCabinet.value.alpha_name_id = value.id;
          break;
        case 'customSource':
          orderSourceForSave.value.alphaName = value.id;
          break;
      }
      console.log('kastaCabinet', selectedKastaKabinet.value);
    }

    function selectPromNpCabinetId(selectedNpCabinet, marketplaceType = 'prom') {
      console.log('selectedNpCabinet', selectedNpCabinet)


      switch (marketplaceType) {
        case 'prom':
          promData.value.np_cabinet_defoult = selectedNpCabinet.contact_person_id;
          break;
        case 'rozetka':
          RozetkaSettings.value.np_cabinet_default_id = selectedNpCabinet.contact_person_id;
          break;
        case 'kasta':
          selectedKastaKabinet.value.np_cabinet_default_id = selectedNpCabinet.contact_person_id;
          break;
        case 'opencart':
          selectedOpencartCabinet.value.np_cabinet_default_id = selectedNpCabinet.contact_person_id;
          break;
        case 'horoshop':
          selectedHoroshopKabinet.value.np_cabinet_default_id = selectedNpCabinet.contact_person_id;
          break;

        case 'hubber':
          selectedHubberCabinet.value.np_cabinet_default_id = selectedNpCabinet.contact_person_id;
          break;
      }


      console.log('selectedOpencartCabinet', selectedOpencartCabinet.value);

      /*      if(marketplaceType === 'prom'){
              promData.value.np_cabinet_defoult = selectedNpCabinet.contact_person_id;
            } else if(marketplaceType === 'rozetka'){
              RozetkaSettings.value.np_cabinet_default_id = selectedNpCabinet.contact_person_id;
            } else if(marketplaceType === 'kasta'){
              selectedKastaKabinet.value.np_cabinet_default_id = selectedNpCabinet.contact_person_id;
            }
            promData.value.np_cabinet_defoult = selectedNpCabinet.contact_person_id;*/
    }

    function selectRozetkaNpCabinetId(selectedNpCabinet) {
      RozetkaSettings.value.np_cabinet_default_id = selectedNpCabinet.contact_person_id;
    }

    const smsSettings = ref({
      api_key: '',
      sms_service_id: 2,
      send_sms_if_ttn_tracked: true,
      auto_send_ttn_in_sms: true,
      auto_send_check_with_ttn: false,
      sms_templates: [
        {
          'name': 'Нова Пошта розсилка ТТН',
          'sms_text_value': npSmsTemplate.value,
          'value_type': 'ttn_np'
        },
        {
          'name': 'Укрпошта розсилка ТТН',
          'sms_text_value': upSmsTemplate.value,
          'value_type': 'ttn_up',
        },
        {
          'name': 'Розетка Делівері розсилка ТТН',
          'sms_text_value': rzSmsTemplate.value,
          'value_type': 'ttn_roz',
        },
        {
          'name': messagePaymentTemplateName.value,
          'sms_text_value': messagePaymentTemplate.value,
          'value_type': 'bank_pay_data',
        },
      ]
    })


    function updateSmsTemplateValue(elem, type) {
      if (type === 'ttn_np') {
        elem.name = 'Нова Пошта розсилка ТТН';
        elem.sms_text_value = npSmsTemplate.value;
      } else if (type === 'ttn_up') {
        elem.name = 'Укрпошта розсилка ТТН';
        elem.sms_text_value = upSmsTemplate.value;
      } else if (type === 'ttn_roz') {
        elem.name = 'Розетка Делівері розсилка ТТН';
        elem.sms_text_value = rzSmsTemplate.value;
      } else if (type === 'bank_pay_data') {
        elem.name = messagePaymentTemplateName.value;
        elem.sms_text_value = messagePaymentTemplate.value;
      }

      elem.value_type = type

    }


    const updateDefaultAlphaName = async (val) => {
      let response = await PuTUpdateDefaultAlphaName(val)
      console.log('update alpha name response', response);
    }

    function fillmessage(status, message) {
      requestMessageStatus.value = true
      requestMessageStatus.value = status
      responseMessage.value = message

      setTimeout(() => {
        resetMessages();
      }, 5000);

    }

    function resetMessages() {
      requestMessageStatus.value = false
      responseMessage.value = ''
    }

    ///////// save datas
    const saveApiKeyNp = async () => {
      if (novaPoshaApikey.value === '') {
        fillmessage('error', 'Введіть ключ');
        return 'error';
      }


      nextButtonEventText.value = 'Зберігаємо'

      let sendSet = senderSettings.value; // додати в очистку

      const val = {
        'apiKey': novaPoshaApikey.value,
        'senderSettings': sendSet,
      }

      if (selectedNpContactPerson.value.cargo_description.id !== null) {
        val.cargo_description = selectedNpContactPerson.value.cargo_description.id
      }

      if (selectedDefaultWarehouse.value.sender_warehouse.id !== null) {
        val.sender_warehouse = selectedDefaultWarehouse.value.sender_warehouse.id
      }

      console.log('saveApiKeyNp', val);
      let response = await createNpSender(val).then((response) => {
        fillmessage(response.status, response.message);
        console.log('create np sender response', response);
        if (response.status === 'error') {
          return 'error';
        } else {
          fillsenderSettingsFromDb(response.data);
          resetNpSettings()
        }
      })
      addNewNpCabinet.value = false
      return response;
    }

    const loadedAlphaNames = ref({})

    const selectedPromCabinet = ref(null)

    async function saveNewAlphaName() {
      await saveDefaultAlphaName()
      saveNewAlphaNameButtonActive.value = false
    }

    const saveApiKeySmsClub = async () => {

      if (smsSettings.value.api_key === '') {

        fillmessage('error', 'Введіть TOKEN');


        return 'error';
      }

      spinerSaveWarehouseActive.value = true


      console.log('smsSettings.value', smsSettings.value)
      // spinerActive.value = true

      const response = await loadAlphaNames(smsSettings.value)

      if (response.status === 'success') {
        loadedAlphaNames.value = response.data.alpha_names
        console.log('loadedAlphaNames.value', loadedAlphaNames.value);
        messageApiKeyId = response.data.message_api_keys_id
        messageSenderSettingsId = response.data.message_sender_settings_id

        if (senderSettingsFromDb.value.message_sender_settings?.alpha_name) {
          //  console.log('join in default alpha name selected')
          defautAlphaNameId.value = senderSettingsFromDb.value.message_sender_settings.alpha_name.id
          defautAlphaName.value = loadedAlphaNames.value[senderSettingsFromDb.value.message_sender_settings.alpha_name.id];
          spinerSaveWarehouseActive.value = false
          return 'chooseAlphaName';
        } else if (loadedAlphaNames.value.length === 1) {
          console.log('loadedAlphaNames.value.length === 1')
          defautAlphaNameId.value = loadedAlphaNames.value[0].id
          defautAlphaName.value = loadedAlphaNames.value[0]
          spinerSaveWarehouseActive.value = false
          return 'promApiKey';
        } else if (loadedAlphaNames.value.length === 2) {
          // обрати альфа ім'я яке не дорівнює 'Shop Zakaz' або 'VashZakaz' або 'club_bulk'
          loadedAlphaNames.value.forEach((item) => {
            if (item.name !== 'Shop Zakaz' && item.name !== 'VashZakaz' && item.name !== 'club_bulk') {
              defautAlphaNameId.value = item.id
              defautAlphaName.value = item
              spinerSaveWarehouseActive.value = false
              return 'chooseAlphaName';
            }
          })
        } else {
          spinerSaveWarehouseActive.value = false
        }
      }

      spinerSaveWarehouseActive.value = false
      /*      smsSettings.value.api_key = ''
            nextButtonEventText.value = 'Далі'
            //   spinerActive.value = false*/
    }


    const saveApiKeyProm = async () => {
      if (promData.value.api_key === '' || promData.value.name === ''
          || getNpCabinetsSelectedForPromState() || getAlphaNameDefStateForProm()
      ) {
        //  console.log('не усі поля заповнені')
        /* requestMessageStatus.value = true
         responseMessage.value = 'Заповніть всі поля'*/

        fillmessage('error', 'Заповніть всі поля');

        return 'error';
      }
      // console.log('всі поля заповнені')

      console.log('prom_data', promData.value)

      nextButtonEventText.value = 'Зберігаємо...'
      let response = await createPromCabinet(promData.value)
      // console.log('prom response', response);
      /* responseMessage.value = response.message
       responseStatus.value = response.status*/

      fillmessage(response.status, response.message);
      customPlaceholder.value = 'Вставте інший API ключ Prom.ua'
      if (response.status === 'error') {
        promData.value.api_key = ''
        return 'error';
      } else {
        console.log('called here 1')
        fillsenderSettingsFromDb(response.data);
      }
      resetPromData()

    }


    async function saveOpencartCabinet() {
      if (selectedOpencartCabinet.value.api_key === '' || selectedOpencartCabinet.value.name === ''
          || getNpCabinetsSelectedForOpencartState() || getAlphaNameDefStateForProm('opencart')
      ) {
        console.log('не усі поля заповнені')
        fillmessage('error', 'Заповніть всі поля');


        // responseMessage.value = 'Заповніть всі поля'
        return 'error';
      }

      let response = await saveOpencartCab({'ocCabinetSettings': selectedOpencartCabinet.value})

      /*  responseMessage.value = response.message
        responseStatus.value = response.status*/

      fillmessage(response.status, response.message);

      if (response.status === 'error') {
        return 'error';
      } else {
        console.log('called here 2')
        fillsenderSettingsFromDb(response.data);
        resetOpencartCabinet();
      }
    }

    async function loadMaket(cabinetId) {
      try {
        // Викликаємо функцію, що отримує вміст файлу з сервера
        let response = await loadMaketFromDb(cabinetId, {
          responseType: 'blob'  // Важливо: отримуємо відповідь у вигляді блоба (файлу)
        });

        if (response) {
          // Створюємо Blob з отриманих даних
          const blob = new Blob([response], {type: 'application/php'});

          // Створюємо тимчасове посилання для завантаження
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);

          // Встановлюємо ім'я файлу для завантаження
          link.setAttribute('download', 'export_orders_json.php');

          // Симулюємо клік, щоб ініціювати завантаження
          document.body.appendChild(link);
          link.click();

          // Видаляємо посилання після завантаження
          document.body.removeChild(link);
        }
      } catch (error) {
        console.error("Помилка при завантаженні файлу:", error);
      }
    }


    const saveRozetkaCabinet = async () => {
      if (RozetkaSettings.value.name === '' || RozetkaSettings.value.url === ''
          /* || getNpCabinetsSelectedForRozetkaState() || getAlphaNameDefStateForProm('rozetka')*/
      ) {
        fillmessage('error', 'Заповніть всі поля');
        return 'error';
      }

      if (RozetkaSettings.value.id !== null &&
          oldRozetkaPassword.value[RozetkaSettings.value.id] === RozetkaSettings.value.password) {
        RozetkaSettings.value.password = '';
      }

      nextButtonEventText.value = 'Зберігаємо...'
      let response = await createRozetkaCabinet(RozetkaSettings.value)


      fillmessage(response.status, response.message);
      customPlaceholder.value = 'Вставте інший API ключ Rozetka.ua'
      if (response.status === 'error') {
        RozetkaSettings.value.password = ''
        return 'error';
      } else {
        console.log('called here 3')
        fillsenderSettingsFromDb(response.data);
        resetRozetkaSettings()
      }


    }


    const saveApiKeyKasta = async () => {
      if (selectedKastaKabinet.value.api_key === '' || selectedKastaKabinet.value.name === ''
          || getNpCabinetsSelectedForPromState('kasta') || getAlphaNameDefStateForProm('kasta')
      ) {
        fillmessage('error', 'Заповніть всі поля');
        return 'error';
      }

      nextButtonEventText.value = 'Зберігаємо...'
      console.log('selectedKastaKabinet.value', selectedKastaKabinet.value);
      let response = await createKastaCabinet(selectedKastaKabinet.value) // save/create
      fillmessage(response.status, response.message);
      customPlaceholder.value = 'Вставте інший API ключ'
      if (response.status === 'error') {
        selectedKastaKabinet.value.api_key = ''
        return 'error';
      } else {
        resetKastaCabinet()
        console.log('called here 5')
        fillsenderSettingsFromDb(response.data);
      }

    }


    const saveHoroshopCabinet = async () => {

      if (selectedHoroshopKabinet.value.username === ''
          || selectedHoroshopKabinet.value.password === ''
          || selectedHoroshopKabinet.value.site_url === ''
          || getNpCabinetsSelectedForPromState('horoshop')
          || getAlphaNameDefStateForProm('horoshop')
      ) {
        fillmessage('error', 'Заповніть всі поля');
        return 'error';
      }

      selectedHoroshopKabinet.value.site_url = selectedHoroshopKabinet.value.site_url.replace(/^https?:\/\//, '');

      nextButtonEventText.value = 'Зберігаємо...'
      console.log('selectedHoroshopKabinet.value', selectedHoroshopKabinet.value);
      let response = await createHoroshopCabinet(selectedHoroshopKabinet.value) // save/create
      fillmessage(response.status, response.message);


      customPlaceholder.value = ''

      if (response.status === 'error') {

        selectedHoroshopKabinet.value.password = ''
        return 'error';
      } else {
        console.log('called here 6')
        fillsenderSettingsFromDb(response.data);
        resetHoroshopCabinet()
      }


    }

    async function savehubberCabinet() {
      if (selectedHubberCabinet.value.name === ''
          || selectedHubberCabinet.value.login === ''
          || selectedHubberCabinet.value.password === ''
          || selectedHubberCabinet.value.company_id === ''
          || getNpCabinetsSelectedForPromState('hubber')
          || getAlphaNameDefStateForProm('hubber')
      ) {
        fillmessage('error', 'Заповніть всі поля');
        return 'error';
      }
      console.log('selectedHubberCabinet.value', {'cabinet_data': selectedHubberCabinet.value})

      let response = await createHubberCabinet({'cabinet_data': selectedHubberCabinet.value}) // save/create
      fillmessage(response.status, response.message);

      customPlaceholder.value = ''

      if (response.status === 'error') {
        return 'error';
      } else {

        fillsenderSettingsFromDb(response.data);
        resethubberCabinet();
      }

    }


    const saveApiKeyUkrPoshta = async () => {
      /*       sender_name: '',
               index: '',
               phone: 380,
               email: null,
               bank_account: '',
               tin: edroupOrIpnValue.value.type === 'ipn' ? edroupOrIpnValue.value.data : null,
               edrpou: edroupOrIpnValue.value.type === 'edrpou' ? edroupOrIpnValue.value.data : null,
               bearer_ecom: '',
               tracking_key: '',
               counterparty_token: '',
               counterparty_uuid: '',*/
      if (ukrPoshtaData.value.sender_name === '' ||
          (ukrPoshtaData.value.edrpou === '' || ukrPoshtaData.value.tin === '')
          || ukrPoshtaData.value.index === '' || ukrPoshtaData.value.phone.length !== 12
          || ukrPoshtaData.value.bearer_ecom === ''
          || ukrPoshtaData.value.tracking_key === '' || ukrPoshtaData.value.counterparty_token === ''
          || ukrPoshtaData.value.counterparty_uuid === ''
      ) {
        fillmessage('error', 'Заповніть всі поля');

        return 'error';
      }


      if (edroupOrIpnValue.value.type === 'ipn') {
        ukrPoshtaData.value.tin = innVal.value
        ukrPoshtaData.value.edrpou = null
      } else {
        ukrPoshtaData.value.edrpou = innVal.value
        ukrPoshtaData.value.tin = null
      }

      ukrPoshtaData.value.paid_by_recipient = ukrPoshtaData.value.delivery_payer_id

      let response = await createUpCabinet(ukrPoshtaData.value)

      fillmessage(response.status, response.message);

      if (response.status === 'success') {
        console.log('called here 7')
        fillsenderSettingsFromDb(response.data);
        resetUkrPoshta()
      }

    }

    function getNpCabinetsSelectedForPromState(marketplackeType = 'prom') {


      switch (marketplackeType) {
        case 'prom':
          return cabinetsNp.length > 1 && promData.value.np_cabinet_defoult === null;
        case 'rozetka':
          return cabinetsNp.length > 1 && RozetkaSettings.value.np_cabinet_default_id === null;
        case 'kasta':
          return cabinetsNp.length > 1 && selectedKastaKabinet.value.np_cabinet_default_id === null;
        case 'horoshop':
          return cabinetsNp.length > 1 && selectedHoroshopKabinet.value.np_cabinet_default_id === null;
        case 'hubber':
          return cabinetsNp.length > 1 && selectedHubberCabinet.value.np_cabinet_default_id === null;
      }

      //  return cabinetsNp.length > 1 && promData.value.np_cabinet_defoult === null;
    }

    function getAlphaNameDefStateForProm(marketplackeType = 'prom') {

      switch (marketplackeType) {
        case 'prom':
          return loadedAlphaNames.value.length > 0 && defautAlphaNameId.value === null && promData.value.alpha_name_id === null;
        case 'opencart':
          return loadedAlphaNames.value.length > 0 && defautAlphaNameId.value === null && selectedOpencartCabinet.value.alpha_name_id === null;
        case 'rozetka':
          return loadedAlphaNames.value.length > 0 && defautAlphaNameId.value === null && RozetkaSettings.value.alpha_name_id === null;
        case 'kasta':
          return loadedAlphaNames.value.length > 0 && defautAlphaNameId.value === null && selectedKastaKabinet.value.alpha_name_id === null;
        case 'horoshop':
          return loadedAlphaNames.value.length > 0 && defautAlphaNameId.value === null && selectedHoroshopKabinet.value.alpha_name_id === null;
        case 'hubber':
          return loadedAlphaNames.value.length > 0 && defautAlphaNameId.value === null && selectedHubberCabinet.value.alpha_name_id === null;


        default:
          return loadedAlphaNames.value.length > 0 && defautAlphaNameId.value === null && promData.value.alpha_name_id === null;
      }

      //  return loadedAlphaNames.value.length > 0 && defautAlphaNameId.value === null && promData.value.alpha_name_id === null;
    }

    function getNpCabinetsSelectedForRozetkaState() {
      return cabinetsNp.length > 1 && RozetkaSettings.value.np_cabinet_default_id === null;
    }

    /*
        function getAlphaNameDefStateForRozetka() {
          return loadedAlphaNames.value.length > 0 && defautAlphaNameId.value === null && RozetkaSettings.value.alpha_name_id === null;
        }*/


    function getNpCabinetsSelectedForOpencartState() {
      return cabinetsNp.length > 1
          && selectedOpencartCabinet.value.np_cabinet_default_id === null;
    }

    const senderSettingsForEditing = ref(null);
    const paymentTypes = store.getters['order/getPaymentTypes']

    // console.log('paymentTypes', paymentTypes);

    /*    const paymentMethods = {
          2: 'Післяплата',
          4: 'Без наложки'
        }*/

    const npCabinetsForEditing = ref({}); // Reactive array to hold contact person data
    /*    const selectedNpContactPerson = ref({
          counterparty_id: null,
          CounterpartyFullName: '',
          api_key: '',
          default_sender: false,
          contact_person_id: null,
          contactPersonaName: '',
          payment_method: null,
          default_payer_id: null,
          defaultContactPerson: null,
          non_cache: false,
          payment_control: false,
          defaultSenderGlobaly: false,
        }); */

    const selectedNpContactPerson = ref({
      cargo_description: {
        id: null,
        name: '',
      },
      payment_method_id: 2,
      contact_person_id: null,
    })

    function clearSelectedContactPersonValues() {
      selectedNpContactPerson.value = {
        cargo_description: {
          id: null,
          name: '',
        },
        payment_method_id: 2,
        contact_person_id: null,
      }
      novaPoshaApikey.value = '';
    }

    const senderSettingsFromDb = ref({});

    function fillSenderSettings(response) {
      console.log('senderSettigns', response);
      senderSettings.value.id = response.sender_settings?.id;
      senderSettings.value.article_or_name = response.sender_settings?.article_or_name;
      senderSettings.value.senderIsDeliveryPayer = response.sender_settings?.senderPayerIfSumBigger !== 1500000;
      senderSettings.value.senderPayerIfSumBigger = response.sender_settings?.senderPayerIfSumBigger;
      senderSettings.value.create_return_id_if_canceled = response.sender_settings?.create_return_id_if_canceled === 1;
      senderSettings.value.min_product_price = response.sender_settings?.min_product_price;
      senderSettings.value.create_return_if_storage_n_days = response.sender_settings?.create_return_if_storage_n_days === 1;
      senderSettings.value.default_weight = response.sender_settings?.default_weight;
      senderSettings.value.defaultKassa = {
        'default_rro_id': response.sender_settings?.default_rro_id,
        'default_rro_type': response.sender_settings?.default_rro_type
      };
      console.log('senderSettingsFilled', senderSettings.value)
    }

    function processContactPersons(response) {
      const result = {}; // Using an object to store contact persons by their ID
      response.delivery_counterparties.forEach(item => {
        item.contact_persons.forEach(el => {
          // Construct the contact person object
          const contactPerson = {
            [el.id]: {
              counterparty_id: item.id,
              disabled: el.disabled === 1,
              CounterpartyFullName: item.CounterpartyFullName,
              api_key: item.api_key,
              default_sender: item.default_sender === 1,
              contact_person_id: el.id,
              city: {
                'id': el.city?.id ?? null,
                'name': el.city?.name ?? 'Оберіть місто',
              },
              sender_warehouse: {
                'id': el.sender_warehouse?.id ?? null,
                'name': el.sender_warehouse?.name_ua ?? 'Оберіть відділення',
              },
              cargo_description: {
                'id': el.cargo_description?.id ?? null,
                'name': el.cargo_description?.name ?? 'Оберіть опис вантажу',
              },
              import_ttns_from_lk: item.import_ttns_from_lk === 1,
              contactPersonaName: el.name,
              payment_method_id: el.payment_method_id,
              default_payer_id: el.types_payers_redelivery_id,
              defaultContactPerson: el.defaultContactPerson === 1,
              non_cache: el.non_cash === 1,
              defaultSenderGlobaly: item.default_sender === 1 && el.defaultContactPerson === 1,
            }
          };
          // Add the contact person object to the result
          result[el.id] = contactPerson[el.id];
        });
      });

      // Assign the processed contact persons to npCabinetsForEditing
      npCabinetsForEditing.value = result;
    }

    // Function to fetch and process NP cabinets list
    const fetchNpCabinetsForEditing = async () => {
      mainSpiner.value = true;
      const response = await getNpCabinetsList();


      if (response.status === 'error') {
        return 'error';
      } else {
        console.log('called here 8')
        fillsenderSettingsFromDb(response);
        await getSenderSettings()
      }
      mainSpiner.value = false;
    };


    function fillsenderSettingsFromDb(response) {
      console.log('getNpCabinetsList response', response);
      if (response.sender_settings !== null) {
        fillSenderSettings(response);

        /*   senderSettings.value.id = response.sender_settings?.id;
           senderSettings.value.article_or_name = response.sender_settings?.article_or_name;
           senderSettings.value.senderIsDeliveryPayer = response.sender_settings?.senderPayerIfSumBigger !== 1500000;
           senderSettings.value.senderPayerIfSumBigger = response.sender_settings?.senderPayerIfSumBigger;
           senderSettings.value.create_return_id_if_canceled = response.sender_settings?.create_return_id_if_canceled;
           senderSettings.value.min_product_price = response.sender_settings?.min_product_price;
           senderSettings.value.create_return_if_storage_n_days = response.sender_settings?.create_return_if_storage_n_days;
           senderSettings.value.default_weight = response.sender_settings?.default_weight;*/
      }

      if (response?.delivery_counterparties.length > 0) {
        processContactPersons(response);
      }

      if (response?.alpha_names.length > 0) {
        // console.log('senderSettingsFromDb.value.alpha_names', response.alpha_names)
        response.alpha_names.forEach((item) => {
          loadedAlphaNames.value[item.id] = {
            id: item.id,
            name: item.name
          };
        })
      }


      if (response?.check_box_cabinet.length > 0) {
        checkBoxCabinets.value = response.check_box_cabinet;

        console.log('checkBoxCabinets', checkBoxCabinets.value)

      }

      senderSettingsFromDb.value = response
      getSmsTemplatesState();
    }


    const search = async (type, value) => {
      if (!value) {
        return;
      }
      let queryurl = '';
      if (type === 'city') {
        //  console.log('value', value)
        queryurl = 'cities?city_name=' + value;
        if (value.length < 3) {
          return;
        }
      } else if (type === 'npWarehouse') {
        queryurl = `cities/${selectedDefaultWarehouse.value.city.id}/warehouses/` + value;
      } else if (type === 'cargoDesc') {
        queryurl = 'get-parcel-decriptions/?name=' + value;
      }
      try {
        //  console.log('queryurl', [queryurl]);
        //  console.log('searchResponse', searchResponse);
        searchResults.value = await getSearchResults(queryurl);
      } catch (e) {
        console.error(e);
      }
    }

    const saveNewWarehouseSpinner = ref(false);

    async function saveNpContactPersonChanges() {
      saveNewWarehouseSpinner.value = true;
      try {
        // console.log('selectedNpContactPerson', selectedNpContactPerson.value);
        const response = await editContactPerson(selectedNpContactPerson.value);

        if (response) {
          // fetchNpCabinetsForEditing();
          // selectedNpContactPerson.value = null;
        }
      } catch (e) {
        console.error(e);
      }

      saveNewWarehouseSpinner.value = false;
      // console.log('selectedNpContactPerson', selectedNpContactPerson.value);
    }

    async function saveSenderSettings() {
      try {
        //  console.log('senderSettings', senderSettings.value);
        const response = await editSenderSettings(senderSettings.value);

        if (response) {
          // fetchNpCabinetsForEditing();
          // selectedNpContactPerson.value = null;
        }
      } catch (e) {
        console.error(e);
      }
      // console.log('senderSettings', senderSettings.value);
    }


    function selectCagroDesc(cargoDesc) {
      selectedNpContactPerson.value.cargo_description.id = cargoDesc.id;
      selectedNpContactPerson.value.cargo_description.name = cargoDesc.name;
      ukrPoshtaData.value.description.id = cargoDesc.id;
      ukrPoshtaData.value.description.name = cargoDesc.name;

      // console.log('ukrPoshtaData', ukrPoshtaData.value);
      inputSearchValue.value = '';
      searchResults.value = [];
    }

    function selectCity(city) {
      selectedDefaultWarehouse.value.city.id = city.id;
      selectedDefaultWarehouse.value.city.name = city.name;
      inputSearchValue.value = '';
      searchResults.value = [];
    }

    function selectWarehouse(warehouse) {
      //  console.log('warehouse', warehouse);
      selectedDefaultWarehouse.value.sender_warehouse.id = warehouse.id;
      selectedDefaultWarehouse.value.sender_warehouse.number = warehouse.number;
      selectedDefaultWarehouse.value.sender_warehouse.name = warehouse.name_ua;
      inputSearchValue.value = '';
      searchResults.value = [];
    }

    const contactPersonDefaultPaymentType = computed(() => {
      return paymentTypes[selectedNpContactPerson.value.payment_method_id].name

    });

    function selectContactPerson(contactPerson) {
      selectedNpContactPerson.value = contactPerson;
      novaPoshaApikey.value = contactPerson.api_key;
      console.log('selectedNpContactPerson', selectedNpContactPerson.value);
    }

    const mainSpiner = ref(true);

    onMounted(() => {
      fetchNpCabinetsForEditing(); // Fetch NP cabinets list on component mount
      loadSources();
    });

    const responseMessage = ref('');
    const responseStatus = ref('success');
    const requestMessageStatus = ref(false);

    const modalTitle = ref('Налаштування');

    let messageApiKeyId;
    let messageSenderSettingsId;
    let cabinetsNp = [];
    const defautAlphaNameId = ref(null);
    const skipIsHidden = ref(false);
    const isNpSendersExists = () => npSenders.value ? npSenders.value.length === 0 : true
    skipIsHidden.value = isNpSendersExists();
    const myId = ref('settings-modal')
    const novaPoshaApikey = ref('')
    const globalTabs = ref('shippers');
    const npSubTabs = ref('np');
    const shippingTabs = ref('np');
    const addNewNpCabinet = ref(false);
    const marketplacesTabs = ref('prom');
    const smsTabs = ref('smsClub');
    const smsStep = ref('smsClub');
    const customPlaceholder = ref('API ключ Нової Пошти')
    const nextButtonEventText = ref('Далі')
    const middleButtonIsVisible = ref(true);
    const middleButtonText = ref('Додати ще один кабінет НП')
    const middleButtonEvent = ref(saveApiKeyNp)

    const npNextButtonEvent = async (type) => {
      if (type === 'next') {
        console.log('if type next');
        console.log('selectedNpContactPerson.value', selectedNpContactPerson.value)
        if (selectedNpContactPerson.value.contact_person_id === null) {
          console.log('if selectedNpContactPerson.value.id === null');

          let saveApiKeyNpResp = await saveApiKeyNp()

          if (saveApiKeyNpResp === 'error') {
            return
          }
        }
      }

      /*    spinerActive.value = true
            senderSettingsFromDb.value = await store.dispatch('order/senderSettings')
            if (senderSettingsFromDb.value.alpha_names.length > 0) {
              console.log('senderSettingsFromDb.value.alpha_names', senderSettingsFromDb.value.alpha_names);
              senderSettingsFromDb.value.alpha_names.forEach((item) => {
                loadedAlphaNames.value[item.id] = {
                  id: item.id,
                  name: item.name
                };
              })
              console.log('loadedAlphaNames.value', loadedAlphaNames.value);
            }

            */

      requestMessageStatus.value = false
      responseMessage.value = ''
      middleButtonIsVisible.value = false;
      nextButtonEventText.value = 'Далі'
      modalTitle.value = 'Введіть токен SMSClub для відправки СМС'
      customPlaceholder.value = 'API ключ SMS Club'
      nextButtonEvent.value = smsClubNextButtonEvent;
      currentStep.value = 'smsClub'
      skipIsHidden.value = getSmsTemplatesState()
    };

    const nextButtonEvent = ref(npNextButtonEvent);


    const getSmsTemplatesState = () => {
      //  console.log('senderSettingsFromDb.valu', senderSettingsFromDb.value);
      if (senderSettingsFromDb.value) {
        // smsSettings.value.sms_templates
        if (senderSettingsFromDb.value.sms_templates?.length > 0) {
          smsSettings.value.sms_templates = senderSettingsFromDb.value.sms_templates;
        }

        // заповнювати defautAlphaName та defautAlphaNameId

        defautAlphaName.value.id = senderSettingsFromDb.value.message_sender_settings?.default_alpha_name_id ?? null;
        defautAlphaName.value.name = senderSettingsFromDb.value.message_sender_settings?.alpha_name?.name ?? null;

        smsSettings.value.api_key = senderSettingsFromDb.value.message_sender_settings?.message_api_keys[0]?.api_key;
        // smsSettings.value.sms_service_id = senderSettingsFromDb.value;
        smsSettings.value.send_sms_if_ttn_tracked = senderSettingsFromDb.value.message_sender_settings?.send_sms_if_ttn_tracked === 1;
        smsSettings.value.auto_send_ttn_in_sms = senderSettingsFromDb.value.message_sender_settings?.auto_send_ttn_in_sms === 1;
        smsSettings.value.auto_send_check_with_ttn = senderSettingsFromDb.value.message_sender_settings?.auto_send_check_with_ttn === 1;
        // console.log('smsSettings', smsSettings.value);
        return senderSettingsFromDb.value.sms_templates?.length === 0;
      } else {
        return false
      }
    }


    const smsTemplWithKeys = {
      'bank_pay_data': 'Рекзвізити на оплату',
      'ttn_np': 'Розсилка ТТН Нова Пошта',
      'ttn_up': 'Розсилка ТТН Укрпошта',
      'ttn_roz': 'Розсилка ТТН Розетка'
    }

    const SMS_TEMPLATE_TYPES = [
      /* {
'bank_pay_data': 'Рекзвізити на оплату',
'ttn_np': 'Розсилка ТТН Нова Пошта',
'ttn_up': 'Розсилка ТТН Укрпошта',
'ttn_roz': 'Розсилка ТТН Розетка'
}*/

      {
        value: 'bank_pay_data',
        text: 'Рекзвізити на оплату'
      },
      {
        value: 'ttn_np',
        text: 'Розсилка ТТН Нова Пошта'
      },
      {
        value: 'ttn_up',
        text: 'Розсилка ТТН Укрпошта'
      },
      {
        value: 'ttn_roz',
        text: 'Розсилка ТТН Розетка'
      }
    ]


    function addSmsTemplate() {
      smsSettings.value.sms_templates.push({
        'name': '',
        'sms_text_value': '',
        'for_ttns': false,
        'value_type': 'bank_pay_data'
      })
    }

    const saveDefaultAlphaName = () => {

      const val = {
        'alpha_name': defautAlphaName.value.id,
      }
      updateDefaultAlphaName(val)
      //  nextButtonEventText.value = 'Зберігаємо...'
      //  middleButtonText.value = 'Додати ще кабінет PROM '
      //  middleButtonIsVisible.value = true;
      //  middleButtonEvent.value = saveApiKeyProm
      //   modalTitle.value = 'Введіть API ключ PROM'
      //    nextButtonEventText.value = 'Далі'
      // nextButtonEvent.value = promNextButtonEvent;
      // currentStep.value = 'promApiKey'
      //  skipIsHidden.value = false

      return 'success'
    }


    const allSources = ref([]);


    const modifiedSources = ref([]);

    async function loadSources() {
      await store.dispatch('order/getCustomSources').then(() => {
        allSources.value = store.getters['order/getCustomSources'];
        // console.log('allSources', allSources.value);
      });
    }

// WatchEffect для відстеження змін у `price_type_id` та `stock_id`
    watchEffect(() => {
      modifiedSources.value = Object.values(allSources.value).filter((source) => {
        return (
            source.price_type_id !== source.original_price_type_id ||
            source.stock_id !== source.original_stock_id
        );
      });
    });

// Обчислюване значення для видимості кнопки
    const isModified = computed(() => modifiedSources.value.length > 0);


    const saveUsers = async () => {
      console.log('saveCrmUsers called')


      let response = await createCrmUsersRequest({users: users.value})

      console.log('create users res', response)
      fillmessage(response.status, response.message);

      if (response.status === 'error') {
        console.log('create users error asd')

        return 'error';
      } else {
        fillUsers(response.data)
      }
      return response;
    }


    const saveNewAlphaNameButtonActive = ref(false);

    function selectAlphaName(alphaName) {
      saveNewAlphaNameButtonActive.value = true
      defautAlphaNameId.value = alphaName.id
      defautAlphaName.value = alphaName
    }

    function usersEmails() {
      console.log('extractUserEmails')
      let usersData = []
      for (let i = 0; i < users.value.length; i++) {
        if (users.value[i].email !== '') {
          usersData.push({
            "email": users.value[i].email,
          })
        }
      }
      //  console.log('usersData', usersData)
      return usersData
    }

    const orderSourceForSave = ref(
        {
          'name': '',
          'url': '',
          'source_type': {
            'id': 0,
            'name': null
          },
          'alphaName': null
        }
    )

    function selectSourceType(sourceType) {
      console.log('selectedSouceType', sourceType)
      orderSourceForSave.value.source_type = sourceType
      console.log('orderSourceForSave', orderSourceForSave.value)
    }


    async function addSource() {
      const dataForSave = {
        'name': orderSourceForSave.value.name,
        'url': orderSourceForSave.value.url,
        'source_type': orderSourceForSave.value.source_type.id,
        'alpha_name_id': orderSourceForSave.value.alphaName
      }
      console.log('dataForSave', dataForSave)

      const res = await saveOrderSource(dataForSave)

      await store.dispatch('alert/triggerAlert', {
        show: true,
        message: res.message,
        variant: res.status
      });

      console.log('create source', res)

      orderSourceForSave.value = {
        'name': '',
        'url': '',
        'source_type': {
          'id': 0,
          'name': null
        },
        'alphaName': null
      }


      await store.dispatch('order/getCustomSources').then(() => {
        allSources.value = store.getters['order/getCustomSources'];
        console.log('allSources', allSources.value)
      })


    }

    const excludedSourceIds = [
      1, // prom
      3, // rozetka
      4, // horoshop
      5, // opencart
      12, // kasta
      14, // import ttn s lk
      15, // ручной ввод

    ];

    const filteredOrderSourceTypes = computed(() => {
      return orderSourceTypes.value.filter(source => !excludedSourceIds.includes(source.id));
    });


    function setOrderSourceToUser(source, userIndex) {
      console.log('setOrderSourceToUser', source)
      /*
        id: 1
        name:"some"
        sourceType:"inst"
       */

      users.value[userIndex].source_name = source.name ?? source.username
      users.value[userIndex].source_type = source.sourceType
      users.value[userIndex].source_id = source.id
    }


    const getPriceType = (data) => {
      if (data.price_type_id === null) {
        return 'Основний'
      } else {
        return priceTypes.value[data.price_type_id].name
      }
    }

    const getStockType = (data) => {
      if (data.stock_id === null) {
        return 'Основний'
      } else {
        return stockTypes.value[data.stock_id].name
      }
    }

    function selectPriceType(source, priceType) {

      source.price_type_id = priceType.id
    }

    function selectStockType(source, stockType) {
      source.stock_id = stockType.id
    }


    async function saveNewPriceStockTypes() {


      console.log('modifiedSources.value',)
      await updatePricesStockTypesForSources({'sources': modifiedSources.value}).then((res) => {
        console.log('res', res)
        store.dispatch('alert/triggerAlert', {
          show: true,
          message: res.message,
          variant: res.status
        });
      })

    }

    const moveToProductsPage = () => {
      emit('update:showSettingsModal', false);
      router.push('/product-list'); // Переходить на інший роут після закриття модального вікна
    }

    const priceTypesResponseHandler = (data) => {
      console.log('priceTypesResponse', data)
      priceTypes.value = data
    }

    const showUpFields = ref(false);

    async function closeOrderModal() {
      console.log('closeOrderModal')
      await getSenderSettings()
      emit('update:showSettingsModal', false);
      window.location.reload();
    }

    return {
      myId,
      nextButtonEvent,
      novaPoshaApikey,
      addiditionalTTnInfo,
      smsSettings,
      globalTabs,
      npSubTabs,
      middleButtonText,
      middleButtonEvent,
      middleButtonIsVisible,
      nextButtonEventText,
      customPlaceholder,
      DeliveyPayerIsRecipient,
      backwardDeliveyPayerIsReceiver,
      uponFailReceiveTypes,
      ukrPoshtaData,
      skipIsHidden,
      requestMessageStatus,
      responseMessage,
      loadedAlphaNames,
      promData,
      defautAlphaNameId,
      changeRozetkaOrdrerStatuses,
      sendTtnsToRozetka,
      RozetkaSettings,
      cabinetsNp,
      // returnMoneyOnBank,
      modalTitle,
      defautAlphaName,
      edroupOrIpnValue,
      npSenders,
      selectEdrouIpn,
      showUpAddiditionalSettings,
      senderSettings,
      innVal,
      changeOrdrerStatuses,
      sendTtnsToProm,
      uploadStocksToRozetka,
      uploadStocksToProm,
      users,
      addUser,
      npSmsTemplate,
      messagePaymentTemplateName,
      upSmsTemplate,
      rzSmsTemplate,
      messagePaymentTemplate,
      npCabinetsForEditing,
      senderSettingsForEditing,
      selectedNpContactPerson,
      paymentTypes,
      payerTypes,
      // paymentMethods,
      setNewValueForSelectedNpContactPerson,
      //  getSlectedPaymentTypeName,
      selectContactPerson,
      contactPersonDefaultPaymentType,
      searchResults,
      inputSearchValue,
      selectCagroDesc,
      search,
      selectCity,
      selectWarehouse,
      saveNpContactPersonChanges,
      saveSenderSettings,
      senderSettingsFromDb,
      addSmsTemplate,
      selectAlphaName,
      selectedPromCabinet,
      selectPromCabinet,
      selectPromAlphaName,
      selectPromNpCabinetId,
      selectRozetkaNpCabinetId,
      selectRozetkaCabinet,
      selectRozetkaAlphaName,
      promCabinetWasSelected,
      selectUpCabinetId,
      selectUpCagroDesc,
      senderAddressNp,
      selectSenderWarehouse,
      selectedSenderWarehouse,
      selectedDefaultWarehouse,
      addingNewWarehouse,
      saveWarehouse,
      spinerSaveWarehouseActive,
      warehouseWasChanged,
      mainSpiner,
      saveNewWarehouseSpinner,
      selectedKastaKabinet,
      selectKastaCabinet,
      selectedHoroshopKabinet,
      selectHoroshopCabinet,
      SMS_TEMPLATE_TYPES,
      smsTemplWithKeys,
      updateSmsTemplateValue,
      orderSourceTypes,
      selectSourceType,
      addSource,
      selectedOpencartCabinet,
      orderSourceForSave,
      selectOpencartStore,
      filteredOrderSourceTypes,
      customSources,
      setOrderSourceToUser,
      priceTypes,
      stockTypes,
      allSources,
      getPriceType,
      getStockType,
      selectPriceType,
      selectStockType,
      isModified,
      saveNewPriceStockTypes,
      shippingTabs,
      marketplacesTabs,
      smsTabs,
      smsStep,
      addNewNpCabinet,
      clearSelectedContactPersonValues,
      saveApiKeyNp,
      saveApiKeySmsClub,
      saveNewAlphaName,
      saveNewAlphaNameButtonActive,
      saveApiKeyProm,
      resetPromData,
      resetRozetkaSettings,
      saveRozetkaCabinet,
      resetKastaCabinet,
      saveApiKeyKasta,
      saveHoroshopCabinet,
      resetHoroshopCabinet,
      saveOpencartCabinet,
      resetOpencartCabinet,
      loadMaket,
      moveToProductsPage,
      priceTypesResponseHandler,
      saveApiKeyUkrPoshta,
      resetUkrPoshta,
      showUpFields,
      responseStatus,
      saveUsers,
      selecthubberStore,
      selectedHubberCabinet,
      savehubberCabinet,
      resethubberCabinet,
      closeOrderModal,
      checkBoxCabinets,
      editKassa,
      selectedKassa,
      selectKassaForEdit,
      productNameInCheck,
      selectCheckProductNameValues,
      addKassa,
      saveKassa,
      getDefaultKassaVal,
      handleChange,


    }
  },
  components: {
    BModal,
    BFormTextarea,
    BFormCheckbox,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BInputGroup,
    BCard,
    BFormInput,
    BSpinner,
    BFormText,
    BFormInvalidFeedback,
    BInputGroupText,
    BTable,
    BTabs,
    BTab,
    BButtonGroup,
    priceTypes,
    BBadge,
    SupportButtonDropdown,
    selectDefaultKassa
  },

  directives: {
    'b-tooltip': BTooltip,
  },


}
</script>

<style scoped>
ul.dropdown-menu.show {
  border: 1px solid black;
}


div#settings-modal-body {
  min-height: 250px !important;
}

/* Стилі для батьківських вкладок */
.parent-tabs .nav-tabs {
  background-color: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
  font-size: 1.1rem; /* Збільшуємо шрифт для батьківських вкладок */
}

.parent-tabs .nav-link {
  padding: 10px 20px; /* Збільшуємо відступи для батьківських вкладок */
  font-weight: bold;
}

.parent-tabs .nav-link.active {
  background-color: #007bff;
  color: white;
}

/* Стилі для дочірніх вкладок */
.child-tabs .nav-tabs {
  background-color: #e9ecef;
  border-bottom: 1px solid #ced4da;
  font-size: 0.85rem; /* Зменшуємо шрифт для дочірніх вкладок */
}

.child-tabs .nav-link {
  padding: 5px 15px; /* Менші відступи для дочірніх вкладок */
}

.child-tabs .nav-link.active {
  background-color: #6c757d;
  color: white;
}

ul.ml-2xl {
  margin-left: 20px;
}

</style>