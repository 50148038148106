<template>
<theNavbar></theNavbar>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import theNavbar from "@/components/theNavbar";
export default {
  name: "mainLayout",

  components: {
    theNavbar
  }
}
</script>

<style scoped>

</style>