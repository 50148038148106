//import axios from "axios";
import apiCall from "@/axios/apiRequests";

import router from "@/Router/router";


export default {
    namespaced: true,
    state() {
        return {
            token:localStorage.getItem('token_key'),
           backUrl: process.env.VUE_APP_LARAVEL_APP_URL,

            socket: null,

            uuid: null
        }
    },

    mutations: {
        saveToken(state, token) {
            state.token = token
            localStorage.setItem('token_key', token)
         //   console.log('token', token)
        },


        setUuid(state, uuid){
            state.uuid = uuid
        },

        logout(state) {
            state.token = null
            localStorage.removeItem('token_key')
            localStorage.removeItem('ukrPostSenders')
            localStorage.removeItem('senderSettings')
            localStorage.removeItem('sms_templates')
            localStorage.removeItem('npSenders')
            localStorage.removeItem('dropShipType')
            localStorage.removeItem('senderAddressesNp')
            localStorage.removeItem('NavigationWidth')
            localStorage.removeItem('orders')
            localStorage.removeItem('stockNames')
            localStorage.removeItem('changedOrderForFront.date')
            localStorage.removeItem('pusherTransportTLS')
            localStorage.removeItem('phonesRelatedToOrders')
            localStorage.removeItem('userData')
            localStorage.removeItem('price_types')
            localStorage.removeItem('pusherTransportNonTLS')

            router.push({name: 'auth'})
        },

        loginWithGoogle(){
            window.location.href = `${process.env.VUE_APP_LARAVEL_APP_URL}/login/google`;
        },

        setSocket(state, socket) {
            state.socket = socket
        }
    },

    actions: {
        async validationToken({ commit }, token) {
            commit('saveToken', token);
            const url = `/validate-token`;
            const tokenVer = {
                tokenVer: Math.floor(100000 + Math.random() * 900000)
            };

            try {
                return await apiCall('post', url, tokenVer);

            } catch (e) {
                console.error('Error:', e);
                throw e;
            }
        }
    },

    getters: {
        token(state){
            return state.token
        },

        uuid(state){
            return state.uuid
        },

        backUrl(state){
            return state.backUrl
        },

        isAuth(_, getters){
          //  console.log('isAuth', getters.token)
            return !!getters.token

        },

        getSocket(state) {
            return state.socket
        }

    }


}