export default {

    namespaced: true,

    state: {
        alert: {
            showAlert: false,
            alertVariant: '',
            alertMessage: '',
        }
    },
    mutations: {
        setAlertState(state, payload) {
          //  console.log('set alert state', payload);
            state.alert.showAlert = payload.show;
            state.alert.alertVariant = payload.variant || '';
            state.alert.alertMessage = payload.message || '';
        },

        closeAlert(state) {
            state.alert.showAlert = 0;
        }
    },
    actions: {
        triggerAlert({ commit }, payload) {
            commit('setAlertState', payload);

            if (payload.show) {
                setTimeout(() => {
                    commit('closeAlert');
                }, payload.show);
            }
        }
    },

    getters: {
        getAlertState(state) {
           // console.log('state.alert', state.alert);
            return state.alert
        }
    }
}




