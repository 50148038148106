export default {

    namespaced: true,
    state: {
        modalChangeOrderStatus: {
            localModalStatus: false,
            title: 'Змінити статус замовлення',
            type: 'changeStatus', // controlByDate  changeStatus

        }
    },
    mutations: {
        changeModalStatus(state, payload) {
            state.modalChangeOrderStatus.localModalStatus = true;
            state.modalChangeOrderStatus.title = payload.title
            state.modalChangeOrderStatus.type = payload.type
        },

        closeModal(state) {
            state.modalChangeOrderStatus.localModalStatus = false;
        }


    },
    actions: {

    },

    getters: {
        getModalStatus(state) {
            return state.modalChangeOrderStatus
        },

        getPaymentRepostState(state) {
            return state.modalChangeOrderStatus.localModalStatus
        }

    }
}




