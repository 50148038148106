import {createRouter, createWebHistory} from "vue-router";
import Orders from "@/views/Orders";
import SingleOrder from "@/views/SingleOrder";
import Auth from "@/views/Auth";

import AuthCallback from "@/views/AuthCallback";
import store from "../store/store.js";
import ProductList from "@/views/ProductList";
import dropReport from "@/components/dropReport";
//import Login from "@/views/Login";


const routes = [
    {
        path: '/',
        name: 'Home',
        component: Orders,
        meta: {
            auth: true,
            layout: 'main',
            title: 'Замовлення | ТТН Генератор'
        }
    },

    {
        path: '/product-list',
        name: 'Products',
        component: ProductList,
        meta: {
            auth: true,
            layout: 'main',
            title: 'Товари | ТТН Генератор'
        }
    },

    {
        path: '/drop-report',
        name: 'Drop',
        component: dropReport,
        meta: {
            auth: true,
            layout: 'main',
            title: 'Звіт по дропшипінгу | ТТН Генератор'
        }
    },

    {
        path: '/order/:id',
        name: 'order',
        component: SingleOrder,
        meta: {
            auth: true,
            layout: 'main',
            title: 'Редагування замовлення | ТТН Генератор'
        }
    },

    {
        path: '/auth',
        name: 'auth',
        component: Auth,
        meta: {
            auth: false,
            layout: 'auth',
            title: 'Вхід | ТТН Генератор'
        }
    },

    {
        path: '/auth/callback',
        name: 'auth-callback',
        component: AuthCallback,
        meta: {
            auth: false,
            layout: 'auth'
        }
    },

/*    {
        path: '/users/:id',
        name: 'users',
        component: viewUser,
        meta: {
            auth: true,
            layout: 'main'
        }
    },*/

    /*    {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                auth: false,
                layout: 'auth'
            }
        },*/

]

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkExactActiveClass: 'active',
    linkActiveClass: 'active'
    }
);

router.beforeEach((to, from, next) => {
    const requireAuth = to.meta.auth

   // document.title = to.meta.title || 'ТТН Генератор';
    const defaultTitle = 'ТТН Генератор'; // Default title
     // Set title to metadata title or defaultTitle if metadata title does not exist
    document.title = to.meta.title || defaultTitle;

    if (requireAuth && store.getters['authModule/isAuth']) {
        next()
    } else if (requireAuth && !store.getters['authModule/isAuth']) {
        next('/auth?message=auth')
    } else {
        next()
    }
})

export default router
