<template>

</template>

<script>
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {onMounted} from "vue";
export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    let validToken = false; // Use ref for reactivity

    // Define the async function outside of onMounted
    async function validateToken() {
      try {
        const token = route.query.token; // Get token from route query
       // console.log("Token received:", token);
       let validationTokenRes = await store.dispatch('authModule/validationToken', token);
      // console.log('validationTokenRes', validationTokenRes);
        if(validationTokenRes.status === 'success'){
         // console.log('join in success')
          store.commit('authModule/saveToken', route.query.token);
          validToken = true;
        } else {
         // console.log('join in fail')
          await router.push('/auth');
        }
      } catch (error) {
        console.error("Error validating token:", error);
        validToken = false; // Set validToken to false if validation fails
      }
    }

    onMounted(() => {
      if(route.query.token) {
        // Call the validateToken function
        validateToken().then(() => {
          if (validToken) {
           // console.log("Token is valid, redirecting to home page")
            router.push('/');
          }
          else {
            router.push('/auth');
            console.log("Token is invalid, redirecting to login page")
          }
        });
      }
    });

    return {
      validToken
    };
  }

}


</script>

<style scoped>

</style>