import {createApp} from "vue";
import App from '@/App';
import router from "@/Router/router";
import store from '@/store/store';

import 'vue-multiselect/dist/vue-multiselect.css';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import VueVirtualScroller from 'vue-virtual-scroller'
import DraggableResizableVue from 'draggable-resizable-vue3'
import BootstrapVueNext from 'bootstrap-vue-next'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import axios from 'axios'

import apiCall from "@/axios/apiRequests.js";
import Echo from 'laravel-echo';
import './theme.css';

window.Pusher = require('pusher-js');


async function authorizeChannel(channel, socketId, options) {

  //  console.log('authorizeChannel', channel, socketId, options);
    return await apiCall('post', '/broadcasting/auth', {
        socket_id: socketId,
        channel_name: channel.name
    });
}

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_WEBSOCKETS_KEY,
    cluster: 'mt1',
    wsHost: process.env.VUE_APP_WS_URL,
    wsPort: 6001,
    wssPort: 443,
    forceTLS: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    authorizer: (channel, options) => {
        return {
            authorize: (socketId, callback) => {
                authorizeChannel(channel, socketId, options)
                    .then((response) => {
                        callback(false, response);
                    })
                    .catch((error) => {
                        console.log('auth error', error)
                        callback(true, error);
                    });
            }
        };
    }
});


// TODO без авторизації завжди підключається, треба лишя для авторизованих зробити
window.Echo.connector.pusher.connection.bind('connected', () => {
   // console.log('connected', window.Echo.socketId())
    axios.defaults.headers.common['X-Socket-ID'] = window.Echo.socketId();
    store.commit('authModule/setSocket', window.Echo.socketId())
});


// Create and mount the Vue app
const app = createApp(App);
app.use(router);
app.use(store);
app.use(DraggableResizableVue)
app.use(BootstrapVueNext)
app.use(VueVirtualScroller)

app.config.globalProperties.$axios = axios

app.mount('#app');

