<template>


  <BRow>

    <BInputGroup size="sm" prepend="Касса за замовчуванням">
      <template #append>
      <BDropdown
          :text="selectedKassaValue?.name ?? 'Касса за замовчуванням'"

          size="sm"

          boundary="viewport"
          container="body"
      >
        <BDropdownItem
            v-for="kassa in kassy"
            :key="kassa.id"
            :value="kassa.id"
            variant="outline-secondary"
            @click="selectDefaultKass(kassa)"
        >
          {{ kassa.name }}
        </BDropdownItem>
      </BDropdown>
      </template>
    </BInputGroup>

  </BRow>

</template>

<script>
/*import {watch, ref, computed} from "vue";

export default {
  name: "selectDefaultKassa",
  props: {
    kassy: Array,
    currentSourceKassaId: Number,
    defaultGlobalKassa: Number
  },

  emits: ['update:defaultKassaId'],

  setup(props, {emit}){

    const kassy = props.kassy
    const globalDefaultKassaid = props.defaultGlobalKassa


    console.log('kassy', kassy)
    console.log('selectedKassa', )
    const selectedKassaValue = ref({});

    const sourceKassaId = computed(() => props.currentSourceKassaId)


    watch(() => sourceKassaId, (newVal) => {
      console.log('newVal', newVal)
      console.log('selectedKassaValue', selectedKassaValue.value)
      console.log('globalDefaultKassaid', globalDefaultKassaid)
      selectedKassaValue.value = kassy.find(kassa => kassa.id === newVal)
    })



    function selectDefaultKass(kassa){
      console.log('kassa', kassa)
      selectedKassaValue.value = kassa
     // emit('update:defaultKassaId', id)

    }

    return {
      kassy,
      selectedKassaValue,
      selectDefaultKass
    }

  }



}*/

import {watch, ref, computed, onMounted} from "vue";

export default {
  name: "selectDefaultKassa",
  props: {
    kassy: Array,
    currentSourceKassaId: Number,
    defaultGlobalKassa: Number
  },
  emits: ['update:defaultKassaId'],
  setup(props, {emit}) {
    const kassy = props.kassy;
    const globalDefaultKassaid = props.defaultGlobalKassa;

    const selectedKassaValue = ref({});

    // Спостереження за зміною props.currentSourceKassaId
    watch(() => props.currentSourceKassaId, (newVal) => {
      console.log('newVal', newVal);
      console.log('selectedKassaValue', selectedKassaValue.value);
      console.log('globalDefaultKassaid', globalDefaultKassaid); // без .value

      if (newVal === null) {
        selectedKassaValue.value = kassy.find(kassa => kassa.id === globalDefaultKassaid);

      } else {
        selectedKassaValue.value = kassy.find(kassa => kassa.id === newVal);
      }
    });

    // Метод для вибору каси
    function selectDefaultKass(kassa) {
      console.log('kassa', kassa);
      selectedKassaValue.value = kassa;
      emit('update:defaultKassaId', kassa.id) // Включіть це, якщо потрібно емісити зміни
    }

    onMounted(() => {
      console.log('Component mounted');

      if (props.currentSourceKassaId === null) {
        selectedKassaValue.value = kassy.find(kassa => kassa.id === globalDefaultKassaid);
      } else {
        selectedKassaValue.value = kassy.find(kassa => kassa.id === props.currentSourceKassaId);
      }
    });


    return {
      kassy,
      selectedKassaValue,
      selectDefaultKass
    };
  }
};


</script>

<style scoped>

</style>