<template>
  <BModal  v-model="localModalStatus" hide-footer :title="modalTitle" @hide="handleHide">
    <BRow>
      <BCol lg="12">
        <p>{{ modalBody }}</p>
        <div class="d-flex justify-content-end">
          <b-button v-show="cancelButtonValue !== ''" variant="secondary" @click="cancel">{{ cancelButtonValue }}</b-button>
          <b-button variant="primary" class="ml-2" @click="confirm">{{ confirmButtonValue }}</b-button>
        </div>
      </BCol>
    </BRow>
  </BModal>
</template>

<script>
import { ref, watch } from 'vue';
import { BModal, BButton } from 'bootstrap-vue-next';

export default {
  name: "ConfirmModal",
  props: {
    modalStatus: Boolean,
    modalTitle: String,
    modalBody: String,
    confirmButtonValue: String,
    cancelButtonValue: String,
  },
  setup(props, { emit }) {
    const localModalStatus = ref(props.modalStatus);

    watch(() => props.modalStatus, (newStatus) => {
      localModalStatus.value = newStatus;
    });

    const updateModalStatus = (newValue) => {
      emit('update:closeConfirmModal', newValue);
    };

    const confirm = () => {
      emit('update:userConfirmation', true);
      updateModalStatus(false);
    };

    const cancel = () => {
      emit('update:userConfirmation', false);
      updateModalStatus(false);
    };

    const handleHide = () => {
      updateModalStatus(false);
    };

    return {
      localModalStatus,
      confirm,
      cancel,
      handleHide
    };
  },
  components: {
    BModal,
    BButton
  },
};
</script>


<!--
<template>
  <BModal v-model="localModalStatus" hide-footer :title="modalTitle">
    <BRow>
      <BCol lg="12">
        <p>{{ modalBody }}</p>
        <div class="d-flex justify-content-end">
          <b-button v-show="cancelButtonValue !== ''" variant="secondary" @click="cancel">{{ cancelButtonValue }}</b-button>
          <b-button variant="primary" class="ml-2" @click="confirm">{{ confirmButtonValue }}</b-button>
        </div>
      </BCol>
    </BRow>
  </BModal>
</template>

<script>
import { ref, watch } from 'vue';
import { BModal, BButton } from 'bootstrap-vue-next';

export default {
  name: "ConfirmModal",
  props: {
    modalStatus: Boolean,
    modalTitle: String,
    modalBody: String,
    confirmButtonValue: String,
    cancelButtonValue: String,
  },
  setup(props, { emit }) {
    const localModalStatus = ref(props.modalStatus);

    // Watch for external changes to modalStatus prop
    watch(() => props.modalStatus, (newStatus) => {
      localModalStatus.value = newStatus;
    });

    const updateModalStatus = (newValue) => {
      emit('update:modalStatus', newValue);
    };

    const confirm = () => {
      emit('update:userConfirmation', true);
      updateModalStatus(false);
    };

    const cancel = () => {
      emit('update:userConfirmation', false);
      updateModalStatus(false);
    };

    return {
      localModalStatus,
      confirm,
      cancel,
      updateModalStatus
    };
  },
  components: {
    BModal,
    BButton
  },
};
</script>
-->


<!--<template>
    <BModal  v-model="localModalStatus" hide-footer :title="modalTitle">
      <BRow>
        <BCol lg="12">
          <p> {{ modalBody }}</p>
          <div class="d-flex justify-content-end">
            <b-button variant="secondary" @click="cancel">{{ cancelButtonValue }}</b-button>
            <b-button variant="primary" class="ml-2" @click="confirm">{{ confirmButtonValue }}</b-button>
          </div>
        </BCol>
      </BRow>
    </BModal>
</template>



<script>
import {ref, toRefs, watch} from 'vue';
import { BModal, BButton } from 'bootstrap-vue-next';

export default {
  name: "ConfirmModal",
  props: {
    modalStatus: Boolean,
    modalTitle: String,
    modalBody: String,
    confirmButtonValue: String,
    cancelButtonValue: String,
  },
  setup(props, { emit }) {
   const localModalStatus = ref(props.modalStatus);

   watch(localModalStatus, (newVal) => {
     console.log('newVal', newVal)

    });

    const updateModalStatus = (newValue) => {
      emit('update:modalStatus', newValue);
    };

    const confirm = () => {
      emit('update:userConfirmation', true);
      updateModalStatus(false);
    };

    const cancel = () => {
      emit('update:userConfirmation', false);
      updateModalStatus(false);
    };

    return {
     localModalStatus,
      confirm,
      cancel,
      updateModalStatus
    };
  },
  components: {
    BModal,
    BButton
  },
};
</script>-->
<!--<script>
import {BModal, BButton} from 'bootstrap-vue-next';
import {ref, watch} from 'vue';

export default {
  name: "ConfirmModal",

  props: {
    modalStatus: {
      type: Boolean,
      default: false
    },
    modalTitle: {
      type: String,
      default: 'Підтвердіть дію'
    },
    modalBody: {
      type: String,
      default: ''
    },
    confirmButtonValue: {
      type: String,
      default: 'Друкувати'
    },
    cancelButtonValue: {
      type: String,
      default: 'Відміна'
    }
  },

  emits: ['update:userConfirmation', 'update:modalStatus'],

  setup(props, {emit}) {
    // Local copy of the modal status
    const localModalStatus = ref(props.modalStatus);

    // Watch for changes in the prop and update the local copy
    watch(() => props.modalStatus, (newVal) => {
      localModalStatus.value = newVal;
    });

    // Emit an event when the local modal status changes
    watch(localModalStatus, (newVal) => {
      emit('update:modalStatus', newVal);
    });

    const confirm = () => {
      localModalStatus.value = false;
      console.log('confirm')
      emit('update:userConfirmation', true);
    };

    const cancel = () => {
      console.log('cancel')
      localModalStatus.value = false;
      emit('update:userConfirmation', false);
    };

    return {
      localModalStatus,
      confirm,
      cancel
    };
  },

  components: {
    BModal,
    BButton
  },
};
</script>-->

<!--
<template>
  <div>
    <b-modal v-show="modalStatus" hide-footer :title="modalTitle">
      <div class="d-flex justify-content-end">
        <b-button variant="secondary" @click="cancel">Cancel</b-button>
        <b-button variant="primary" class="ml-2" @click="confirm">Confirm</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>

import {BModal, BButton} from 'bootstrap-vue-next';

export default {
  name: "ConfirmModal",

  props: {
    modalStatus: {
      type: Boolean,
      default: false
    },
    modalTitle: {
      type: String,
      default: 'Підтвердіть дію'
    }
  },

  emits: ['update:userConfirmation'],

  setup(props, {emit}) {

    console.log('props', props)

   // const modalVisible = props.modalVisible
   // const modalTitle = props.modalTitle

    const confirm = () => {
     // modalVisible.value = false
      emit('update:userConfirmation', true)
    }
    const cancel = () => {
    //  modalVisible.value = false
      emit('update:userConfirmation', false)
    }
    return {
     // modalVisible,
      confirm,
    //  modalTitle,
      cancel
    }
  },


  components: {
    BModal,
    BButton
  },

}



</script>

-->
