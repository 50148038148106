<template>
  <div class="d-flex align-items-center">
    <!-- Випадаючий список "Потрібна допомога?" -->
    <BDropdown
        text="Потрібна допомога?"
        variant="link"
        right
        class="me-2"
        size="sm"
        toggle-class="text-danger"

    >

      <BDropdownItem href="viber://chat?number=%2B380938507911" target="_blank">
        Зв'язатися через Viber
      </BDropdownItem>

      <BDropdownItem href="https://t.me/emlax69" target="_blank">
        Зв'язатися через Telegram
      </BDropdownItem>

    </BDropdown>


  </div>

</template>

<script>

import { BDropdown, BDropdownItem, BCloseButton } from 'bootstrap-vue-next'

export default {
  name: "SupportButtonDropdown",

  components: {
    BDropdown,
    BDropdownItem,
    BCloseButton
  },

}



</script>

<style scoped>

</style>