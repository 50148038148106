import {createLogger, createStore} from "vuex";
import modalOrderWindow from "@/store/modules/modalOrderWindow";
import order from "@/store/modules/order";

import authModule from "@/store/modules/authModule";
import alert from "@/store/modules/alert";
import changeOrderStatusByTtn from "@/store/modules/changeOrderStatusByTtn";
import callInfoModule from "@/store/modules/callInfoModule";


const plugins = [];

if(process.env.NODE_ENV === 'development') {
    //plugins.push(createLogger())
}

export default createStore({
    plugins,

    modules: {
        order,
        modalOrderWindow,
        authModule,
        alert,
        changeOrderStatusByTtn,
        callInfoModule,
    }
})
