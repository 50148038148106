<template>

  <BModal v-model="localModalStatus" size="lg" scrollable noCloseOnBackdrop noCloseOnEsc :title="modalTitle"
          @hide="closePackingModal" class="p-3"
          @ok="saveChanges"
          cancelTitle="Закрити без змін"
          okTitle="Зберегти"
  >

    <!--    <h5> {{ order.SourceType}}</h5>
        <p> {{ order.deliveryData.PostName }} - {{ order.deliveryData.TtnNumber }}</p>-->
    <p>Одержувач {{ order.deliveryData.DeliveryFio }}</p>
    <p v-if="notIsPaid != null"> Без післяплати - {{ notIsPaid }} </p>
    <p v-if="order.orderComment !== null">КОМЕНТАР {{ order.orderComment }}</p>

    <b-card v-for="item in order.products" :key="item.product_id" class="product-block" :variant="buttonVariant(item.product_id)">
      <div class="d-flex pointing" :id="'heading-' + item.product_id" v-b-toggle="'collapse-' + item.product_id">
        <h5>
          {{ item.product.name }} ({{ item.product.article }})
        </h5>
        <BButton size="sm"  variant="info">
          {{ buttonVariant(item.product_id) === 'success' ? 'Упаковано' : 'Відкрити' }}
        </BButton>
      </div>
      <BCollapse :id="'collapse-' + item.product_id">

        <div class="desktop-packing-details">
          <div class="img">
            <BImg  :src="item.product.thumbnail_url" alt=""/>
          </div>
          <div>
            <div>
              <h3>Кількість: {{ item.quantity }} шт.</h3>
              <p>Доступний залишок: <b>{{ item.product.stock.stock_qty }} шт.</b></p>
              <p v-show="item.product.weight !== 0">Вага 1 одиниці: {{ item.product.weight }}</p>
            </div>
            <div v-b-toggle="'collapse-' + item.product_id">
              <BButton
                  v-if="buttonVariant(item.product_id) !== 'success'"
                  variant="primary"

                       class="mt-2"
                       @click="setItemAsPacked(item)"
              >
                Помітити як упакований
              </BButton>
            </div>
          </div>
        </div>
      </BCollapse>
    </b-card>

  </BModal>
</template>
<script>
import {
  BButton, BCard,
  BDropdown,
  BDropdownItem,
  BFormCheckbox, BFormInput, BFormInvalidFeedback,
  BFormSelect, BFormText,
  BFormTextarea,
  BInputGroup,
  BModal, BSpinner, BCollapse
} from 'bootstrap-vue-next';
import {computed, ref, watch} from "vue";
import {updatePackingStatus} from "@/axios/settignsRequests";

export default {
  name: "PackingModal",

  props: {
    localModalStatus: Boolean,
    order: {
      type: Object,
      required: true,
    },
  },

  setup(props, {emit}) {
    const localModalStatus = ref(props.localModalStatus);
    const order = computed(() => props.order);
    const modalTitle = order.value.SourceType + ' ' + order.value.deliveryData.PostName + ' ' + order.value.deliveryData.TtnNumber
    console.log('order', order.value);
    const packedProducts = ref([]);
    const collapseStates = ref({});

    fillPackedProducts()

    const closePackingModal = () => {
      emit('update:localModalStatus', false);
    }

    const notIsPaid = ref(getPaidStatus(order.value));

    function getPaidStatus(order) {
      if (order.PaymentTypeNameId === 4 && order.is_paid === 0) {
        return 'Не cплачено';
      } else {
        return null;
      }
    }

    const isProductPacked = (productId) => {
      return packedProducts.value.includes(productId);
    };

    const buttonVariant = (productId) => {
      return isProductPacked(productId) ? 'success' : 'outline-secondary';
    };

    let packed_ordered_products_ids = [];

    const setItemAsPacked = (item) => {
      const productId = item.product_id;
      if (!isProductPacked(productId)) {
        packedProducts.value.push(productId);
        packed_ordered_products_ids.push(item.id);
      }
      console.log('packed_ordered_products_ids', packed_ordered_products_ids);
    };

    function fillPackedProducts() {
      order.value.products.forEach((item) => {
        if (item.is_packed) {
          packedProducts.value.push(item.product_id);
        }
      });
    }

    function saveChanges() {
      const data = {
        orderedProductsIds: packed_ordered_products_ids,
        orderId: order.value.crm_order_id
      };

      updatePackingStatus(data)
          .then((res) => {
            console.log('saveChanges fired', res);
            closePackingModal();
          })
          .catch((error) => {
            const key = 'unsavedPackingData';
            const existingData = localStorage.getItem(key);

            // Parse the existing data or initialize a new object if none exists
            const unsavedData = existingData ? JSON.parse(existingData) : {};

            // Add or update the entry for this order
            unsavedData[order.value.crm_order_id] = data;

            // Serialize the updated data object and save it back to localStorage
            localStorage.setItem(key, JSON.stringify(unsavedData));

            console.log('Error saving changes: ', error);
          });

      console.log('saveChanges fired', packed_ordered_products_ids);
    }



    return {
      collapseStates,
      notIsPaid,
      modalTitle,
      localModalStatus,
      order,
      closePackingModal,
      setItemAsPacked,
      buttonVariant,
      saveChanges
    }
  },

  components: {
    BModal,
    BFormTextarea,
    BFormCheckbox,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BInputGroup,
    BCard,
    BFormInput,
    BSpinner,
    BFormText,
    BFormInvalidFeedback,
    BCollapse
  },

}


</script>

<style scoped>


@media (min-width: 768px) {
  /* Desktops and larger devices */
  .img img {
    width: 300px;
    max-width: 300px;
  }
}




@media (min-width: 768px) { /* Desktops and larger devices */
  .desktop-packing-details {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}




</style>