//import axios from "axios";
import apiCall from "@/axios/apiRequests";
import {getCustomSources, getOrdersDataForCallsHostoryByPhone, getSenderSettings} from "@/axios/settignsRequests";
import {ref} from "vue";


export default {
    namespaced: true,
    state() {
        return {
            orders: {
                0: [],
                1: [],
                2: [],
                3: [],
                4: [],
                5: [],
                6: [],
                7: [],
                8: [],
                9: [],
                10: [],
                11: [],
                12: [],
                13: [],
                14: [],
            },

            OrderStatusesCount: {
                0: 0,
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
                13: 0,
                14: 0,
            },

            isAdmin: false,

            senderAddressesNp: {},

            url: process.env.VUE_APP_LARAVEL_APP_URL,

            orderStatuses: {
                1: {id: 1, name: 'Новий'},
                2: {id: 2, name: 'Очікує відправку'},
                3: {id: 3, name: 'Очікує предоплату'},
                4: {id: 4, name: 'У дорозі з наложкою'},
                5: {id: 5, name: 'У дорозі без наложки'},
                6: {id: 6, name: 'Зберігання/відмови'},
                7: {id: 7, name: 'Виконані'},
                8: {id: 8, name: 'Не успішні'},
                9: {id: 9, name: 'Очікує наявності'},
                10: {id: 10, name: 'Одержали повернення'},
                11: {id: 11, name: 'На комплектації'},
                12: {id: 12, name: 'Брак'},
                13: {id: 12, name: 'Усі замовлення'},
                14: {id: 14, name: 'Видалені'},
            },

            deliveryInfoAssociatedTypes: {
                1: 'Нова Пошта',
                2: 'Укрпошта',
                3: 'Розетка Дел.',
                4: 'Delivery',
                5: 'Sat',
                6: 'Самовивіз',
                7: 'Доставка по місту',
                8: 'Інші',
                9: 'Meest',
            },

            senderSettings: {},

            paymentTypes: {
                2: {id: 2, name: 'Грошовий переказ', shortName: 'Грошовий переказ'},
                3: {id: 3, name: 'Контроль оплати', shortName: 'Контроль оплати'},
                4: {id: 4, name: 'Предоплата (без наложки)', shortName: 'Предоплата'},
                5: {id: 5, name: 'Готівка(самовивіз)', shortName: 'Готівка(самовивіз)'},
            },

            deliveryPayerTypes: {
                3: {id: 3, name: 'Відправник'},
                4: {id: 4, name: 'Одержувач'}
            },

            userData: {
                'sip_number': null,
                'is_admin': '',
                'name': '',
                'dropship_type': '',
                'ip_telephony': false,
                'source_name': '',
                'source_id': '',
                'source_type': '',
            },

            customSources: [],

        }
    },

    mutations: {


        saveOrders(state, {orders, ordersStatusId}) {

            state.orders[ordersStatusId] = orders

            // console.log('state.orders', state.orders);

            // localStorage.setItem('orders', JSON.stringify(state.orders));

        },


        loadLocalOrders(state) {
            const storedOrders = localStorage.getItem('orders');
            if (storedOrders) {
                state.orders = JSON.parse(storedOrders);
            }
        },

        setOrdersStatusesCount(state, {ordersStatusId, ordersCount}) {

            state.OrderStatusesCount[ordersStatusId] = ordersCount
        },

        saveSenderSettingsToStore(state, {senderSettingsResponse}) {
            state.senderSettings = senderSettingsResponse
        },

        addOrder(state, payload) {
            state.orders[payload.CrmOrderStatusId - 1] = [payload, ...state.orders[payload.CrmOrderStatusId - 1]];
            //  localStorage.setItem('orders', JSON.stringify(state.orders));
        },
        updateOrderById(state, orderFromFront) {

            if (orderFromFront.crm_order_id) {
                let orderToUpdate = state.orders[orderFromFront.CrmOrderStatusId - 1].find(order => order.crm_order_id === orderFromFront.crm_order_id);
                if (orderToUpdate) {

                    Object.assign(orderToUpdate, orderFromFront);
                } else { // addOrder

                    state.orders[orderFromFront.CrmOrderStatusId - 1] = [orderFromFront, ...state.orders[orderFromFront.CrmOrderStatusId - 1]];

                }
            }

            // localStorage.setItem('orders', JSON.stringify(state.orders));

        },
        deleteOrder(state, {crmOrderId, orderStatusId}) {
            // console.log('deleteOrder crmOrderId', crmOrderId);
            //  console.log('deleteOrder orderStatusId', orderStatusId);
         /*   state.orders[orderStatusId] = state.orders[orderStatusId].filter(order => // order.js:166 Uncaught (in promise) TypeError: Cannot read properties of undefined (reading 'includes')
                 !crmOrderId.includes(order.crm_order_id));
         */
            //  localStorage.setItem('orders', JSON.stringify(state.orders));

        console.log('crmOrderIdasd', crmOrderId)

            if (state.orders[orderStatusId]) {
                state.orders[orderStatusId] = state.orders[orderStatusId].filter(order =>
                    !crmOrderId.includes(order.crm_order_id)
                );
            }


        },




        setTtnNumbersByFullInfoId(state, ordersFromAPI) {
            console.log('ordersFromAPI', ordersFromAPI);
            Object.values(ordersFromAPI).forEach(orderFromAPI => {
                console.log('orderFromAPI', orderFromAPI);
                let orderToUpdate = state.orders[1].find(order => order.full_info_id === orderFromAPI.full_info_id);
                if (orderToUpdate) {
                    orderToUpdate.deliveryData.TtnNumber = orderFromAPI.number_ttn ?? orderFromAPI.api_errors;
                }
            });
        },



        updateCheckData(state, data) {
            console.log('ordersFromAPI', data);
            const ordersFromAPI = data.ordersDataFromAPI;
            ordersFromAPI.forEach(orderFromAPI => {
                let orderToUpdate = state.orders[data.activeTab].find(order => order.crm_order_id === orderFromAPI.order_id);
                if (orderToUpdate) {
                    orderToUpdate.check_data = orderFromAPI;
                }
            });
        },

        updateCheckPrintStatus(state, data) {

            console.log('data', data);

            const ordersFromAPI = data.ordersDataFromAPI;
            ordersFromAPI.forEach(orderFromAPI => {
                console.log('orderFromAPI', orderFromAPI);
                let orderToUpdate = state.orders[data.activeTab].find(order => order.crm_order_id === orderFromAPI);
                if (orderToUpdate) {
                    orderToUpdate.check_data.CheckActions = 'Надруковано';
                }
            });
        },


        // array [
        //     {
        //         "ttn_number": "20450857078624",
        //         "reestr_id": "105-49912440",
        //         "error_message": null
        //     }
        // ]
        setReestrNumbersByTtnNumber(state, ttns_numberAndReestr_id) {
            console.log('ttns_numberAndReestr_id', ttns_numberAndReestr_id);
            ttns_numberAndReestr_id.forEach(ttn => {
                console.log('ttnNumber.ttn_number', ttn.ttn_number);
                let orderToUpdate = state.orders[1].find(order => {
                    console.log('order.deliveryData?.TtnNumber', order.deliveryData?.TtnNumber);
                    return order.deliveryData?.TtnNumber == ttn.ttn_number // тип данних НЕ ЗМІНЮВАТИ
                });

                console.log('orderToUpdate', orderToUpdate);

                if (orderToUpdate) { //  && orderToUpdate.deliveryData.ReestrNumber !== null

                    if (ttn.reestr_id !== null) {
                        orderToUpdate.deliveryData.ReestrNumber = ttn.reestr_id;
                    } else {
                        orderToUpdate.deliveryData.ReestrNumber = ttn.error_message;
                    }
                }

            });

            //  localStorage.setItem('orders', JSON.stringify(state.orders));

        },
        setTtnsAsPrinted(state, orderIds) {
            orderIds.map(orderId => {
                let orderToUpdate = state.orders[1].find(order => order.crm_order_id === orderId);
                if (orderToUpdate) {
                    orderToUpdate.deliveryData.TtnPrintedStatus = 'Так';
                }
            });

            //  localStorage.setItem('orders', JSON.stringify(state.orders));

        },
        setReestrPrinted(state, uniqueReestrNumbers) {

            uniqueReestrNumbers.map(reestrNumber => {
                let orderToUpdate = state.orders[1].find(order => order.deliveryData?.ReestrNumber === reestrNumber);
                if (orderToUpdate) {
                    orderToUpdate.deliveryData.ReestrPrintedStatus = 'Так';
                }
            });

            //   localStorage.setItem('orders', JSON.stringify(state.orders));

        },
        // payload = {1234: { // crm_order_id
        // 'smsSendStatus': 'smsSendStatus',
        // 'smsTemplateName': 'smsTemplateName',
        // }}
        setSmsSentStatus(state, payload) {
            // if payload Object.key === crm_order_id, then set smsSentStatus = message
            payload.forEach(smsErorrResponse => {

                let orderToUpdate = state.orders[1].find(order => order.crm_order_id === smsErorrResponse.order_id);
                if (orderToUpdate) {
                    // console.log('orderToUpdate', orderToUpdate);
                    orderToUpdate.smsHistory.message_value = smsErorrResponse.message_value;
                    orderToUpdate.smsHistory.sms_template_name = smsErorrResponse.sms_template_name;
                }
            });

            //  localStorage.setItem('orders', JSON.stringify(state.orders));

        },
        saveSenderAddressesNpToStore(state, payload) {
            // console.log('saving addresses to store', payload);
            state.senderAddressesNp = payload;
            // console.log('state.senderAddressesNp', state.senderAddressesNp);
        },
        saveIsMainUserToStore(state, payload) {
            state.isAdmin = payload;
        },

        // save userData to store
        saveUserDataToStore(state, payload) {
            state.userData = payload;
        },

        setCustomSources(state, payload) {
            state.customSources = payload;

        },


        /*    phoneNumbers =  [
            "995138401",
            "500815068",
            "679667963",
            "963032875",
            "635451869",
            "951936249",
            "985783693",
            "980338181",
            "506414633",
            "631472516",
            "981104309",
            "953897423",
            "674546303",
            "502997340"
        ]

        allOrders = [
    {
        "crm_order_id": 57033,
        "BuyerPhone": 504073432,

    },
     {
        "crm_order_id": 57034,
        "BuyerPhone": 504073434,

    }
]

        */



        /*      setFilteredOrders(state, { allOrders, phoneNumbers }) {

                  let filteredOrders = allOrders.filter(order => phoneNumbers.includes(order.BuyerPhone));

                  console.log('findedOrders', filteredOrders);

              }*/


        setFilteredOrders(state, {allOrders, formattedPhoneNumbers}) {

        }


    },

    actions: {

        /*     async updateCreateOrders({ commit, dispatch, state }, orderFromFront) {
                     console.log('action updateOrderById', orderFromFront);
                     let message = '';
                     if (orderFromFront.crm_order_id) {
                         let orderToUpdate = state.orders[orderFromFront.CrmOrderStatusId - 1].find(order => order.crm_order_id === orderFromFront.crm_order_id);
                         if (orderToUpdate) {
                             console.log('updateOrderById orderToUpdate', orderToUpdate);
                             // Use commit to call the mutation
                             Object.assign(orderToUpdate, orderFromFront);
                             //commit('mutationToUpdateOrder', orderFromFront); // Adjust this to your mutation name and payload
                             message = 'Замовлення було оновлено';
                         } else {
                             console.log('addOrder');
                             // Directly call the mutation via commit here as well
                             commit('addOrder', orderFromFront);
                             message = 'Замовлення було створено';
                         }
                     }
                     // Dispatch another action
                     dispatch('alert/triggerAlert', {
                         show: 3000,
                         variant: 'success',
                         message: message
                     }, { root: true });
                 },
      */

        // TODO тут я зберігаю відповідь у різних місцях, локалстор, сторе..треба у одному місці переробити
        async senderSettings({commit}) {
            let response = await getSenderSettings()


            let userData = {
                'is_admin': response.admin,
                'sip_number': response.sip_id,
                'name': response.name,
                'dropship_type': response.dropship_type,
                'ip_telephony': response.sip_id !== null,
                'host': response.ami_settings?.host,
                'source_name': response.source_name,
                'source_type': response.source_type,
                'source_id': response.source_id,
            }

            commit('saveUserDataToStore', userData);
            commit('authModule/setUuid', response.uuid, {root: true});

            localStorage.setItem('senderSettings', JSON.stringify([]));
            localStorage.setItem('npSenders', JSON.stringify(formatNpSenders(response.delivery_counterparties)));
            localStorage.setItem('dropShipType', JSON.stringify(response.dropship_type));
            localStorage.setItem('sms_templates', JSON.stringify(response.sms_templates));
            localStorage.setItem('ukrPostSenders', JSON.stringify(response.urk_post_sender_data));
            localStorage.setItem('userData', JSON.stringify(userData));
            localStorage.setItem('stockNames', JSON.stringify(response.stock_names));
            localStorage.setItem('price_types', JSON.stringify(response.price_types));

            if (response.admin === 'true') {
                const isAdmin = true;
                commit('saveIsMainUserToStore', isAdmin);
            }

            if (response.sender_settings !== null) {
                localStorage.setItem('senderSettings', JSON.stringify(response.sender_settings));
                commit('saveSenderSettingsToStore', response.sender_settings)
            }
            let senderAddressesNp = {};

            response.sender_addresses_np.forEach((sender) => {
                senderAddressesNp[sender.warehouse_id] = {
                    'is_default': sender.is_default === 1,
                    'id': sender.warehouse_id,
                    'name': sender.np_warehouses.np_cities.name + ', №' + sender.np_warehouses.number,
                }
            });

            //console.log('senderAddressesNp', senderAddressesNp);

            commit('saveSenderAddressesNpToStore', senderAddressesNp);
            // save senderAddressesNp to local storage
            localStorage.setItem('senderAddressesNp', JSON.stringify(senderAddressesNp));


            function formatNpSenders(npSendersArray) {

                return npSendersArray.flatMap(sender =>
                    sender.contact_persons.map(person => ({
                        name: person.name,
                        contact_person_id: person.id,
                        non_cash: sender.non_cash,
                        payment_control: sender.payment_control,
                        payment_method_id: person.payment_method_id,
                        redelivery_payer: person.types_payers_redelivery_id,
                        default_sender: sender.default_sender,
                        defaultContactPerson: person.defaultContactPerson,
                    }))
                );
            }

            return response;

        },

        async printmarkingsRequest({commit, rootGetters}, payload) {
            const response = await apiCall('post', `/printmarkings`, payload);
            const printWindow = window.open('', '_blank');
            printWindow.document.write(response);
            printWindow.document.close();
            if (response) {
                commit('setTtnsAsPrinted', payload.ordersIds);
            }
        },

        async sendRequestWithBulkSMSWithTTns({commit, dispatch, rootGetters}, payload) {
            const response = await apiCall('post', '/send_ttn', payload);
            dispatch('alert/triggerAlert', {
                show: 3000,
                variant: response.status === 'success' ? 'success' : 'danger',
                message: response.message
            }, {root: true});
            console.log('bulk sms response', response);
            return response;
        },

        async dropOrdersRequest({commit, dispatch, rootGetters}, {orderIds, orderStatusId}) {
            //  console.log('payload1', orderIds.ordersIds);
            commit('deleteOrder', {crmOrderId: orderIds.ordersIds, orderStatusId});
            const response = await apiCall('post', '/drop-orders', orderIds);

            dispatch('alert/triggerAlert', {
                show: 3000,
                variant: response.status === 'success' ? 'success' : 'danger',
                message: response.message
            }, {root: true});

            return response;
        },

        async getOrders({state, commit, rootGetters}, ordersStatusId) {
            const fetchedOrders = await apiCall('get', `/orders?status=${ordersStatusId + 1}`);

          //  console.log('fetchedOrders', fetchedOrders);

            commit('saveOrders', {orders: fetchedOrders, ordersStatusId}); // this works fine with array

            return fetchedOrders.length;

            // якщо треба з ключом по статусу ід, то нижче код
            /*       Object.keys(fetchedOrders).forEach(key => {
                       let tab = parseInt(key, 10) - 1;
                       commit('saveOrders', {orders: fetchedOrders[key], ordersStatusId: tab});
                   });*/

            /*    Object.keys(fetchedOrders).forEach(key => { // this does not works with object and keys. orders
                    console.log('orders', fetchedOrders[key]);
                    console.log('key', key);
                    const tab = (key - 1);
                    console.log('tab', tab);
                    commit('saveOrders', {orders: fetchedOrders[key], tab });
                });*/

            // console.log('orders saved to store', state.orders);

        },

        async createUpdateOrder({commit, dispatch, rootGetters}, {finalForm, changedOrderForFront}) {

            const response = await apiCall('post', '/orders', finalForm);

            // console.log('response create order', response);

            dispatch('alert/triggerAlert', {
                show: 3000,
                variant: response.status === 'success' ? 'success' : 'danger',
                message: response.message
            }, {root: true});


            // закоментував через помилку в ключі
            if (changedOrderForFront !== null) {
                if (changedOrderForFront.savingType === 'update') {
                    console.log('changedOrderForFront update', changedOrderForFront)
                    commit('updateOrderById', changedOrderForFront);
                } else {


                    const createdOrder = response.orderForCreateUpdateAtFront;
/*
                    const createdOrderIdKey = Object.keys(response.createdOrdersIds)[0];
                    const createdOrder = response.createdOrdersIds[createdOrderIdKey];

                    changedOrderForFront.crm_order_id = createdOrder.crm_order_id;
                    changedOrderForFront.full_info_id = response.fullInfoIds;
                    changedOrderForFront.UsersOrderNumber = createdOrder.users_order_number;
                    changedOrderForFront.senderUphoneKey = createdOrder.sender_uphone_key;
                    changedOrderForFront.sourceData = {OrderNumber: createdOrder.users_order_number}; // Assuming you need to define sourceData
                    changedOrderForFront.seatsAmounts = response.seatAmounts;
                    changedOrderForFront.packing_status = 'Не упаковано';

                    commit('addOrder', changedOrderForFront);*/

                    commit('addOrder', createdOrder);

                }
            }
            return response;
        },

        async sendSms({commit, rootGetters}, payload) {
            return await apiCall('post', '/send_sinle_sms', payload);
        },


        async changeOrderStatuses({commit, dispatch, rootGetters}, {requestChangeStatus, activeTab}) {
            //console.log('requestChangeStatus1', requestChangeStatus);
            //console.log('activeTab', activeTab);
            const response = await apiCall('post', '/change-orders-statuses', requestChangeStatus);
            // console.log('response', response);
            if (response.status === 'success') {
                // console.log('payload.ordersIds', requestChangeStatus.ordersIds);
                commit('deleteOrder', {crmOrderId: requestChangeStatus.ordersIds, orderStatusId: activeTab});
            }

            dispatch('alert/triggerAlert', {
                show: 3000,
                variant: response.status === 'success' ? 'success' : 'danger',
                message: response.message
            }, {root: true});
            return response;
        },

        async getOrderStatusesCount({commit}) {
            let res = await apiCall('get', '/orders-statuses-count');
            // console.log('res', res);

            Object.keys(res).forEach(statusId => {
                commit('setOrdersStatusesCount', {ordersStatusId: statusId, ordersCount: res[statusId]});
            }, this);

            // commit('setOrdersStatusesCount', res);
            return res;
        },


        async loadPaymentsByDate({commit, rootGetters}, payload) {
            return await apiCall('get', '/payments-by-date?date=' + payload);
        },

        async createTtnsRequest({commit, rootGetters}, payload) {
            return await apiCall('post', '/createnpttn', payload);
        },

        async addToReestr({commit, rootGetters}, payload) {
            return await apiCall('post', '/createnpreestr', payload);
        },

        async createReturn({commit, rootGetters}, payload) {
            return await apiCall('post', '/createreturnttn', payload);
        },

        async printReestrRequest({rootGetters}, payload) {
            const response = await apiCall('post', `/print-reestrs`, payload, 'blob');
            const file = new Blob([response.data], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        },

        async changeOrderStatusByNumbers({commit,dispatch }, payload) {

            const response = await apiCall('post', '/change-order-statuses-by-numbers', payload);
            if (response.status === 'success') {
                // console.log('payload.', response);
                let orderStatusId = payload.numberType === 'ttn' ? 4 : 5; // швидке рішення, якщо тип номерів це ТТН, тоді
                // статус замовлення 4(з наложкою), якщо це номери замовлень, тоді 5 (без наложки)
                commit('deleteOrder', {crmOrderId: response.successedOrdersIda, orderStatusId});
                return response;
            } else {
                dispatch('alert/triggerAlert', {
                    show: 3000,
                    variant: 'danger',
                    message: response.message
                }, {root: true});
                return null;
            }
        },

        async getCustomSources({commit}) {
            const response = await getCustomSources('get', '/custom-sources');


            let totalSourcesForStore = {};
            const sourceTypes = {
                'insta_cabinets': 'inst',
                'telega_cabinets': 'tg',
                'fb_cabinets': 'fb',
                'opencart_cabinets': 'oc',
                'prom_source_import_type': 'prom',
                'horoshop_cabinet_settings_model': 'horoshop',
                'wp_cabinets': 'wp',
                'rozetka_cab_settings': 'rozetka',
                'other_crms_cabinets': 'crms',
                'hubber_cabinets': 'hubber',
                'other_sources_cabinets': 'other'
            };

            Object.keys(sourceTypes).forEach(sourceType => {
                if (response[sourceType] && response[sourceType].length > 0) {
                    response[sourceType].forEach(source => {
                        // Створення ключа з `sourceType` та `id`
                        const key = `${sourceTypes[sourceType]}${source.id}`;

                        // Додавання об'єкта до `totalSourcesForStore` з використанням створеного ключа
                        totalSourcesForStore[key] = {
                            ...source,
                            sourceType: sourceTypes[sourceType] // Додаємо sourceType для зручності
                        };
                    });
                }
            });


            commit('setCustomSources', totalSourcesForStore);
        },


        /*    findExitedOrdersByPhoneNumbers({getters, commit}, phoneNumbers) {



                const formattedPhoneNumbers = phoneNumbers.map(phone => parseInt(phone.slice(-9), 10));

               // console.log('formattedPhoneNumbers', formattedPhoneNumbers);

                const allOrders = getters.getAllOrders;
               // console.log('allOrders', allOrders);
               // console.log('formattedPhoneNumbers', formattedPhoneNumbers);
                const phoneNumberSet = new Set(formattedPhoneNumbers);
                const filteredOrders = [];
                const existingPhoneNumbers = new Set();

                const relatedOrdersDataToPhoneNumbers = {};

                allOrders.forEach(order => {
                    const phoneNumber = order.BuyerPhone;
                    if (phoneNumberSet.has(phoneNumber)) {
                        filteredOrders.push(order);
                        existingPhoneNumbers.add(phoneNumber);
                    }
                });

                const nonExistentPhoneNumbers = formattedPhoneNumbers.filter(phoneNumber => !existingPhoneNumbers.has(phoneNumber));

                console.log('filteredOrders', filteredOrders);
              //  console.log('nonExistentPhoneNumbers', nonExistentPhoneNumbers);


                if (filteredOrders.length > 0) {
                    iterateOrders(filteredOrders)
                }


                if (nonExistentPhoneNumbers.length > 0) {
                    // Send request to db to get orders by phone numbers
                    getOrdersDataForCallsHostoryByPhone({phoneNumbers: nonExistentPhoneNumbers}).then(response => {

                        if (response.status === 'success' && response.data.length > 0) {
                            let ordersByOrderStatusId = {};

                            response.data.forEach(order => {
                                const phoneNumber = order.BuyerPhone;
                                relatedOrdersDataToPhoneNumbers[phoneNumber] = order;

                                // Initialize the array if it doesn't exist
                                if (!ordersByOrderStatusId[order.CrmOrderStatusId]) {
                                    ordersByOrderStatusId[order.CrmOrderStatusId] = [];
                                }

                                ordersByOrderStatusId[order.CrmOrderStatusId].push(order);
                            });

                            Object.entries(ordersByOrderStatusId).forEach(([ordersStatusId, orders]) => {
                              //  console.log('ordersStatusId', ordersStatusId);
                              //  console.log('orders', orders);

                                iterateOrders(orders);

                                commit('saveOrders', {orders, ordersStatusId});
                            });
                        }

                        // Save relatedOrdersDataToPhoneNumbers to the store
                       // commit('callInfoModule/saveRelatedOrdersDataToPhoneNumbers', relatedOrdersDataToPhoneNumbers, { root: true });

                        return relatedOrdersDataToPhoneNumbers;
                    });
                } else {
                    // Якщо немає неприпустимих номерів телефонів, збережіть дані у сховище відразу
                  //  commit('callInfoModule/saveRelatedOrdersDataToPhoneNumbers', relatedOrdersDataToPhoneNumbers, { root: true });
                    return relatedOrdersDataToPhoneNumbers;

                }

                function fillOrderData(order) {
                    return {
                        crm_order_id: order.crm_order_id,
                        BuyerPhone: order.BuyerPhone,
                        CrmOrderStatus: {
                            id: order.CrmOrderStatusId,
                            name: order.CrmOrderStatus
                        },
                        deliveryStatus: order.deliveryStatus,
                        deliveryType: order.deliveryData.PostName,
                        paymentStatus: order.is_paid === 1 ? 'Оплачено' : 'Не оплачено',
                        totalPrice: order.total_amount,
                        source: order.SourceType,
                        buyerName: order.BuyerName,
                        orderComment: order.orderComment
                    }
                }

                function iterateOrders(filteredOrders) {
                    filteredOrders.forEach(order => {
                        const phoneNumber = order.BuyerPhone;
                        let saveData = fillOrderData(order);
                      //  console.log('saveData', saveData);

                        // Initialize the array if it doesn't exist
                        if (!Array.isArray(relatedOrdersDataToPhoneNumbers[phoneNumber])) {
                            relatedOrdersDataToPhoneNumbers[phoneNumber] = [];
                        }

                        relatedOrdersDataToPhoneNumbers[phoneNumber].push(saveData);
                    });
                }


                // console.log orders


            }*/

        async findExitedOrdersByPhoneNumbers({getters, commit}, phoneNumbers) {

           // console.log('phoneNumbers', phoneNumbers);

   /*         const numericValues = phoneNumbers.filter(value => {
                // Check if value is numeric and length is more than 9
                return /^\d+$/.test(value) && value.length > 9;
            });

            console.log(numericValues);

            const formattedPhoneNumbers = numericValues.map(phone => parseInt(phone.slice(-9), 10));*/

            const formattedPhoneNumbers = phoneNumbers.reduce((acc, value) => {
                // Check if value is numeric and length is more than 9
                if (/^\d+$/.test(value) && value.length > 9) {
                    // Extract the last 9 digits and parse as an integer
                    acc.push(parseInt(value.slice(-9), 10));
                }
                return acc;
            }, []);

          //  console.log('formattedPhoneNumbers', formattedPhoneNumbers);

            const allOrders = getters.getAllOrders;
            const phoneNumberSet = new Set(formattedPhoneNumbers);
            const filteredOrders = [];
            const existingPhoneNumbers = new Set();
            const relatedOrdersDataToPhoneNumbers = {};

            allOrders.forEach(order => {
                const phoneNumber = order.BuyerPhone;
                if (phoneNumberSet.has(phoneNumber)) {
                    filteredOrders.push(order);
                    existingPhoneNumbers.add(phoneNumber);
                }
            });

            const nonExistentPhoneNumbers = formattedPhoneNumbers.filter(phoneNumber => !existingPhoneNumbers.has(phoneNumber));

            if (filteredOrders.length > 0) {
                iterateOrders(filteredOrders, relatedOrdersDataToPhoneNumbers);
            }

            if (nonExistentPhoneNumbers.length > 0) {
                try {
                    const response = await getOrdersDataForCallsHostoryByPhone({phoneNumbers: nonExistentPhoneNumbers});
                    if (response.status === 'success' && response.data.length > 0) {
                        let ordersByOrderStatusId = {};

                        response.data.forEach(order => {
                            //   const phoneNumber = order.BuyerPhone;

                            let crmOrderStatusId = order.CrmOrderStatusId - 1;

                            if (!ordersByOrderStatusId[crmOrderStatusId]) {
                                ordersByOrderStatusId[crmOrderStatusId] = [];
                            }

                            ordersByOrderStatusId[crmOrderStatusId].push(order);
                        });
                        Object.entries(ordersByOrderStatusId).forEach(([ordersStatusId, orders]) => {
                            iterateOrders(orders, relatedOrdersDataToPhoneNumbers);
                            //  console.log("orders length", orders.length);
                            /*
                            orderStatusId 3
                             orders length 38
                            order.js:772 orderStatusId 4
                             orders length 4
                            order.js:772 orderStatusId 5
                             orders length 2
                            order.js:772 orderStatusId 6
                             orders length 53
                            order.js:772 orderStatusId 7
                             orders length 5
                            order.js:772 orderStatusId 8
                             orders length 1
                             */
                            commit('saveOrders', {orders, ordersStatusId});
                        });

                    //    console.log('saved orders after request', getters.getOriginalOrders);

                    }

                    commit('callInfoModule/saveRelatedOrdersDataToPhoneNumbers', relatedOrdersDataToPhoneNumbers, {root: true});
                } catch (error) {
                    console.error('Error fetching orders:', error);
                }
            } else {
                commit('callInfoModule/saveRelatedOrdersDataToPhoneNumbers', relatedOrdersDataToPhoneNumbers, {root: true});
            }

            // save relatedOrdersDataToPhoneNumbers to local storage

            localStorage.setItem('phonesRelatedToOrders', JSON.stringify(relatedOrdersDataToPhoneNumbers));

            return relatedOrdersDataToPhoneNumbers;


            function fillOrderData(order) {
                return {
                    orderDate: formatDateToDayMonthYear(order.date),
                    order_id: order.sourceData.OrderNumber,
                    crm_order_id: order.crm_order_id,
                    BuyerPhone: order.BuyerPhone,
                    CrmOrderStatus: {
                        id: order.CrmOrderStatusId - 1,
                        name: order.CrmOrderStatus
                    },
                    deliveryType: order.deliveryData.PostName,
                    TtnNumber: order.deliveryData.TtnNumber,
                    deliveryStatus: order.deliveryData.DeliveryStatus,
                    paymentStatus: order.is_paid,
                    totalPrice: order.total_amount,
                    source: order.sourceData.source_type,
                    buyerName: order.BuyerName,
                    orderComment: order.orderComment
                };
            }

            function formatDateToDayMonthYear(dateString) {
                const date = new Date(dateString);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
                const year = date.getFullYear();
                return `${day}.${month}.${year}`;
            }

            function iterateOrders(filteredOrders, relatedOrdersDataToPhoneNumbers) {
                filteredOrders.forEach(order => {
                    const phoneNumber = '0' + order.BuyerPhone.toString();
                    let saveData = fillOrderData(order);

                    if (!Array.isArray(relatedOrdersDataToPhoneNumbers[phoneNumber])) {
                        relatedOrdersDataToPhoneNumbers[phoneNumber] = [];
                    }

                    relatedOrdersDataToPhoneNumbers[phoneNumber].push(saveData);
                });
            }
        }
    },


    getters: {
        getOrders: (state) => (orderStatusId) => {
            return state.orders[orderStatusId] || [];
        },


        getORderByOrderStatusAndCrmOrderId: (state) => (orderStatusId, crmOrderId) => {
            //   console.log('orderStatusId', orderStatusId);
            //   console.log('crmOrderId', crmOrderId);
            //   console.log('state.orders[orderStatusId]', state.orders[orderStatusId]);
            return state.orders[orderStatusId].find(order => order.crm_order_id === crmOrderId);
        },

        getAllOrders: (state) => {
            return Object.values(state.orders).flat();
        },

        getOriginalOrders: (state) => {
            return state.orders;
        },

        getSenderSettings(state) {
            return state.senderSettings
        },

        getOrderStatuses(state) {
            return state.orderStatuses;
        },

        senderAddressesNp(state) {
            return state.senderAddressesNp;
        },

        getDeliveryTypes(state) {
            return state.deliveryInfoAssociatedTypes;
        },

        getIsAdmin(state) {
            return state.isAdmin;
        },

        getDeliveryPayerTypes(state) {
            return state.deliveryPayerTypes;
        },

        getPaymentTypes(state) {
            return state.paymentTypes;
        },

        getTabCounts(state) {
            return state.OrderStatusesCount;
        },

        getUsersData(state) {
            return state.userData;
        },

        getCustomSources(state) {
            return state.customSources;
        }

    }
}