<template>

  <div id="modalContent">
    <orders-table></orders-table>
  </div>
</template>
<script>

import ordersTable from '@/components/ordersTable';


export default {
  name: 'Orders',

  setup() {
    return {
    };
  },

  components: {
    ordersTable,

  },

};
</script>


