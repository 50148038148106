<template>
  <div class="page-content">
  <div class="padding-xl product-block-header d-flex">
    <div class="w-10 text-center">
      Джерело
    </div>
    <div class="w-10 text-center">
      Перевізник
    </div>
    <div class="w-10 text-center">
      ТТН
    </div>
    <div class="w-10 text-center">
      Одержувач
    </div>
    <div class="w-10">
      Комментар
    </div>
    <div class="w-50">
      Товари
    </div>
  </div>

  <div v-for="order in ordersForPacking" :key="order.crm_order_id"
       class="padding-xl product-block d-flex border-bottom">
    <div class="w-10 text-center ">
      {{ order.SourceType }}
    </div>
    <div class="w-10 text-center">
      {{ order.deliveryData.PostName }}
    </div>
    <div class="w-10 text-center">
      {{ order.deliveryData.TtnNumber }}
    </div>
    <div class="w-10 text-center">
      {{ order.deliveryData.DeliveryFio }}
    </div>
    <div class="w-10">
      {{ order.orderComment }}
    </div>
    <div class="w-50">
      <div v-for="product in order.products" class="d-flex pointing" :id="product.product_id">
        <!-- Uncomment this if product images are needed
        <div class="w-15">
          <div class="img">
            <img :src="product.product.thumbnail_url" alt="">
          </div>
        </div>
        -->

        <div class="w-85">
          [{{ product.product.article }}] {{ product.product.name }}
        </div>
        <div class="w-15 text-center">
          {{ product.quantity }} од.
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import {
  BButton, BCard,
  BDropdown,
  BDropdownItem,
  BFormCheckbox, BFormInput, BFormInvalidFeedback,
  BFormSelect, BFormText,
  BFormTextarea,
  BInputGroup,
  BModal, BSpinner, BCollapse
} from 'bootstrap-vue-next';
import {computed, ref, watch} from "vue";
import {updatePackingStatus} from "@/axios/settignsRequests";

export default {
  name: "PackingListModal",

  props: {

    ordersForPrint: {
      type: Object,
      required: true,
    },
  },

  setup(props) {

    const ordersForPacking = computed(() => props.ordersForPrint);

    console.log('ordersForPacking', ordersForPacking.value);

    return {

      ordersForPacking,
    }
  },

  components: {

  },

}


</script>

<style scoped>


@media print {
  html, body {
    width: 210mm;
    min-height: 297mm;
    margin: 0;
    padding: 0;
    font-size: 12pt; /* Adjust font size as necessary */
  }
  /* Ensure the content width fits the A4 width minus typical printer margins */
  .page-content {
    width: 205mm; /* A bit less than 210mm to account for printer margins */
    margin: 0 auto;
  }
}


</style>