<template>
  <div>
    <component :is="page + 'Layout' "></component>


  </div>
</template>

<script>

import {useRoute} from "vue-router";
import {computed} from "vue";
import mainLayout from "@/layouts/mainLayout";
import authLayout from "@/layouts/authLayout";
export default {
  setup() {

    const route = useRoute();

    const page = computed(() => route.meta.layout);

    return {
      //getVal: () => console.log(page),
      page
    }
  },
  components: {
    mainLayout,
    authLayout,
  }
}
</script>

<style scoped>

</style>