<template>
  <BButton variant="outline-secondary" @click="editPriceTypes"> Редагувати типи цін</BButton>

  <BModal v-model="preventableModal"
          title="Список типів цін"
          @hide.prevent
          scrollable

          @ok="savePriceTypes"
          @cancel="preventableModal = false"
          cancel-title="Закрити"
          ok-title="Зберегти"

  >

    <BButton @click="addPriceType">Додати тип ціни</BButton>

    <div v-for="(priceType) in priceTypes" :key="priceType.id" class="mt-2">
      <div class="d-flex ">
        <div v-if="priceType.user_id !== null "> {{ priceType.name }}</div>
        <div v-else>
          <BFormInput v-model="priceType.name" placeholder="Назва типу ціни"></BFormInput>
        </div>
      </div>
    </div>
  </BModal>
</template>

<script>
import {getAllPriceTypesByUser, savePrices} from "@/axios/settignsRequests";
import {ref} from "vue";

import {
  BButton,
  BFormInput,
  BModal
} from 'bootstrap-vue-next';

import {useStore} from "vuex";

export default {
  name: "priceTypes",


/*  props: {

  }*/



  setup(props, { emit }){
    const store = useStore()
    const priceTypes = ref([]);

    const preventableModal = ref(false)

    function addPriceType() {
      const newId = generateUniqueId(); // Функція для генерації унікального ID
      priceTypes.value[newId] = {
        'id': newId,
        'name': '',
        'value': '',
        'description': null,
        'user_id': null,
      };
    }

    function generateUniqueId() {
      return Date.now().toString(); // Або інший метод для генерації унікального ID
    }

    async function editPriceTypes() {
      preventableModal.value = true
      await getPriceTyesFromServer()
    }

    async function getPriceTyesFromServer() {
      await getAllPriceTypesByUser().then(res => {
        console.log('priceTypes', res)
        priceTypes.value = res
      })
    }

    async function savePriceTypes() {
      // Перетворюємо об'єкт назад у масив
      const preparedPriceTypes = Object.values(priceTypes.value).map(priceType => {
        // Перевірка, чи є user_id
        if (!priceType.user_id) {
          // Якщо user_id відсутній, видаляємо id
          priceType.id = null;
        }
        return priceType;
      });

      console.log('preparedPriceTypes', preparedPriceTypes);

      // Збереження даних
      await savePrices({pricesTypes: preparedPriceTypes}).then(response => {
        console.log('response', response);

        if (response.status === 'success') {

          emit('update:priceTypesResponse', response.data);
          priceTypes.value = response.data;
          store.dispatch('alert/triggerAlert', {
            'show': true,
            'variant': 'success',
            'message': 'Типи цін успішно збережені'
          }, {root: true});
        } else {
          store.dispatch('alert/triggerAlert', {
            'show': true,
            'variant': 'danger',
            'message': 'Помилка при збереженні типів цін'
          }, {root: true});
        }
      });

      preventableModal.value = false

    }

    return {
      priceTypes,
      editPriceTypes,
      savePriceTypes,
      addPriceType,
      preventableModal
    }

  },

  components: {
    BButton,
    BModal,
    BFormInput
  }



}
</script>

<style scoped>

</style>