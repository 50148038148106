
<template>
  <BNavbar toggleable="lg" variant="primary" v-b-color-mode="'dark'">
    <BNavbarBrand href="#">ТТН Генератор</BNavbarBrand>
    <BNavbarToggle target="nav-collapse"/>
    <BCollapse id="nav-collapse" is-nav>
      <BNavbarNav>
        <BNavItem to="/">Замовлення</BNavItem>  <!-- Home link -->
        <BNavItem to="/product-list">Товари</BNavItem>  <!-- Products link -->
        <BNavItem @click="showCallInfo">Дзвінки
          <BBadge variant="light"  v-if="missedCallsCount > 0">{{
              missedCallsCount
            }}
          </BBadge>
        </BNavItem>
        <BNavbarNav class="ms-auto mb-2 mb-lg-0">
          <BNavItemDropdown text="Підтримка" right>
            <BDropdownItem href="viber://chat?number=%2B380938507911" target="_blank">
              Зв'язатися через Viber
            </BDropdownItem>

            <BDropdownItem href="https://t.me/emlax69" target="_blank">
              Зв'язатися через Telegram
            </BDropdownItem>
          </BNavItemDropdown>
        </BNavbarNav>

      </BNavbarNav>
      <BNavbarNav class="ms-auto mb-2 mb-lg-0">
        <BNavItemDropdown text="Звіти" right>
          <BDropdownItem
              href="#"
              @click="openChangeOrderStatusesByNumberModal">Звіт по грошовим переказам</BDropdownItem>
          <BDropdownItem href="/drop-report" >Звіт по дропшиперам посередникам</BDropdownItem>
        </BNavItemDropdown>
      </BNavbarNav>
      <BNavbarNav class="ms-auto mb-2 mb-lg-0">
        <BNavItem href="#" @click="settingsBigModalIsVisible = !settingsBigModalIsVisible">Налаштування</BNavItem>
        <BNavItem href="#" @click="logout">Вийти</BNavItem>
      </BNavbarNav>
    </BCollapse>
  </BNavbar>
<!--  <SettingsModal
      v-if="settingsModalIsVisible"
      v-model="settingsModalIsVisible"
      :showSettingsModal="settingsModalIsVisible"
      @update:showSettingsModal="ChangeStateSettingsModal()"
  />-->

  <SettingsBigModal
      v-if="settingsBigModalIsVisible"
      v-model="settingsBigModalIsVisible"
      :showSettingsModal="settingsBigModalIsVisible"
      @update:showSettingsModal="ChangeStateSettingsBigModal()"
  />

  <transition>
    <BAlert
        v-model="alert.showAlert"
        :variant="alert.alertVariant"
        @dismissed="closeAlert"
        :interval="autoDismissingAlertInterval"
        @close-countdown="autoDismissingAlertCountdown = $event"
        dismissible
    >
      {{ alert.alertMessage }}
    </BAlert>
  </transition>


</template>

<script>
import {useStore} from "vuex";
import {computed, onMounted, ref} from "vue";
import SupportButtonDropdown from "@/components/SupportButtonDropdown";

import {
  BNavbar,
  BAlert,
  vBColorMode,
  BNavbarBrand,
  BNavbarNav,
  BNavItem,
  BNavItemDropdown,
  BDropdownItem,
  BNavbarToggle,
  BCollapse,
  BOffcanvas,
  BBadge
} from 'bootstrap-vue-next';
//import SettingsModal from "@/components/SettingsModal";
import dropReport from "@/components/dropReport";
import SettingsBigModal from "@/components/SettingsBigModal";

export default {
  name: "theNavbar",

  setup() {
    const store = useStore();
    const logout = () => {
      store.commit('authModule/logout')
    };


    function showCallInfo () {
      store.commit('callInfoModule/changeModalStatus', {
        'status': true,
        'title': 'Історія викликів',
        'type': 'callInfo',
      })
    }

    function openChangeOrderStatusesByNumberModal() {
      store.commit('changeOrderStatusByTtn/changeModalStatus', {
        'title': 'Конроль отримання грошових переказів НП',
        'type': 'controlByDate',
      })
    }

   // const settingsModalIsVisible = ref(false);
    const settingsBigModalIsVisible = ref(false);

  /*  const ChangeStateSettingsModal = () => {
      settingsModalIsVisible.value = !settingsModalIsVisible.value;
    };*/

    const ChangeStateSettingsBigModal = () => {
      settingsBigModalIsVisible.value = !settingsBigModalIsVisible.value;
    };


   const missedCallsCount = computed(() =>
       store.getters['callInfoModule/getMissedCallsCount']
   )

/*    onMounted(() => {
      setTimeout(() => {
        const npSenders = JSON.parse(localStorage.getItem('npSenders'));
        if (!npSenders || npSenders.length === 0) {
          settingsModalIsVisible.value = true;
        }
      }, 1000);
    });*/


    const autoDismissingAlertCountdown = ref(0)

    const autoDismissingAlertInterval = ref(1000); // Interval for countdown update in milliseconds

    const alert = computed(() => {
      return store.getters['alert/getAlertState'];
    });

    const closeAlert = () => {
      store.commit('alert/setAlertState', {show: 0});
    };

    return {
      logout,
      openChangeOrderStatusesByNumberModal,
    //  settingsModalIsVisible,
    //  ChangeStateSettingsModal,
      settingsBigModalIsVisible,
      ChangeStateSettingsBigModal,
      showCallInfo,
      missedCallsCount,
      autoDismissingAlertCountdown,
      autoDismissingAlertInterval,
      alert,
      closeAlert,

    };
  },


  components: {
    //SettingsModal,
    SettingsBigModal,
    dropReport,
    BNavbar,
    BNavbarBrand,
    BNavbarNav,
    BNavItem,
    BNavItemDropdown,
    BDropdownItem,
    BNavbarToggle,
    BCollapse,
    vBColorMode,
    BOffcanvas,
    BBadge,
    BAlert,
    SupportButtonDropdown
  }
}


</script>