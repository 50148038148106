
export default {
    namespaced: true,
    state() {

        return {
            singleOrder: {},
            isOpen: false,
        }
    },

    mutations: {
        saveOrderData(state, payload) {

        },
        changeOrderModalState(state){
            state.isOpen = !state.isOpen;
        }
    },

    actions: {

    },

    getters: {
      getOrderState(state) {
          return state.isOpen;
      }
    }



}