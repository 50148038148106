export default {

    namespaced: true,

    state: {
        callInfo: {
            'status': false,
            'title': '',
            'type': '',
        },

        missedCallsCount: 0,

        relatedOrdersDataToPhoneNumbers: {},


    },

    mutations: {

        changeModalStatus(state, payload) {
            console.log('call mutation');
            state.callInfo.status = payload.status;
            state.callInfo.title = payload.title || '';
            state.callInfo.type = payload.type || '';
        },

        setMissedCallsCount(state, payload) {
            state.missedCallsCount = payload;
        },

        saveRelatedOrdersDataToPhoneNumbers(state, relatedOrdersDataToPhoneNumbers) {
            state.relatedOrdersDataToPhoneNumbers = { ...state.relatedOrdersDataToPhoneNumbers, ...relatedOrdersDataToPhoneNumbers };
        }
    },

    getters: {
        getCallInfoState(state) {
            return state.callInfo
        },

        getMissedCallsCount(state) {
            return state.missedCallsCount
        },

        getRelatedOrdersDataToPhoneNumbers(state) {
            return state.relatedOrdersDataToPhoneNumbers
        }

    }




}