
import {makeCall} from "@/axios/settignsRequests";
import store from "@/store/store.js";



export async function useCallToClient(managerPhone, buyerPhone, buyerName) {

    console.log('called call')

    //  const callToClient = async () => {
    console.log('callToClient', buyerPhone.value);
    try {
        const response = await makeCall({
            'channel': managerPhone,
            'destination': buyerPhone,
            'buyerName': buyerName
        });

        await store.dispatch('alert/triggerAlert', {
            show: 3000,
            variant: response.status,
            message: response.message
        }, {root: true});
    } catch (error) {
        console.error('Error:', error);
    }
    // };


}